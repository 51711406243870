import React, { useEffect, useState } from 'react'

import useGetAnnouncements from '../../../../../hooks/saleston/getAnnouncements'
import RefreshButton from '../../../../atoms/RefreshButton'
import UserAnnouncementsTable from '../../../../molecules/Table/UserAnnouncements'

import type { TablePaginationConfig } from 'antd/es/table'
import './index.css'

export interface AnnouncementsProps {
  document: string
}

const Announcements = ({ document }: AnnouncementsProps) => {
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    defaultCurrent: 1,
    defaultPageSize: 10,
  })

  const { announcements, loading, refetch } = useGetAnnouncements(document)

  useEffect(() => {
    setPagination((current) => ({
      ...current,
      total: announcements.totalRecords,
    }))
  }, [announcements.totalRecords])

  return (
    <>
      <RefreshButton trigger={refetch} icon='./images/refresh.svg' alignment='right' />
      <UserAnnouncementsTable
        dataSource={announcements.records}
        loading={loading}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  )
}

export default Announcements
