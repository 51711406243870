import React, { useContext, useEffect, useState } from 'react'

import useGetCases from '../../../../../hooks/saleston/getCases'

import './index.css'

import useListener from '../../../../../hooks/useListener'
import RefreshButton from '../../../../atoms/RefreshButton'
import UserRecordsTable from '../../../../molecules/Table/UserRecords'
import { TabContext } from '../../../../templates/Client'
import type { TablePaginationConfig } from 'antd/es/table'

export interface CaseProps {
  id: string
}

const Cases = ({ id }: CaseProps) => {
  const { account } = useContext(TabContext)
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    defaultCurrent: 1,
    defaultPageSize: 10,
  })

  const { cases, loading, refetch } = useGetCases({ accountId: id }, pagination.current, pagination.pageSize)

  useListener(['case:created', 'case:ownerChanged'], (e) => {
    const { accountId } = (e as CustomEvent).detail
    if (accountId === account?.Id) {
      refetch()
    }
  })
  useEffect(() => {
    setPagination((currentPagination) => {
      return {
        ...currentPagination,
        total: cases.totalRecords,
      }
    })
  }, [cases.totalRecords])

  return (
    <>
      <RefreshButton trigger={refetch} icon='./images/refresh.svg' alignment='right' />
      <UserRecordsTable
        dataSource={cases.records}
        loading={loading}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  )
}

export default Cases
