import { Space, Spin } from 'antd'
import React, { useEffect, useRef } from 'react'

interface RefreshButtonProps {
  loading: boolean
  handle: () => void
}

const InfiniteScrollTrigger = ({ loading, handle }: RefreshButtonProps) => {
  const loaderRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0]
      if (target.isIntersecting && !loading) {
        handle()
      }
    })

    const { current } = loaderRef

    if (current) {
      observer.observe(current)
    }

    return () => {
      if (current) {
        observer.unobserve(current)
      }
    }
  }, [handle, loading])

  return (
    <Space ref={loaderRef} style={{
      display: 'flex',
      justifyContent: 'center',
      margin: '2% 0',
    }}>
      { loading && <Spin /> }
    </Space>
  )
}

export default InfiniteScrollTrigger
