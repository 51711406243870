import { Table } from 'antd'
import React from 'react'

import { Token } from '../../../../services/vs/types'
import VSButton from '../../../atoms/VSButton'

import columns from './columns'

interface VsContact {
  id: string
  name: string
  email: string
  phone: string
}

interface VSContactsTableProps {
  dataSource: VsContact[]
  sendToken: (token: Omit<Token, 'URN' | 'document'>) => Promise<void>
  loading: boolean
}

const insertButton = (items: VsContact[], { sendToken }: { sendToken: VSContactsTableProps['sendToken'] }) =>
  items.map((item) => ({
    ...item,
    sendToken: (
      <VSButton
        sendToken={async () => {
          await Promise.all([
            // Send Token to email
            sendToken({
              contact: item.email,
              user_id: item.id,
              user_name: item.name,
            }),

            // Send Token to phone
            sendToken({
              contact: item.phone,
              user_id: item.id,
              user_name: item.name,
            }),
          ])
        }}
      />
    ),
  }))

const VSContactsTable = ({ dataSource, sendToken, loading }: VSContactsTableProps) => (
  <>
    <Table
      loading={loading}
      dataSource={insertButton(dataSource, { sendToken })}
      columns={columns}
      pagination={false}
      rowKey={(record) => record.id}
    />
  </>
)

export default VSContactsTable
