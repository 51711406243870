import { Card, Collapse } from 'antd'
import { ExpandIconPosition } from 'antd/lib/collapse/Collapse'
import React, { ReactNode } from 'react'
import './index.css'

const { Panel } = Collapse

interface CollapsableCardProps {
  iconName?: string
  title: string
  autoOpen?: boolean
  forceRender?: boolean
  loading: boolean
  extra?: ReactNode
  noPadding?: boolean
  onChange: (key: string | string[]) => void
  onlyMarginBottom?: string
  customClassCollapse?: string
  expandIconPosition?: ExpandIconPosition
  noMargin?: boolean
  children?: any
}

const CollapsableCard = ({
  iconName,
  title,
  autoOpen,
  forceRender,
  loading,
  extra,
  noPadding,
  onChange,
  onlyMarginBottom,
  customClassCollapse,
  expandIconPosition,
  noMargin,
  children,
}: CollapsableCardProps) => (
  <div>
    <Collapse
      style={{ margin: noMargin ? '0px' : '5px' }}
      className={`collapse ${onlyMarginBottom ? 'onlyMarginBottom' : ''} ${customClassCollapse || ''}  ${noMargin ? '' : 'marginAllOver'
      }`}
      expandIconPosition={expandIconPosition || 'end'}
      defaultActiveKey={autoOpen ? ['0'] : undefined}
      onChange={onChange}
    >
      <Panel
        forceRender={forceRender || false}
        className='custom'
        key='0'
        header={
          <div className='collapseHeader'>
            {iconName ? <img src={`./images/${iconName}`} alt='icon' className='collapseTitleIcon' /> : null}

            <span className='collapseTitleText verticalAlign'>
              <b>{title}</b>
            </span>

            <span className='rightAlign'>{extra}</span>
          </div>
        }
      >
        <Card
          className='content'
          bordered={false}
          loading={loading}
          // style={{ boxShadow: 'none' }}
          styles={{
            body: {
              margin: noPadding ? '-15px' : '0px',
            },
          }}
        >
          {children}
        </Card>
      </Panel>
    </Collapse>
  </div>
)

export default CollapsableCard
