/* eslint-disable no-restricted-syntax */
import { useCallback } from 'react'

import getCaseFeed, { CaseFeedFilter } from '../../services/saleston/getCaseFeed'
import { SFCaseFeed } from '../../services/saleston/types'
import useAwaitedData from '../useAwaitedData'

const useGetCaseFeed = ({ caseId }: CaseFeedFilter, page?: string, perPage: number = 10) => {
  const fetch = useCallback(() => getCaseFeed({ caseId }, page, perPage), [caseId, page, perPage])
  const { data, loading, refetch } = useAwaitedData(fetch, {} as SFCaseFeed)

  return {
    data,
    loading,
    refetch,
  }
}

export default useGetCaseFeed
