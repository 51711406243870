import React from 'react'

import { SFCaseFeedItem } from '../../../../services/saleston/types'
import Description from '../../../atoms/Description'

interface FeedItemTextPostProps {
  feedItemData: SFCaseFeedItem

}

const FeedItemTextPost = ({ feedItemData }: FeedItemTextPostProps) => (
  <Description content={feedItemData.body?.text || ''} />
)

export default FeedItemTextPost
