import { Button, Tooltip } from 'antd'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

import message from '../../../services/message'

export interface CopyButtonProps {
  content: string
  alertMessage: string
}

const CopyButton = ({ content, alertMessage }: CopyButtonProps) => (
  <CopyToClipboard
    text={content}
    onCopy={() => {
      message({
        type: 'success',
        content: alertMessage,
      })
    }}
  >
    <Tooltip placement="topLeft" title={content}>
      <Button type="link">{content}</Button>
    </Tooltip>
  </CopyToClipboard>
)

export default CopyButton
