import { useCallback } from 'react'
import { useBoolean } from 'react-use'

const useAwait = <T = any>(
  asyncFn: T,
): [
  boolean,
  T,
  {
    toggle: (nextState?: boolean) => void
  }
] => {
  const [isLoading, toggle] = useBoolean(false)
  // @ts-ignore
  const fetch: T = useCallback(
    async (...args) => {
      try {
        toggle(true)
        const data = await (asyncFn as any)(...args)
        toggle(false)
        return data
      } catch (err) {
        toggle(false)
        throw err
      }
    },
    [toggle, asyncFn],
  )
  return [
    isLoading,
    fetch,
    {
      toggle,
    },
  ]
}

export default useAwait
