import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import React, { ReactElement, useCallback, useContext, useEffect } from 'react'
import { useLocalStorage } from 'react-use'

import useGetCaseTree from '../../../hooks/saleston/useGetCaseTree'
import { SFCaseTreeField } from '../../../services/saleston/types'
import arrayToOption from '../../../utils/arrayToOption'
import { getCategories, getFields, getSubjects, getTopics, getCombinationOptions } from '../../../utils/caseTree'
import removeAccents from '../../../utils/removeAccents'
import { TabContext } from '../../templates/Client'

interface OptionCommands<T = string | undefined> {
  value: T
  store: (value: T) => void
  reset: () => void
}

interface DynamicFieldsProps {
  form: FormInstance
  onValuesChange: (topic?: string, category?: string, subject?: string) => void
  topic: OptionCommands
  category: OptionCommands
  subject: OptionCommands
}

const DynamicFields = ({ form, onValuesChange, topic, category, subject }: DynamicFieldsProps) => {
  const { urn } = useContext(TabContext)
  const { tree } = useGetCaseTree()
  const [fields, setFields] = useLocalStorage<SFCaseTreeField[]>(`pluton-${urn}-create-case-dynamic-fields`, [])

  useEffect(() => {
    if (topic.value && category.value && subject.value) {
      setFields(getFields(tree, [topic.value, category.value, subject.value]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject])

  const handleChangeTopic = () => {
    topic.reset()
  }

  const handleChangeCategory = () => {
    form.setFieldsValue({
      CategoriaPagarmeMEI__c: '',
    })
    category.reset()
    setFields([])
  }

  const handleChangeSubject = () => {
    form.setFieldsValue({
      Assuntopagarmemei__c: '',
    })
    subject.reset()
    setFields([])
  }

  const RenderDynamicInput = (field: SFCaseTreeField) => {
    const hash: Record<SFCaseTreeField['type'], ReactElement> = {
      text: <Input placeholder={field.placeholder} />,
      textarea: <Input.TextArea placeholder={field.placeholder} />,
      select: <Select placeholder={field.placeholder} options={field.options ? arrayToOption(field.options) : []} />,
      checkbox: <Checkbox>{field.title}</Checkbox>,
    }

    return hash[field.type]
  }

  const autoSetFields = useCallback(({ topic: newTopic, category: newCategory, subject: newSubject }) => {
    topic.store(newTopic)
    form.setFieldValue('TemaPagarmeMEI__c', newTopic)

    category.store(newCategory)
    form.setFieldValue('CategoriaPagarmeMEI__c', newCategory)

    subject.store(newSubject)
    form.setFieldValue('Assuntopagarmemei__c', newSubject)

    onValuesChange(newTopic, newCategory, newSubject)
  }, [category, form, onValuesChange, subject, topic])

  const filterOptions = (input: string, option?: { label: string }) => {
    const label = option?.label ?? ''

    const labelParsed = removeAccents(label.toLocaleLowerCase())
    const inputWords = removeAccents(input.toLocaleLowerCase()).split(/[ ,:]+/)

    return inputWords.every(word => labelParsed.includes(word))
  }

  return (
    <>
      <Row justify='center'>
        <Form.Item label='Classificação Rápida' style={{ width: '100%' }}>
          <Select
            showSearch
            placeholder='Busque a classificação da árvore desejada'
            optionFilterProp="children"
            filterOption={filterOptions}
            options={getCombinationOptions(tree)}
            onChange={(raw) => autoSetFields(JSON.parse(raw))}
          />

          <Row style={{ margin: '1% 0' }}>
            <Button
              size='small'
              style={{
                color: '#0e6f23',
                borderColor: '#0e6f23',
              }}
              onClick={() => {
                autoSetFields({
                  topic: 'Solicitação',
                  category: 'Logística (S)',
                  subject: 'Manutenção de Máquina',
                })
              }} >Solicitação : Logística (S) : Manutenção de Máquina</Button>
          </Row>
          <Row style={{ margin: '1% 0' }}>
            <Button
              size='small'
              style={{
                color: '#25a529',
                borderColor: '#25a529',
              }}
              onClick={() => {
                autoSetFields({
                  topic: 'Dúvida',
                  category: 'Recebimento (D)',
                  subject: 'Saldo',
                })
              }}>Dúvida : Recebimento (D) : Saldo</Button>
          </Row>
          <Row style={{ margin: '1% 0' }}>
            <Button
              size='small'
              style={{
                color: '#2cd828',
                borderColor: '#2cd828',
              }}
              onClick={() => {
                autoSetFields({
                  topic: 'Dúvida',
                  category: 'Tarifa (D)',
                  subject: 'Minhas Taxas',
                })
              }}>Dúvida : Tarifa (D) : Minhas Taxas</Button>
          </Row>

        </Form.Item>
      </Row>
      <Row justify='space-between'>
        <Col span={7}>
          <Form.Item label='Tema' name='TemaPagarmeMEI__c'>
            <Select
              placeholder='Selecione o Tema'
              options={arrayToOption(getTopics(tree))}
              onChange={(value) => {
                handleChangeTopic()
                topic.store(value)

                handleChangeCategory()
                handleChangeSubject()

                onValuesChange(value)
              }}
            />
          </Form.Item>
        </Col>

        <Col span={7}>
          <Form.Item label='Categoria' name='CategoriaPagarmeMEI__c'>
            <Select
              placeholder='Selecione a Categoria'
              disabled={!topic.value}
              options={topic.value ? arrayToOption(getCategories(tree, topic.value)) : []}
              onChange={(value) => {
                category.store(value)
                handleChangeSubject()

                onValuesChange(topic.value, value)
              }}
            />
          </Form.Item>
        </Col>

        <Col span={7}>
          <Form.Item label='Assunto' name='Assuntopagarmemei__c'>
            <Select
              placeholder='Selecione o Assunto'
              disabled={!category.value}
              options={topic.value && category.value ? arrayToOption(getSubjects(tree, topic.value, category.value)) : []}
              onChange={(value) => {
                subject.store(value)

                onValuesChange(topic.value, category.value, value)
              }}
            />
          </Form.Item>
        </Col>

        {fields?.map((field) => (
          <Col key={field.SFfield} span={24}>
            <Form.Item
              label={field.title}
              name={field.SFfield}
              rules={field.rules}
              tooltip={field.tooltip}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...(field.type === 'checkbox' && {
                valuePropName: 'checked',
                label: undefined,
              })}
            >
              {RenderDynamicInput(field)}
            </Form.Item>
          </Col>
        ))}
      </Row>
    </>
  )
}

export default DynamicFields
