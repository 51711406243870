import React from 'react'

import './index.css'

interface CaseDetailsFieldProps {
    label: string
    value: string
}

const CaseDetailsField = ({ label, value }: CaseDetailsFieldProps) => (
  <div className='containerItem'>
    <span className='label'>{label}</span>
    <span className='text'>{value}</span>
  </div>
)

export default CaseDetailsField
