import { Auth } from 'aws-amplify'
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import useAuth from '../hooks/useAuth'

const {
  REACT_APP_COGNITO_IDENTITY_PROVIDER,
} = process.env

const Login = () => {
  const [searchParams] = useSearchParams()
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (isAuthenticated) {
      window.close()
      return
    }

    Auth.federatedSignIn({ customProvider: REACT_APP_COGNITO_IDENTITY_PROVIDER || 'salesforce-sdx' })
  }, [searchParams, isAuthenticated])

  return (
    <div style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}>
      <img
        src="./images/loading.svg"
        alt='loading-icon'
        style={{ width: '10vw' }}
      />
    </div>
  )
}

export default Login
