import { Table, Row } from 'antd'
import React from 'react'

import { SFCase } from '../../../../services/saleston/types'
import type { TablePaginationConfig } from 'antd/es/table'

import columns from './columns'

interface UserRecordsTableProps {
  dataSource: SFCase[]
  customColumns?: typeof columns
  loading: boolean
  pagination?: TablePaginationConfig
  setPagination?: (pagination: TablePaginationConfig) => void
}

const UserRecordsTable = ({ dataSource, customColumns, loading, pagination, setPagination }: UserRecordsTableProps) => {
  const handleTableChange = (paginationComponent: TablePaginationConfig) => {
    if (!pagination || !setPagination) return

    setPagination(paginationComponent)
  }

  return (
    <>
      <Table
        columns={customColumns || columns}
        dataSource={dataSource}
        rowKey={(record) => record.Id}
        loading={loading}
        pagination={pagination || false}
        expandable={{
          expandedRowRender: (record) => <>
            <Row>
              <p>Tema:
                {record?.TemaPagarmeMEI__c! ? record.TemaPagarmeMEI__c : ' O tema não foi preenchido!'}
              </p>
            </Row>

            <Row>
              <p>Categoria:
                {record?.CategoriaPagarmeMEI__c! ? record.CategoriaPagarmeMEI__c : ' A categoria não foi preenchida!'}
              </p>
            </Row>

            <Row>
              <p>Assunto:
                {record?.AssuntoPagarmeMEI__c! ? record.AssuntoPagarmeMEI__c : ' O assunto não foi preenchido!'}
              </p>
            </Row>

            <Row>
              <p>Descrição:
                {record?.Description! ? record.Description : ' A descrição não foi preenchida!'}
              </p>
            </Row>

          </>,
        }}
        onChange={handleTableChange}
      />
    </>
  )
}

export default UserRecordsTable
