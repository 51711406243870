import { Space } from 'antd'
import React from 'react'
import './index.css'

interface RefreshButtonProps {
  icon: string
  trigger: () => void
  alignment?: 'left' | 'normal' | 'center' | 'right'
  ref?: React.ForwardedRef<HTMLButtonElement>
}

const RefreshButton = ({ icon, trigger, alignment, ref }: RefreshButtonProps) => (
  <Space style={{
    display: 'flex',
    justifyContent: alignment,
  }}>
    <button ref={ref} onClick={event => {
      trigger()
      event.stopPropagation()
    }} className='refreshButton' type='button'>
      <img src={icon} alt='refresh-icon' />
    </button>
  </Space>
)

const refreshButtonForwardingRef = (props: RefreshButtonProps, ref: RefreshButtonProps['ref']) => RefreshButton({
  ...props,
  ref,
})

export default React.forwardRef(refreshButtonForwardingRef)
