export default {
  asset: {
    brand: {
      card: {
        agiplan: 'brands/cards/agiplan.svg',
        alelo: 'brands/cards/alelo.svg',
        amex: 'brands/cards/amex.svg',
        aura: 'brands/cards/aura.svg',
        avista: 'brands/cards/avista.svg',
        banescard: 'brands/cards/banescard.svg',
        banese: 'brands/cards/banese.svg',
        banrisul: 'brands/cards/banrisul.svg',
        ben: 'brands/cards/ben.svg',
        brasilcard: 'brands/cards/brasilcard.svg',
        cabal: 'brands/cards/cabal.svg',
        calcard: 'brands/cards/calcard.svg',
        cooper: 'brands/cards/cooper.svg',
        credishop: 'brands/cards/credishop.svg',
        credz: 'brands/cards/credz.svg',
        dacasa: 'brands/cards/dacasa.svg',
        dinners: 'brands/cards/dinners.svg',
        discover: 'brands/cards/discover.svg',
        elo: 'brands/cards/elo.svg',
        fortbrasil: 'brands/cards/fortbrasil.svg',
        goodcard: 'brands/cards/goodcard.svg',
        greencard: 'brands/cards/greencard.svg',
        hiper: 'brands/cards/hiper.svg',
        hipercard: 'brands/cards/hipercard.svg',
        jcb: 'brands/cards/jcb.svg',
        mastercard: 'brands/cards/mastercard.svg',
        maxifrota: 'brands/cards/maxifrota.svg',
        other: 'brands/cards/other.svg',
        pluxee: 'brands/cards/pluxee.svg',
        senff: 'brands/cards/senff.svg',
        sodexo: 'brands/cards/sodexo.svg',
        sorocred: 'brands/cards/sorocred.svg',
        ticket: 'brands/cards/ticket.svg',
        unionpay: 'brands/cards/unionpay.svg',
        up: 'brands/cards/up.svg',
        valecard: 'brands/cards/valecard.svg',
        verdecard: 'brands/cards/verdecard.svg',
        verocard: 'brands/cards/verocard.svg',
        visa: 'brands/cards/visa.svg',
        vrbeneficios: 'brands/cards/vrbeneficios.svg',
      },
      payment: {
        'apple-pay': 'brands/payments/apple-pay.svg',
        'google-pay': 'brands/payments/google-pay.svg',
        pix: 'brands/payments/pix.svg',
        'samsung-pay': 'brands/payments/samsung-pay.svg',
      },
    },
    component: {
      'status-tracker': {
        completed: 'components/status-tracker/completed.svg',
        current: 'components/status-tracker/current.svg',
        error: 'components/status-tracker/error.svg',
        next: 'components/status-tracker/next.svg',
        'on-hold': 'components/status-tracker/on-hold.svg',
        warning: 'components/status-tracker/warning.svg',
      },
    },
    font: {
      global: 'Inter',
      brand: 'Sharon Display',
    },
    icon: {
      'action-attachment': 'icons/action-attachment.svg',
      'action-copy': 'icons/action-copy.svg',
      'action-download': 'icons/action-download.svg',
      'action-edit': 'icons/action-edit.svg',
      'action-export': 'icons/action-export.svg',
      'action-filter': 'icons/action-filter.svg',
      'action-history': 'icons/action-history.svg',
      'action-refresh': 'icons/action-refresh.svg',
      'action-search': 'icons/action-search.svg',
      'action-settings-cog': 'icons/action-settings-cog.svg',
      'action-settings-sliders': 'icons/action-settings-sliders.svg',
      'action-share': 'icons/action-share.svg',
      'arrow-backward': 'icons/arrow-backward.svg',
      'arrow-curve-cycle': 'icons/arrow-curve-cycle.svg',
      'arrow-curve-in': 'icons/arrow-curve-in.svg',
      'arrow-curve-out': 'icons/arrow-curve-out.svg',
      'arrow-down': 'icons/arrow-down.svg',
      'arrow-forward': 'icons/arrow-forward.svg',
      'arrow-left': 'icons/arrow-left.svg',
      'arrow-left-right': 'icons/arrow-left-right.svg',
      'arrow-north-east': 'icons/arrow-north-east.svg',
      'arrow-north-west': 'icons/arrow-north-west.svg',
      'arrow-right': 'icons/arrow-right.svg',
      'arrow-south-east': 'icons/arrow-south-east.svg',
      'arrow-south-west': 'icons/arrow-south-west.svg',
      'arrow-up': 'icons/arrow-up.svg',
      'arrow-up-down': 'icons/arrow-up-down.svg',
      barcode: 'icons/barcode.svg',
      'barcode-scanner': 'icons/barcode-scanner.svg',
      bell: 'icons/bell.svg',
      'brand-pix': 'icons/brand-pix.svg',
      'brand-stone': 'icons/brand-stone.svg',
      'brand-whatsapp': 'icons/brand-whatsapp.svg',
      chat: 'icons/chat.svg',
      calendar: 'icons/calendar.svg',
      'calendar-add': 'icons/calendar-add.svg',
      'calendar-check': 'icons/calendar-check.svg',
      'calendar-discount': 'icons/calendar-discount.svg',
      'calendar-money': 'icons/calendar-money.svg',
      card: 'icons/card.svg',
      'card-virtual': 'icons/card-virtual.svg',
      'caret-down': 'icons/caret-down.svg',
      'caret-left': 'icons/caret-left.svg',
      'caret-left-right': 'icons/caret-left-right.svg',
      'caret-right': 'icons/caret-right.svg',
      'caret-up': 'icons/caret-up.svg',
      'caret-up-down': 'icons/caret-up-down.svg',
      check: 'icons/check.svg',
      'chevron-down': 'icons/chevron-down.svg',
      'chevron-left': 'icons/chevron-left.svg',
      'chevron-right': 'icons/chevron-right.svg',
      'chevron-up': 'icons/chevron-up.svg',
      'chevron-up-down': 'icons/chevron-up-down.svg',
      'circle-alert': 'icons/circle-alert.svg',
      'circle-check': 'icons/circle-check.svg',
      'circle-clock': 'icons/circle-clock.svg',
      'circle-equals': 'icons/circle-equals.svg',
      'circle-error': 'icons/circle-error.svg',
      'circle-help': 'icons/circle-help.svg',
      'circle-info': 'icons/circle-info.svg',
      'circle-minus': 'icons/circle-minus.svg',
      'circle-money': 'icons/circle-money.svg',
      'circle-plus': 'icons/circle-plus.svg',
      close: 'icons/close.svg',
      'device-computer': 'icons/device-computer.svg',
      'device-phone': 'icons/device-phone.svg',
      'device-phone-add': 'icons/device-phone-add.svg',
      'device-pos': 'icons/device-pos.svg',
      dot: 'icons/dot.svg',
      'eye-close': 'icons/eye-close.svg',
      'eye-open': 'icons/eye-open.svg',
      'file-folded': 'icons/file-folded.svg',
      'file-unfolded': 'icons/file-unfolded.svg',
      heart: 'icons/heart.svg',
      key: 'icons/key.svg',
      'location-pin': 'icons/location-pin.svg',
      'lock-close': 'icons/lock-close.svg',
      'lock-open': 'icons/lock-open.svg',
      mail: 'icons/mail.svg',
      'menu-grid': 'icons/menu-grid.svg',
      'menu-hamburger': 'icons/menu-hamburger.svg',
      'menu-more-horizontal': 'icons/menu-more-horizontal.svg',
      'menu-more-vertical': 'icons/menu-more-vertical.svg',
      minus: 'icons/minus.svg',
      'money-hand': 'icons/money-hand.svg',
      'money-notes': 'icons/money-notes.svg',
      notebook: 'icons/notebook.svg',
      'paper-plane': 'icons/paper-plane.svg',
      'percent-box': 'icons/percent-box.svg',
      'piggy-bank': 'icons/piggy-bank.svg',
      'play-game': 'icons/play-game.svg',
      'play-music': 'icons/play-music.svg',
      'play-tv': 'icons/play-tv.svg',
      person: 'icons/person.svg',
      'person-add': 'icons/person-add.svg',
      'person-check': 'icons/person-check.svg',
      'person-delete': 'icons/person-delete.svg',
      persons: 'icons/persons.svg',
      plus: 'icons/plus.svg',
      qrcode: 'icons/qrcode.svg',
      shop: 'icons/shop.svg',
      star: 'icons/star.svg',
      trash: 'icons/trash.svg',
      wallet: 'icons/wallet.svg',
      warning: 'icons/warning.svg',
      handshake: 'icons/handshake.svg',
      extract: 'icons/extract.svg',
      'chart-arrow-up': 'icons/chart-arrow-up.svg',
      'chat-support': 'icons/chat-support.svg',
      'circle-money-blocked': 'icons/circle-money-blocked.svg',
      house: 'icons/house.svg',
      'money-deposit': 'icons/money-deposit.svg',
      link: 'icons/link.svg',
      'brand-tapton': 'icons/brand-tapton.svg',
      'brand-ton': 'icons/brand-ton.svg',
      bank: 'icons/bank.svg',
      nfc: 'icons/nfc.svg',
      speaker: 'icons/speaker.svg',
      'brand-open-finance': 'icons/brand-open-finance.svg',
      gift: 'icons/gift.svg',
      'chart-bar': 'icons/chart-bar.svg',
      lamp: 'icons/lamp.svg',
      'bolt-lightning': 'icons/bolt-lightning.svg',
      'bolt-lightning-off': 'icons/bolt-lightning-off.svg',
      camera: 'icons/camera.svg',
    },
  },
  comp: {
    accordion: {
      'padding-x': '8px',
      'padding-y': '4px',
      divider: {
        'margin-x': '20px',
      },
      header: {
        'min-height': '40px',
        'corner-radius': '8px',
        'gap-x': '12px',
        'margin-x': '8px',
        'padding-y': '8px',
        'padding-right': '8px',
        'padding-left': '12px',
        content: {
          'gap-y': '2px',
        },
        trailing: {
          'gap-x': '8px',
        },
        hover: {
          background: 'hsla(210, 98.8%, 14.4%, 0.055)',
        },
        focus: {
          'outline-width': '2px',
          'outline-style': 'solid',
          'outline-color': 'hsla(214, 16%, 20%, 0.84)',
        },
      },
      trailing: {
        'gap-x': '8px',
      },
      panel: {
        'padding-x': '0px',
        'padding-top': '0px',
        'padding-bottom': '0px',
        'has-safe-area': {
          'padding-x': '20px',
        },
      },
      variant: {
        item: {
          divider: {
            'margin-x': '20px',
          },
          header: {
            'margin-top': '4px',
            'margin-bottom': '4px',
          },
          panel: {
            'has-safe-area': {
              'padding-bottom': '12px',
            },
          },
        },
        section: {
          'padding-bottom': '8px',
          header: {
            'margin-top': '8px',
            'margin-bottom': '0px',
          },
          panel: {
            'has-safe-area': {
              'padding-bottom': '4px',
            },
          },
        },
      },
    },
    'action-button': {
      background: 'hsla(210, 98.8%, 14.4%, 0.055)',
      height: '36px',
      'min-width': '36px',
      padding: '8px',
      'corner-radius': '8px',
      gap: '4px',
      color: 'hsla(214, 16%, 20%, 1)',
      label: {
        'padding-x': '4px',
        color: 'hsla(214, 16%, 20%, 1)',
      },
      icon: {
        color: 'hsla(206, 97.0%, 4.8%, 0.593)',
      },
      'icon-only': {
        width: '36px',
      },
      hover: {
        background: 'hsla(210, 94.1%, 11.1%, 0.075)',
      },
      focus: {
        'outline-width': '2px',
        'outline-style': 'solid',
        'outline-color': 'hsla(214, 16%, 20%, 0.84)',
        'outline-offset': '2px',
      },
      selected: {
        background: 'hsla(139, 100%, 32%, 0.10)',
        'border-width': '1.5px',
        'border-style': 'solid',
        'border-color': 'hsla(151, 100%, 28%, 0.81)',
        color: 'hsla(153, 100%, 22%, 0.87)',
        hover: {
          background: 'hsla(140, 100%, 33%, 0.16)',
        },
      },
    },
    'alert-modal': {
      content: {
        'padding-top': '12px',
        gap: '12px',
      },
      'screen-size': {
        medium: {
          content: {
            'padding-top': '0',
          },
        },
      },
    },
    alert: {
      'corner-radius': '12px',
      'border-width': '1px',
      'gap-x': '8px',
      content: {
        'gap-y': '4px',
      },
      'padding-top': '16px',
      'padding-right': '16px',
      'padding-bottom': '16px',
      'padding-left': '16px',
      dismissable: {
        'button-wrapper-padding': '4px',
        'padding-right': '0',
      },
      link: {
        'margin-top': '4px',
      },
      neutral: {
        'text-color': 'hsla(214, 16%, 20%, 1)',
        'icon-color': 'hsla(214, 16%, 20%, 1)',
        'border-color': 'hsla(210, 94.1%, 11.1%, 0.075)',
        background: 'hsla(210, 98.8%, 14.4%, 0.055)',
      },
      info: {
        'text-color': 'hsla(226, 99.6%, 31.1%, 0.797)',
        'icon-color': 'hsla(226, 99.6%, 31.1%, 0.797)',
        'border-color': 'hsla(224, 99.5%, 44.9%, 0.224)',
        background: 'hsla(224, 100%, 50.1%, 0.059)',
      },
      warning: {
        'text-color': 'hsla(23, 100%, 36.8%, 0.980)',
        'icon-color': 'hsla(23, 100%, 36.8%, 0.980)',
        'border-color': 'hsla(25, 100%, 50.1%, 0.346)',
        background: 'hsla(25, 100%, 50.5%, 0.095)',
      },
      negative: {
        'text-color': 'hsla(358, 99.8%, 38.3%, 0.832)',
        'icon-color': 'hsla(358, 99.8%, 38.3%, 0.832)',
        'border-color': 'hsla(0, 99.5%, 44.9%, 0.224)',
        background: 'hsla(0, 100%, 50.2%, 0.063)',
      },
      positive: {
        'text-color': 'hsla(153, 100%, 22%, 0.87)',
        'icon-color': 'hsla(153, 100%, 22%, 0.87)',
        'border-color': 'hsla(144, 100%, 29%, 0.32)',
        background: 'hsla(139, 100%, 32%, 0.10)',
      },
    },
    'aspect-ratio': {
      square: {
        'aspect-ratio-width': '1',
        'aspect-ratio-height': '1',
      },
      landscape: {
        'aspect-ratio-width': '4',
        'aspect-ratio-height': '3',
      },
      wide: {
        'aspect-ratio-width': '16',
        'aspect-ratio-height': '9',
      },
      ultrawide: {
        'aspect-ratio-width': '12',
        'aspect-ratio-height': '5',
      },
    },
    avatar: {
      'corner-radius': '500px',
      background: 'hsla(139, 100%, 32%, 0.10)',
      foreground: 'hsla(153, 100%, 22%, 0.87)',
      size: {
        xsmall: {
          label: {
            'max-characters': '1',
          },
          badge: {
            offset: '0',
          },
          size: '32px',
        },
        small: {
          label: {
            'max-characters': '2',
          },
          badge: {
            offset: '2px',
          },
          size: '40px',
        },
        medium: {
          label: {
            'max-characters': '2',
          },
          badge: {
            offset: '4px',
          },
          size: '48px',
        },
        large: {
          label: {
            'max-characters': '2',
          },
          badge: {
            offset: '2px',
          },
          size: '64px',
        },
        xlarge: {
          label: {
            'max-characters': '2',
          },
          badge: {
            offset: '4px',
          },
          size: '96px',
        },
      },
    },
    backdrop: {
      background: 'rgba(0,0,0,0.5)',
    },
    badge: {
      'corner-radius': '500px',
      foreground: 'hsla(0, 0%, 100%, 1)',
      variant: {
        text: {
          'padding-x': '4px',
          'min-width': '20px',
          height: '18px',
          label: {
            'padding-x': '2px',
            color: 'hsla(0, 0%, 100%, 1)',
          },
        },
        icon: {
          width: '24px',
          height: '24px',
        },
        dot: {
          width: '8px',
          height: '8px',
        },
      },
      voice: {
        info: {
          background: 'hsl(226, 70.0%, 55.5%)',
        },
        negative: {
          background: 'hsl(358, 75.0%, 59.0%)',
        },
        warning: {
          background: 'hsl(24, 94.0%, 50.0%)',
        },
        positive: {
          background: 'hsl(151, 55%, 42%)',
        },
        brand: {
          background: 'hsl(151, 55%, 42%)',
          foreground: 'hsl(137, 47%, 97%)',
        },
      },
    },
    box: {
      background: 'hsla(0, 0%, 100%, 1)',
      'padding-y': '20px',
      header: {
        'gap-x': '12px',
        'padding-x': '20px',
        'padding-top': '20px',
        'padding-bottom': '8px',
        'title-color': 'hsla(214, 16%, 20%, 1)',
      },
      main: {
        padding: '0',
      },
      'has-padding': {
        header: {
          'padding-bottom': '0',
        },
        main: {
          padding: '20px',
        },
      },
      'on-drawer': {
        'corner-radius': '0',
        'border-width': '0',
      },
      'screen-size': {
        medium: {
          'corner-radius': '12px',
          'border-width': '1px',
          'border-style': 'solid',
          'border-color': 'hsla(210, 94.1%, 11.1%, 0.075)',
        },
      },
    },
    'button-group': {
      gap: '8px',
    },
    button: {
      'corner-radius': '8px',
      label: {
        'padding-x': '4px',
      },
      large: {
        height: '48px',
        'min-width': '48px',
        'padding-x': '16px',
        'gap-x': '4px',
      },
      medium: {
        height: '40px',
        'min-width': '40px',
        'padding-x': '12px',
        'gap-x': '4px',
      },
      small: {
        height: '32px',
        'min-width': '32px',
        'padding-x': '8px',
        'gap-x': '2px',
      },
      focus: {
        'outline-width': '2px',
        'outline-style': 'solid',
        'outline-color': 'hsla(214, 16%, 20%, 0.84)',
        'outline-offset': '2px',
      },
      disabled: {
        background: 'hsla(210, 98.8%, 14.4%, 0.055)',
        label: {
          color: 'hsla(205, 96.5%, 10.0%, 0.244)',
        },
        icon: {
          color: 'hsla(205, 96.5%, 10.0%, 0.244)',
        },
      },
      loading: {
        opacity: 0.5,
      },
      neutral: {
        subtle: {
          background: 'hsla(210, 98.8%, 14.4%, 0.055)',
          label: {
            color: 'hsla(214, 16%, 20%, 1)',
          },
          icon: {
            color: 'hsla(206, 97.0%, 4.8%, 0.593)',
          },
          hover: {
            background: 'hsla(210, 94.1%, 11.1%, 0.075)',
          },
          focus: {
            'outline-color': 'hsla(214, 16%, 20%, 0.84)',
          },
          disabled: {
            background: 'hsla(210, 98.8%, 14.4%, 0.055)',
            label: {
              color: 'hsla(205, 96.5%, 10.0%, 0.244)',
            },
            icon: {
              color: 'hsla(205, 96.5%, 10.0%, 0.244)',
            },
          },
        },
        ghost: {
          background: 'transparent',
          label: {
            color: 'hsla(214, 16%, 20%, 1)',
          },
          icon: {
            color: 'hsla(206, 97.0%, 4.8%, 0.593)',
          },
          hover: {
            background: 'hsla(210, 98.8%, 14.4%, 0.055)',
          },
          focus: {
            'outline-color': 'hsla(214, 16%, 20%, 0.84)',
          },
          disabled: {
            background: 'transparent',
            label: {
              color: 'hsla(205, 96.5%, 10.0%, 0.244)',
            },
            icon: {
              color: 'hsla(205, 96.5%, 10.0%, 0.244)',
            },
          },
        },
      },
      primary: {
        solid: {
          background: 'hsl(151, 55%, 42%)',
          label: {
            color: 'hsl(137, 47%, 97%)',
          },
          icon: {
            color: 'hsl(137, 47%, 97%)',
          },
          hover: {
            background: 'hsl(152, 56%, 39%)',
          },
          focus: {
            'outline-color': 'hsla(214, 16%, 20%, 0.84)',
          },
          disabled: {
            background: 'hsla(210, 98.8%, 14.4%, 0.055)',
            label: {
              color: 'hsla(205, 96.5%, 10.0%, 0.244)',
            },
            icon: {
              color: 'hsla(205, 96.5%, 10.0%, 0.244)',
            },
          },
        },
        ghost: {
          background: 'transparent',
          label: {
            color: 'hsla(153, 100%, 22%, 0.87)',
          },
          icon: {
            color: 'hsla(153, 100%, 22%, 0.87)',
          },
          hover: {
            background: 'hsla(139, 100%, 32%, 0.10)',
          },
          focus: {
            'outline-color': 'hsla(214, 16%, 20%, 0.84)',
          },
          disabled: {
            background: 'transparent',
            label: {
              color: 'hsla(205, 96.5%, 10.0%, 0.244)',
            },
            icon: {
              color: 'hsla(205, 96.5%, 10.0%, 0.244)',
            },
          },
        },
      },
      danger: {
        subtle: {
          background: 'hsla(0, 100%, 50.2%, 0.063)',
          label: {
            color: 'hsla(358, 99.8%, 38.3%, 0.832)',
          },
          icon: {
            color: 'hsla(358, 99.8%, 38.3%, 0.832)',
          },
          hover: {
            background: 'hsla(0, 100%, 50.0%, 0.102)',
          },
          focus: {
            'outline-color': 'hsla(214, 16%, 20%, 0.84)',
          },
          disabled: {
            background: 'hsla(210, 98.8%, 14.4%, 0.055)',
            label: {
              color: 'hsla(205, 96.5%, 10.0%, 0.244)',
            },
            icon: {
              color: 'hsla(205, 96.5%, 10.0%, 0.244)',
            },
          },
        },
        ghost: {
          background: 'transparent',
          label: {
            color: 'hsla(358, 99.8%, 38.3%, 0.832)',
          },
          icon: {
            color: 'hsla(358, 99.8%, 38.3%, 0.832)',
          },
          hover: {
            background: 'hsla(0, 100%, 50.2%, 0.063)',
          },
          focus: {
            'outline-color': 'hsla(214, 16%, 20%, 0.84)',
          },
          disabled: {
            background: 'transparent',
            label: {
              color: 'hsla(205, 96.5%, 10.0%, 0.244)',
            },
            icon: {
              color: 'hsla(205, 96.5%, 10.0%, 0.244)',
            },
          },
        },
      },
      'on-color': {
        solid: {
          background: 'hsla(0, 0%, 100%, 1)',
          label: {
            color: 'hsla(214, 16%, 20%, 1)',
          },
          icon: {
            color: 'hsla(214, 16%, 20%, 1)',
          },
          hover: {
            background: 'hsl(209, 13.3%, 95.3%)',
          },
          focus: {
            'outline-color': 'hsla(0, 0%, 100%, 1)',
          },
          disabled: {
            background: 'hsla(0, 0%, 100%, 0.124)',
            icon: {
              color: 'hsla(0, 0%, 100%, 0.50)',
            },
            label: {
              color: 'hsla(0, 0%, 100%, 0.50)',
            },
          },
        },
        subtle: {
          background: 'hsla(0, 0%, 100%, 0.124)',
          label: {
            color: 'hsla(0, 0%, 100%, 1)',
          },
          icon: {
            color: 'hsla(0, 0%, 100%, 1)',
          },
          hover: {
            background: 'hsla(0, 0%, 100%, 0.176)',
          },
          focus: {
            'outline-color': 'hsla(0, 0%, 100%, 1)',
          },
          disabled: {
            background: 'hsla(0, 0%, 100%, 0.124)',
            icon: {
              color: 'hsla(0, 0%, 100%, 0.50)',
            },
            label: {
              color: 'hsla(0, 0%, 100%, 0.50)',
            },
          },
        },
        ghost: {
          background: 'transparent',
          label: {
            color: 'hsla(0, 0%, 100%, 1)',
          },
          icon: {
            color: 'hsla(0, 0%, 100%, 1)',
          },
          hover: {
            background: 'hsla(0, 0%, 100%, 0.124)',
          },
          focus: {
            'outline-color': 'hsla(0, 0%, 100%, 1)',
          },
          disabled: {
            background: 'transparent',
            icon: {
              color: 'hsla(0, 0%, 100%, 0.50)',
            },
            label: {
              color: 'hsla(0, 0%, 100%, 0.50)',
            },
          },
        },
      },
      'on-brand': {
        solid: {
          background: 'hsla(0, 0%, 100%, 1)',
          label: {
            color: 'hsla(153, 100%, 22%, 0.87)',
          },
          icon: {
            color: 'hsla(153, 100%, 22%, 0.87)',
          },
          hover: {
            background: 'hsl(209, 13.3%, 95.3%)',
          },
          focus: {
            'outline-color': 'hsl(137, 47%, 97%)',
          },
          disabled: {
            background: 'hsla(0, 0%, 100%, 0.176)',
            icon: {
              color: 'hsl(146, 40%, 68%)',
            },
            label: {
              color: 'hsl(146, 40%, 68%)',
            },
          },
        },
        subtle: {
          background: 'hsla(0, 0%, 100%, 0.176)',
          label: {
            color: 'hsl(137, 47%, 97%)',
          },
          icon: {
            color: 'hsl(137, 47%, 97%)',
          },
          hover: {
            background: 'hsla(0, 0%, 100%, 0.249)',
          },
          focus: {
            'outline-color': 'hsl(137, 47%, 97%)',
          },
          disabled: {
            background: 'hsla(0, 0%, 100%, 0.176)',
            icon: {
              color: 'hsl(146, 40%, 68%)',
            },
            label: {
              color: 'hsl(146, 40%, 68%)',
            },
          },
        },
        ghost: {
          background: 'transparent',
          label: {
            color: 'hsl(137, 47%, 97%)',
          },
          icon: {
            color: 'hsl(137, 47%, 97%)',
          },
          hover: {
            background: 'hsla(0, 0%, 100%, 0.176)',
          },
          focus: {
            'outline-color': 'hsl(137, 47%, 97%)',
          },
          disabled: {
            background: 'transparent',
            icon: {
              color: 'hsl(146, 40%, 68%)',
            },
            label: {
              color: 'hsl(146, 40%, 68%)',
            },
          },
        },
      },
    },
    'checkbox-group': {
      'gap-y': '12px',
      label: {
        color: 'hsla(206, 97.0%, 4.8%, 0.593)',
        wrapper: {
          'gap-x': '4px',
        },
      },
      items: {
        gap: '16px',
      },
      support: {
        color: 'hsla(206, 97.0%, 4.8%, 0.593)',
        wrapper: {
          'padding-x': '2px',
          'gap-x': '4px',
        },
        text: {
          'gap-x': '4px',
        },
      },
      error: {
        label: {
          color: 'hsla(358, 99.8%, 38.3%, 0.832)',
        },
        support: {
          color: 'hsla(358, 99.8%, 38.3%, 0.832)',
        },
      },
      disabled: {
        label: {
          color: 'hsla(205, 96.5%, 10.0%, 0.244)',
        },
        support: {
          color: 'hsla(205, 96.5%, 10.0%, 0.244)',
        },
      },
    },
    checkbox: {
      wrapper: {
        'column-gap': '8px',
      },
      box: {
        background: 'hsla(0, 0%, 100%, 1)',
        'border-color': 'hsla(206, 96.4%, 11.3%, 0.126)',
        'border-width': '2px',
        'corner-radius': '4px',
        wrapper: {
          width: '20px',
          height: '20px',
        },
      },
      label: {
        color: 'hsla(214, 16%, 20%, 1)',
      },
      support: {
        color: 'hsla(206, 97.0%, 4.8%, 0.593)',
      },
      hover: {
        box: {
          'border-color': 'hsla(205, 96.5%, 10.0%, 0.244)',
        },
      },
      checked: {
        box: {
          background: 'hsl(151, 55%, 42%)',
          'border-width': '0',
        },
        marker: {
          color: 'hsl(137, 47%, 97%)',
        },
        hover: {
          box: {
            background: 'hsl(152, 56%, 39%)',
          },
        },
      },
      error: {
        box: {
          background: 'hsla(0, 100%, 50.2%, 0.063)',
          'border-color': 'hsla(358, 99.9%, 42.9%, 0.718)',
        },
        support: {
          color: 'hsla(358, 99.8%, 38.3%, 0.832)',
        },
        hover: {
          box: {
            background: 'hsla(0, 100%, 50.0%, 0.102)',
          },
        },
      },
      focus: {
        'outline-width': '2px',
        'outline-style': 'solid',
        'outline-color': 'hsla(214, 16%, 20%, 0.84)',
        'outline-offset': '2px',
      },
      disabled: {
        box: {
          background: 'hsla(210, 98.8%, 14.4%, 0.055)',
          'border-color': 'hsla(210, 94.1%, 11.1%, 0.075)',
          'border-width': '2px',
        },
        label: {
          color: 'hsla(205, 96.5%, 10.0%, 0.244)',
        },
        support: {
          color: 'hsla(205, 96.5%, 10.0%, 0.244)',
        },
        checked: {
          marker: {
            color: 'hsla(205, 96.5%, 10.0%, 0.244)',
          },
        },
      },
    },
    'content-navigator': {
      padding: '4px',
      'gap-x': '24px',
      main: {
        'gap-y': '2px',
        'padding-y': '8px',
        'corner-radius': '8px',
        leading: {
          'margin-right': '8px',
        },
        'dropdown-indicator': {
          'margin-left': '2px',
        },
      },
      'is-boxed': {
        'corner-radius': '12px',
        background: 'hsla(0, 0%, 100%, 1)',
      },
      focus: {
        main: {
          'outline-width': '2px',
          'outline-style': 'solid',
          'outline-color': 'hsla(214, 16%, 20%, 0.84)',
          'outline-offset': '0',
        },
      },
      'screen-size': {
        medium: {
          padding: '8px',
        },
      },
    },
    'date-picker': {
      'min-width': '296px',
      header: {
        'padding-bottom': '8px',
        'display-mode': {
          'gap-x': '4px',
          'min-height': '40px',
          'padding-y': '8px',
          focus: {
            outline: {
              width: '2px',
              style: 'solid',
              color: 'hsla(214, 16%, 20%, 0.84)',
            },
            'outline-offset': '2px',
          },
        },
        navigation: {
          'gap-x': '4px',
        },
      },
      display: {
        days: {
          main: {
            'gap-y': '8px',
            'min-height': '292px',
            'max-height': '344px',
          },
          'week-header': {
            height: '32px',
            foreground: 'hsla(206, 97.0%, 4.8%, 0.593)',
          },
          cell: {
            item: {
              width: '44px',
              height: '44px',
              'corner-radius': '500px',
              foreground: 'hsla(214, 16%, 20%, 1)',
              background: 'transparent',
              today: {
                foreground: 'hsla(153, 100%, 22%, 0.87)',
                dot: {
                  width: '4px',
                  height: '4px',
                  'offset-bottom': '8px',
                  'corner-radius': '500px',
                  background: 'hsla(153, 100%, 22%, 0.87)',
                },
              },
              hover: {
                background: 'hsla(210, 98.8%, 14.4%, 0.055)',
              },
              focus: {
                outline: {
                  width: '2px',
                  style: 'solid',
                  color: 'hsla(214, 16%, 20%, 0.84)',
                },
              },
            },
            variant: {
              selected: {
                background: 'hsla(139, 100%, 32%, 0.10)',
                item: {
                  foreground: 'hsl(137, 47%, 97%)',
                  background: 'hsl(151, 55%, 42%)',
                  today: {
                    dot: {
                      background: 'hsl(137, 47%, 97%)',
                    },
                  },
                  hover: {
                    background: 'hsl(152, 56%, 39%)',
                  },
                },
              },
              'in-range': {
                background: 'hsla(139, 100%, 32%, 0.10)',
                item: {
                  foreground: 'hsla(153, 100%, 22%, 0.87)',
                  background: 'transparent',
                  today: {
                    dot: {
                      background: 'hsla(153, 100%, 22%, 0.87)',
                    },
                  },
                  hover: {
                    background: 'hsla(140, 100%, 33%, 0.16)',
                  },
                },
              },
              highlighted: {
                item: {
                  background: 'hsla(139, 100%, 32%, 0.10)',
                  foreground: 'hsla(153, 100%, 22%, 0.87)',
                  today: {
                    dot: {
                      background: 'hsla(153, 100%, 22%, 0.87)',
                    },
                  },
                  hover: {
                    background: 'hsla(140, 100%, 33%, 0.16)',
                  },
                },
              },
              outside: {
                item: {
                  foreground: 'hsla(206, 97.0%, 4.8%, 0.593)',
                  opacity: 0.8,
                  today: {
                    dot: {
                      background: 'hsla(206, 97.0%, 4.8%, 0.593)',
                    },
                  },
                },
              },
              disabled: {
                item: {
                  foreground: 'hsla(205, 96.5%, 10.0%, 0.244)',
                  today: {
                    dot: {
                      background: 'hsla(205, 96.5%, 10.0%, 0.244)',
                    },
                  },
                },
              },
            },
          },
        },
        months: {
          main: {
            'gap-x': '8px',
            'min-height': '292px',
            'max-height': '344px',
          },
          cell: {
            item: {
              height: '40px',
              'margin-y': '8px',
              'margin-x': '12px',
              'padding-y': '8px',
              'padding-x': '12px',
              'corner-radius': '500px',
              background: 'transparent',
              foreground: 'hsla(214, 16%, 20%, 1)',
              hover: {
                background: 'hsla(210, 98.8%, 14.4%, 0.055)',
              },
            },
            variant: {
              default: {
                selected: {
                  item: {
                    background: 'hsl(151, 55%, 42%)',
                    foreground: 'hsl(137, 47%, 97%)',
                    hover: {
                      background: 'hsl(152, 56%, 39%)',
                    },
                  },
                },
                disabled: {
                  item: {
                    foreground: 'hsla(205, 96.5%, 10.0%, 0.244)',
                  },
                },
              },
            },
          },
        },
      },
    },
    'display-item': {
      'gap-y': '4px',
      label: {
        'gap-x': '4px',
        color: 'hsla(206, 97.0%, 4.8%, 0.593)',
      },
      content: {
        color: 'hsla(214, 16%, 20%, 1)',
        tag: {
          'margin-top': '4px',
        },
      },
      support: {
        color: 'hsla(206, 97.0%, 4.8%, 0.593)',
      },
    },
    divider: {
      background: 'hsla(210, 94.1%, 11.1%, 0.075)',
      horizontal: {
        width: '100%',
        height: '1px',
        small: {
          width: '100%',
          height: '1px',
        },
        medium: {
          width: '100%',
          height: '2px',
        },
        large: {
          width: '100%',
          height: '6px',
        },
      },
      vertical: {
        width: '1px',
        height: '100%',
        small: {
          width: '1px',
          height: '100%',
        },
        medium: {
          width: '2px',
          height: '100%',
        },
        large: {
          width: '6px',
          height: '100%',
        },
      },
    },
    drawer: {
      background: 'hsl(209, 13.3%, 95.3%)',
      width: '100%',
      'min-height': '100%',
      header: {
        height: '64px',
        background: 'hsl(209, 13.3%, 95.3%)',
        'border-bottom-width': '1px',
        'border-bottom-style': 'solid',
        'border-bottom-color': 'hsla(210, 94.1%, 11.1%, 0.075)',
        'title-color': 'hsla(214, 16%, 20%, 1)',
        'gap-x': '4px',
        'padding-y': '12px',
        'padding-left': '8px',
        'padding-right': '12px',
      },
      main: {
        'gap-y': '8px',
      },
      animation: {
        duration: '320ms',
        'timing-function': 'ease-in-out',
        from: {
          'translate-x': '-100%',
        },
        to: {
          'translate-x': '0',
        },
      },
      'screen-size': {
        medium: {
          width: '400px',
          'border-left-width': '1px',
          'border-left-style': 'solid',
          'border-left-color': 'hsla(210, 94.1%, 11.1%, 0.075)',
          'box-shadow-offset-x': '0px',
          'box-shadow-offset-y': '12px',
          'box-shadow-blur-radius': '60px',
          'box-shadow-spread-radius': '-4px',
          'box-shadow-color': 'rgba(43, 50, 59, 0.12)',
          header: {
            background: 'hsla(0, 0%, 100%, 1)',
          },
        },
      },
    },
    'dropdown-menu': {
      'corner-radius': '12px',
      background: 'hsla(0, 0%, 100%, 1)',
      margin: '8px',
      navigation: {
        padding: '8px',
        content: {
          'corner-radius': '8px',
          'text-color': 'hsla(214, 16%, 20%, 1)',
          'gap-x': '16px',
          'padding-y': '8px',
          'padding-x': '12px',
          hover: {
            background: 'hsla(210, 98.8%, 14.4%, 0.055)',
          },
        },
      },
      section: {
        header: {
          'padding-top': '16px',
          'padding-x': '20px',
          'text-color': 'hsla(206, 97.0%, 4.8%, 0.593)',
        },
        main: {
          padding: '8px',
          gap: '2px',
        },
      },
      'screen-size': {
        medium: {
          width: '300px',
          margin: '0px',
          'box-shadow-offset-x': '0px',
          'box-shadow-offset-y': '8px',
          'box-shadow-blur-radius': '24px',
          'box-shadow-spread-radius': '-12px',
          'box-shadow-color': 'rgba(43, 50, 59, 0.20)',
          'border-width': '1px',
          'border-style': 'solid',
          'border-color': 'hsla(210, 94.1%, 11.1%, 0.075)',
        },
      },
    },
    'dropdown-selection': {
      header: {
        height: '56px',
        'padding-left': '20px',
        'padding-y': '16px',
        'gap-x': '4px',
        close: {
          'corner-radius': '4px',
          'margin-right': '12px',
          padding: '8px',
          color: 'hsla(206, 97.0%, 4.8%, 0.593)',
          hover: {
            color: 'hsla(214, 16%, 20%, 1)',
          },
          focus: {
            outline: {
              width: '2px',
              style: 'solid',
              color: 'hsla(214, 16%, 20%, 0.84)',
              offset: '0',
            },
          },
          'padding-right': '12px',
          'padding-y': '8px',
        },
      },
      search: {
        'padding-x': '20px',
        'padding-top': '16px',
        'padding-bottom': '4px',
      },
      list: {
        'padding-y': '8px',
        'gap-y': '2px',
      },
      footer: {
        background: 'hsla(0, 0%, 100%, 1)',
        'padding-y': '20px',
        'padding-x': '20px',
      },
      'screen-size': {
        medium: {
          'max-height': '600px',
          header: {
            height: '52px',
            'padding-left': '16px',
            'padding-y': '16px',
          },
          search: {
            'padding-top': '12px',
            'padding-x': '16px',
          },
          list: {
            'gap-y': '0',
          },
          footer: {
            'padding-y': '16px',
            'padding-x': '16px',
          },
        },
      },
    },
    dropdown: {
      width: '100%',
      'max-height': '100%',
      background: 'hsla(0, 0%, 100%, 1)',
      'corner-radius': '12px',
      'border-width': '1px',
      'border-style': 'solid',
      'border-color': 'hsla(210, 94.1%, 11.1%, 0.075)',
      navigation: {
        padding: '8px',
        content: {
          'corner-radius': '8px',
          'text-color': 'hsla(214, 16%, 20%, 1)',
          'gap-x': '16px',
          'padding-y': '8px',
          'padding-x': '12px',
          hover: {
            background: 'hsla(210, 98.8%, 14.4%, 0.055)',
          },
          focus: {
            'outline-width': '2px',
            'outline-style': 'solid',
            'outline-color': 'hsla(214, 16%, 20%, 0.84)',
          },
        },
      },
      'screen-size': {
        medium: {
          shadow: {
            color: 'hsla(210, 94.1%, 11.1%, 0.075)',
            offsetX: '0',
            offsetY: '5px',
            blur: '20px',
          },
          width: '300px',
          'max-height': '472px',
        },
      },
    },
    empty: {
      'gap-y': '24px',
      content: {
        'gap-y': '8px',
      },
    },
    feedback: {
      gap: '24px',
      content: {
        gap: '16px',
      },
    },
    'form-field': {
      wrapper: {
        'gap-y': '4px',
      },
      label: {
        wrapper: {
          'gap-x': '8px',
        },
        content: {
          color: 'hsla(206, 97.0%, 4.8%, 0.593)',
        },
      },
      'optional-indicator': {
        color: 'hsla(206, 97.0%, 4.8%, 0.593)',
      },
      'input-wrapper': {
        background: 'hsla(0, 0%, 100%, 1)',
        'border-width': '1.5px',
        'border-color': 'hsla(206, 96.4%, 11.3%, 0.126)',
        'corner-radius': '8px',
        'padding-left': '12px',
        'padding-right': '4px',
        'gap-x': '8px',
      },
      adornment: {
        leading: {
          height: '20px',
          'min-width': '20px',
          color: 'hsla(206, 97.0%, 4.8%, 0.593)',
        },
        trailing: {
          height: '40px',
          'min-width': '40px',
          text: {
            'padding-right': '8px',
          },
        },
      },
      input: {
        'font-family': 'Inter',
        'font-weight': 500,
        'placeholder-color': 'hsla(205, 96.5%, 10.0%, 0.244)',
        color: 'hsla(214, 16%, 20%, 1)',
        'padding-right': '8px',
      },
      support: {
        color: 'hsla(206, 97.0%, 4.8%, 0.593)',
        'gap-x': '20px',
        wrapper: {
          'padding-x': '2px',
        },
        text: {
          'gap-x': '4px',
        },
      },
      large: {
        'input-wrapper': {
          height: '48px',
        },
        input: {
          'font-size': '16px',
          'line-height': '24px',
          'letter-spacing': '-0.011em',
        },
      },
      medium: {
        'input-wrapper': {
          height: '40px',
        },
        input: {
          'font-size': '14px',
          'line-height': '20px',
          'letter-spacing': '-0.006em',
        },
      },
      hover: {
        'input-wrapper': {
          'border-color': 'hsla(205, 96.5%, 10.0%, 0.244)',
        },
      },
      focus: {
        'input-wrapper': {
          background: 'hsla(0, 0%, 100%, 1)',
          'border-color': 'transparent',
          'outline-width': '2px',
          'outline-style': 'solid',
          'outline-color': 'hsla(151, 100%, 28%, 0.81)',
          'outline-offset': '-2px',
        },
      },
      error: {
        'label-content': {
          color: 'hsla(358, 99.8%, 38.3%, 0.832)',
        },
        'input-wrapper': {
          background: 'hsla(0, 100%, 50.2%, 0.063)',
          'border-color': 'hsla(358, 99.9%, 42.9%, 0.718)',
        },
        adornment: {
          leading: {
            color: 'hsla(358, 99.8%, 38.3%, 0.832)',
          },
        },
        support: {
          color: 'hsla(358, 99.8%, 38.3%, 0.832)',
        },
        hover: {
          'input-wrapper': {
            background: 'hsla(0, 100%, 50.0%, 0.102)',
          },
        },
        focus: {
          'input-wrapper': {
            background: 'hsla(0, 0%, 100%, 1)',
            'outline-color': 'hsla(358, 99.9%, 42.9%, 0.718)',
          },
        },
      },
      disabled: {
        label: {
          content: {
            color: 'hsla(205, 96.5%, 10.0%, 0.244)',
          },
        },
        'input-wrapper': {
          background: 'hsla(210, 98.8%, 14.4%, 0.055)',
          'border-color': 'hsla(210, 94.1%, 11.1%, 0.075)',
        },
        input: {
          color: 'hsla(205, 96.5%, 10.0%, 0.244)',
        },
        adornment: {
          opacity: 0.4,
        },
        support: {
          color: 'hsla(205, 96.5%, 10.0%, 0.244)',
        },
      },
    },
    'group-header': {
      'gap-x': '12px',
      'margin-top': '12px',
      'margin-x': '8px',
      'padding-y': '8px',
      'padding-right': '8px',
      'padding-left': '12px',
      'corner-radius': '8px',
      interactive: {
        hover: {
          background: 'hsla(210, 98.8%, 14.4%, 0.055)',
        },
        focus: {
          'outline-width': '2px',
          'outline-style': 'solid',
          'outline-color': 'hsla(214, 16%, 20%, 0.84)',
        },
      },
    },
    hyperlink: {
      color: 'hsla(153, 100%, 22%, 0.87)',
      'font-weight': 600,
      'text-decoration': 'underline',
    },
    'icon-button': {
      large: {
        height: '48px',
        width: '48px',
      },
      medium: {
        height: '40px',
        width: '40px',
      },
      small: {
        height: '32px',
        width: '32px',
      },
    },
    'icon-shape': {
      'corner-radius': '500px',
      neutral: {
        color: 'hsla(214, 16%, 20%, 1)',
        background: 'hsla(210, 98.8%, 14.4%, 0.055)',
      },
      brand: {
        color: 'hsla(153, 100%, 22%, 0.87)',
        background: 'hsla(139, 100%, 32%, 0.10)',
      },
      positive: {
        color: 'hsla(153, 100%, 22%, 0.87)',
        background: 'hsla(139, 100%, 32%, 0.10)',
      },
      negative: {
        color: 'hsla(358, 99.8%, 38.3%, 0.832)',
        background: 'hsla(0, 100%, 50.2%, 0.063)',
      },
      warning: {
        color: 'hsla(23, 100%, 36.8%, 0.980)',
        background: 'hsla(25, 100%, 50.5%, 0.095)',
      },
      info: {
        color: 'hsla(226, 99.6%, 31.1%, 0.797)',
        background: 'hsla(224, 100%, 50.1%, 0.059)',
      },
      small: {
        height: '40px',
        width: '40px',
      },
      medium: {
        height: '48px',
        width: '48px',
      },
      large: {
        height: '96px',
        width: '96px',
      },
    },
    icon: {
      'content-color': {
        high: 'hsla(214, 16%, 20%, 1)',
        medium: 'hsla(206, 97.0%, 4.8%, 0.593)',
        low: 'hsla(205, 96.5%, 10.0%, 0.244)',
        brand: 'hsla(153, 100%, 22%, 0.87)',
        positive: 'hsla(153, 100%, 22%, 0.87)',
        negative: 'hsla(358, 99.8%, 38.3%, 0.832)',
        warning: 'hsla(23, 100%, 36.8%, 0.980)',
        info: 'hsla(226, 99.6%, 31.1%, 0.797)',
        'on-color': 'hsla(0, 0%, 100%, 1)',
        'on-color-high': 'hsla(0, 0%, 100%, 1)',
        'on-color-medium': 'hsla(0, 0%, 100%, 0.80)',
        'on-color-low': 'hsla(0, 0%, 100%, 0.50)',
        'on-brand': 'hsl(137, 47%, 97%)',
        'on-brand-high': 'hsl(137, 47%, 97%)',
        'on-brand-medium': 'hsl(142, 44%, 84%)',
        'on-brand-low': 'hsl(146, 40%, 68%)',
      },
      xlarge: {
        height: '32px',
        width: '32px',
      },
      large: {
        height: '24px',
        width: '24px',
      },
      medium: {
        height: '20px',
        width: '20px',
      },
      small: {
        height: '16px',
        width: '16px',
      },
    },
    'input-pin': {
      wrapper: {
        gap: '8px',
      },
      input: {
        'max-width': '48px',
        height: '48px',
      },
    },
    link: {
      'corner-radius': '4px',
      'gap-x': '4px',
      decoration: 'none',
      'content-color': {
        neutral: 'hsla(214, 16%, 20%, 1)',
        brand: 'hsla(153, 100%, 22%, 0.87)',
        'on-brand': 'hsl(137, 47%, 97%)',
        positive: 'hsla(153, 100%, 22%, 0.87)',
        negative: 'hsla(358, 99.8%, 38.3%, 0.832)',
        warning: 'hsla(23, 100%, 36.8%, 0.980)',
        info: 'hsla(226, 99.6%, 31.1%, 0.797)',
        'on-color': 'hsla(0, 0%, 100%, 1)',
      },
      hover: {
        decoration: 'underline',
      },
      focus: {
        'outline-width': '2px',
        'outline-style': 'solid',
        'outline-color': 'hsla(214, 16%, 20%, 0.84)',
        'outline-offset': '2px',
      },
    },
    'list-content': {
      'min-height': '48px',
      'padding-y': '12px',
      'gap-x': '12px',
      icon: 'hsla(206, 97.0%, 4.8%, 0.593)',
      label: {
        wrapper: {
          'gap-x': '4px',
          'max-width': '150px',
        },
        color: 'hsla(206, 97.0%, 4.8%, 0.593)',
      },
      'is-condensed': {
        'min-height': '34px',
        'padding-y': '4px',
      },
    },
    'list-item': {
      'min-height': '40px',
      'gap-x': '12px',
      'corner-radius': '8px',
      'margin-y': '4px',
      'margin-x': '8px',
      'padding-left': '12px',
      'padding-right': '8px',
      'padding-y': '8px',
      leading: {
        'min-width': '20px',
      },
      trailing: {
        'padding-right': '4px',
      },
      'is-compact': {
        'min-height': '28px',
        'padding-y': '4px',
        'margin-y': '2px',
      },
      'main-content': {
        'gap-y': '2px',
        label: {
          'gap-x': '4px',
        },
      },
      hover: {
        background: 'hsla(210, 98.8%, 14.4%, 0.055)',
      },
      danger: {
        hover: {
          background: 'hsla(0, 100%, 50.0%, 0.102)',
        },
      },
      'is-active': {
        background: 'hsla(139, 100%, 32%, 0.10)',
        hover: {
          background: 'hsla(140, 100%, 33%, 0.16)',
        },
      },
      'is-selection': {
        trailing: {
          'gap-x': '8px',
        },
        checked: {
          background: 'hsla(139, 100%, 32%, 0.10)',
          hover: {
            background: 'hsla(140, 100%, 33%, 0.16)',
          },
        },
      },
      focus: {
        'outline-width': '2px',
        'outline-style': 'solid',
        'outline-color': 'hsla(214, 16%, 20%, 0.84)',
        'outline-offset': '2px',
      },
    },
    'list-navigation': {
      'gap-x': '12px',
      'corner-radius': '8px',
      'padding-y': '12px',
      'padding-left': '12px',
      'padding-right': '4px',
      'margin-x': '8px',
      content: {
        'gap-y': '2px',
      },
      'right-area': {
        'gap-x': '8px',
        icon: {
          color: 'hsla(205, 96.5%, 10.0%, 0.244)',
        },
      },
    },
    hover: {
      background: 'hsla(210, 98.8%, 14.4%, 0.055)',
    },
    focus: {
      'outline-width': '2px',
      'outline-style': 'solid',
      'outline-color': 'hsla(214, 16%, 20%, 0.84)',
      'outline-offset': '0px',
    },
    'list-subheader': {
      'gap-x': '12px',
      'padding-x': '20px',
      'padding-y': '16px',
      label: {
        color: 'hsla(206, 97.0%, 4.8%, 0.593)',
      },
      'value-neutral': {
        color: 'hsla(206, 97.0%, 4.8%, 0.593)',
      },
      'value-positive': {
        color: 'hsla(153, 100%, 22%, 0.87)',
      },
      'value-negative': {
        color: 'hsla(358, 99.8%, 38.3%, 0.832)',
      },
      'border-bottom': {
        'border-color': 'hsla(210, 94.1%, 11.1%, 0.075)',
        'border-width': '1px',
      },
    },
    'list-topic': {
      'gap-x': '12px',
      'gap-y': '2px',
      'padding-y': '12px',
      title: {
        color: 'hsla(214, 16%, 20%, 1)',
      },
      comment: {
        color: 'hsla(206, 97.0%, 4.8%, 0.593)',
      },
    },
    'menu-item': {
      'corner-radius': '8px',
      'padding-y': '8px',
      'padding-left': '12px',
      'padding-right': '8px',
      gap: '12px',
      'min-height': '40px',
      'leading-color': 'hsla(206, 97.0%, 4.8%, 0.593)',
      content: {
        'gap-y': '2px',
      },
      label: {
        'text-color': 'hsla(214, 16%, 20%, 1)',
      },
      support: {
        'text-color': 'hsla(206, 97.0%, 4.8%, 0.593)',
      },
      'right-area': {
        'gap-x': '4px',
      },
      trailing: {
        'min-height': '20px',
        color: 'hsla(206, 97.0%, 4.8%, 0.593)',
        'padding-right': '4px',
      },
      hover: {
        background: 'hsla(210, 98.8%, 14.4%, 0.055)',
      },
      focus: {
        'outline-width': '2px',
        'outline-style': 'solid',
        'outline-color': 'hsla(214, 16%, 20%, 0.84)',
        'outline-offset': '0px',
      },
      disabled: {
        opacity: 0.3,
      },
      'is-active': {
        background: 'hsla(139, 100%, 32%, 0.10)',
        'leading-color': 'hsla(153, 100%, 22%, 0.87)',
        'content-color': 'hsla(153, 100%, 22%, 0.87)',
        hover: {
          background: 'hsla(140, 100%, 33%, 0.16)',
        },
      },
      danger: {
        'leading-color': 'hsla(358, 99.8%, 38.3%, 0.832)',
        'text-color': 'hsla(358, 99.8%, 38.3%, 0.832)',
        hover: {
          background: 'hsla(0, 100%, 50.0%, 0.102)',
        },
      },
    },
    modal: {
      width: '100%',
      'max-width': '500px',
      'max-height': '75%',
      background: 'hsla(0, 0%, 100%, 1)',
      'corner-radius': '12px',
      margin: '8px',
      'grabber-wrapper': {
        height: '12px',
      },
      'grabber-indicator': {
        width: '32px',
        height: '4px',
        'corner-radius': '500px',
        color: 'hsla(205, 96.5%, 10.0%, 0.244)',
      },
      close: {
        background: 'hsla(0, 0%, 100%, 0.249)',
      },
      'close-position': {
        top: '8px',
        right: '8px',
      },
      header: {
        height: '56px',
        'padding-left': '20px',
        'padding-right': '56px',
        background: 'hsla(0, 0%, 100%, 1)',
        'border-bottom-width': '1px',
        'border-bottom-style': 'solid',
        'border-bottom-color': 'hsla(210, 94.1%, 11.1%, 0.075)',
        'text-color': 'hsla(214, 16%, 20%, 1)',
      },
      content: {
        gap: '8px',
        padding: '20px',
      },
      footer: {
        background: 'hsla(0, 0%, 100%, 1)',
        padding: '16px',
        'padding-top': '0',
      },
      'is-paddingless': {
        content: {
          padding: '0',
          'padding-bottom': '20px',
        },
      },
      'is-height-fixed': {
        'margin-top': '40px',
        'max-height': '100%',
        'min-height': '100%',
        content: {
          'padding-bottom': '20px',
        },
        footer: {
          'padding-top': '16px',
          'border-top-width': '1px',
          'border-top-style': 'solid',
          'border-top-color': 'hsla(210, 94.1%, 11.1%, 0.075)',
        },
      },
      'screen-size': {
        medium: {
          width: '500px',
          'max-height': '650px',
          margin: '0px',
          content: {
            padding: '24px',
          },
          footer: {
            'border-top-width': '1px',
            'border-top-style': 'solid',
            'border-top-color': 'hsla(210, 94.1%, 11.1%, 0.075)',
          },
          'is-paddingless': {
            content: {
              'padding-bottom': '0',
            },
          },
          'is-height-fixed': {
            'margin-top': '0px',
            'min-height': '90%',
            'max-height': '860px',
          },
        },
      },
    },
    'navigation-bar': {
      'min-height': '44px',
      'padding-y': '4px',
      'padding-left': '16px',
      'padding-right': '16px',
      background: {
        surface: 'hsla(0, 0%, 100%, 1)',
        body: 'hsl(209, 13.3%, 95.3%)',
      },
      title: {
        'padding-x': '4px',
      },
      'actions-wrapper': {
        'gap-x': '8px',
      },
      'screen-size': {
        medium: {
          'min-height': '64px',
          'padding-left': '12px',
          'padding-right': '12px',
          'border-bottom-width': '1px',
          'border-bottom-style': 'solid',
          'border-bottom-color': 'hsla(210, 94.1%, 11.1%, 0.075)',
          title: {
            'padding-x': '8px',
          },
        },
      },
    },
    onboarding: {
      gap: '24px',
      content: {
        gap: '8px',
      },
    },
    'page-container': {
      width: '100%',
      'max-width': '1024px',
      padding: '0',
      'gap-y': '4px',
      'main-content': {
        width: '100%',
      },
      sidebar: {
        width: '100%',
      },
      'screen-size': {
        medium: {
          padding: '40px',
          'gap-y': '32px',
          'gap-x': '24px',
          sidebar: {
            width: '280px',
          },
          narrow: {
            'max-width': '620px',
          },
          wide: {
            'max-width': '1200px',
          },
          full: {
            'max-width': 'unset',
          },
        },
      },
    },
    'page-title': {
      'gap-y': '8px',
    },
    pagination: {
      'gap-x': '16px',
    },
    'radio-group': {
      'gap-y': '12px',
      label: {
        color: 'hsla(206, 97.0%, 4.8%, 0.593)',
        wrapper: {
          'gap-x': '4px',
        },
      },
      items: {
        gap: '16px',
      },
      support: {
        color: 'hsla(206, 97.0%, 4.8%, 0.593)',
        wrapper: {
          'padding-x': '2px',
          'gap-x': '4px',
        },
        text: {
          'gap-x': '4px',
        },
      },
      error: {
        label: {
          color: 'hsla(358, 99.8%, 38.3%, 0.832)',
        },
        support: {
          color: 'hsla(358, 99.8%, 38.3%, 0.832)',
        },
      },
      disabled: {
        label: {
          color: 'hsla(205, 96.5%, 10.0%, 0.244)',
        },
        support: {
          color: 'hsla(205, 96.5%, 10.0%, 0.244)',
        },
      },
    },
    radio: {
      wrapper: {
        'column-gap': '8px',
      },
      box: {
        background: 'hsla(0, 0%, 100%, 1)',
        'border-color': 'hsla(206, 96.4%, 11.3%, 0.126)',
        'border-width': '2px',
        'corner-radius': '500px',
        wrapper: {
          width: '20px',
          height: '20px',
        },
      },
      marker: {
        'corner-radius': '500px',
        width: '8px',
        height: '8px',
      },
      label: {
        color: 'hsla(214, 16%, 20%, 1)',
      },
      support: {
        color: 'hsla(206, 97.0%, 4.8%, 0.593)',
      },
      hover: {
        box: {
          'border-color': 'hsla(205, 96.5%, 10.0%, 0.244)',
        },
      },
      checked: {
        box: {
          background: 'hsl(151, 55%, 42%)',
          'border-width': '0',
        },
        marker: {
          background: 'hsla(0, 0%, 100%, 1)',
        },
        hover: {
          box: {
            background: 'hsl(152, 56%, 39%)',
          },
        },
      },
      focus: {
        'outline-width': '2px',
        'outline-style': 'solid',
        'outline-color': 'hsla(214, 16%, 20%, 0.84)',
        'outline-offset': '2px',
      },
      disabled: {
        box: {
          background: 'hsla(210, 98.8%, 14.4%, 0.055)',
          'border-color': 'hsla(210, 94.1%, 11.1%, 0.075)',
          'border-width': '2px',
        },
        label: {
          color: 'hsla(205, 96.5%, 10.0%, 0.244)',
        },
        support: {
          color: 'hsla(205, 96.5%, 10.0%, 0.244)',
        },
        checked: {
          marker: {
            color: 'hsla(205, 96.5%, 10.0%, 0.244)',
          },
        },
      },
    },
    select: {
      'input-wrapper': {
        'padding-right': '32px',
      },
      'dropdown-indicator': {
        icon: "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e\")",
        width: '16px',
        height: '12px',
      },
    },
    shortcut: {
      'gap-y': '8px',
      width: '80px',
      'min-width': '80px',
      'max-width': '80px',
      'icon-area': {
        'corner-radius': '500px',
        width: '60px',
        height: '60px',
      },
      pressed: {
        'icon-area-background': {
          scale: '0.95',
        },
        icon: {
          scale: '0.97',
        },
      },
      focus: {
        'icon-area': {
          'outline-width': '2px',
          'outline-style': 'solid',
          'outline-color': 'hsla(214, 16%, 20%, 0.84)',
          'outline-offset': '2px',
        },
      },
      disabled: {
        icon: {
          color: 'hsla(205, 96.5%, 10.0%, 0.244)',
        },
      },
      neutral: {
        'icon-area': {
          background: 'hsla(210, 98.8%, 14.4%, 0.055)',
        },
        icon: {
          color: 'hsla(214, 16%, 20%, 1)',
        },
        hover: {
          'icon-area': {
            background: 'hsla(210, 94.1%, 11.1%, 0.075)',
          },
        },
      },
      'is-highlight': {
        'icon-area': {
          background: 'hsla(139, 100%, 32%, 0.10)',
        },
        icon: {
          color: 'hsla(153, 100%, 22%, 0.87)',
        },
        hover: {
          'icon-area': {
            background: 'hsla(140, 100%, 33%, 0.16)',
          },
        },
      },
    },
    slider: {
      'gap-y': '8px',
      header: {
        'gap-x': '8px',
      },
      main: {
        'gap-x': '4px',
        'gap-y': '4px',
      },
      'track-area': {
        height: '20px',
      },
      track: {
        'corner-radius': '500px',
        background: 'hsla(216, 91.1%, 10.9%, 0.099)',
        height: '4px',
      },
      'active-track': {
        'corner-radius': '500px',
        background: 'hsl(151, 55%, 42%)',
        height: '4px',
      },
      'knob-area': {
        width: '42px',
        height: '42px',
      },
      knob: {
        width: '16px',
        height: '16px',
        'corner-radius': '500px',
        background: 'hsl(151, 55%, 42%)',
        'border-width': '3px',
        'border-color': 'hsla(0, 0%, 100%, 1)',
        hover: {
          width: '20px',
          height: '20px',
          background: 'hsl(152, 56%, 39%)',
        },
        pressed: {
          width: '20px',
          height: '20px',
          background: 'hsl(152, 56%, 39%)',
          shadow: {
            width: '42px',
            height: '42px',
            'corner-radius': '500px',
            background: 'hsla(139, 100%, 32%, 0.10)',
          },
        },
        focus: {
          'outline-width': '2px',
          'outline-style': 'solid',
          'outline-color': 'hsla(214, 16%, 20%, 0.84)',
          'outline-offset': '2px',
        },
      },
      disabled: {
        track: {
          background: 'hsla(210, 94.1%, 11.1%, 0.075)',
        },
        'active-track': {
          background: 'hsl(207, 11.1%, 85.9%)',
        },
        knob: {
          background: 'hsl(208, 11.7%, 91.1%)',
        },
      },
    },
    spinner: {
      track: {
        opacity: 0.2,
      },
      indicator: {
        animation: {
          name: 'spin',
          duration: '800ms',
          'timing-function': 'linear',
          'iteration-count': 'infinite',
        },
      },
    },
    'status-tracker': {
      step: {
        gap: '12px',
        'path-line': {
          'corner-radius': '500px',
          background: 'hsla(210, 98.8%, 14.4%, 0.055)',
          margin: '4px',
        },
        content: {
          'gap-y': '2px',
          'padding-left': '2px',
          'padding-right': '8px',
          comment: {
            color: 'hsla(206, 97.0%, 4.8%, 0.593)',
          },
        },
        status: {
          current: {
            asset: {
              color: 'hsl(151, 55%, 42%)',
            },
            content: {
              label: {
                color: 'hsla(153, 100%, 22%, 0.87)',
              },
            },
          },
          completed: {
            asset: {
              color: 'hsl(151, 55%, 42%)',
            },
            'path-line': {
              background: 'hsl(151, 55%, 42%)',
            },
            content: {
              label: {
                color: 'hsla(214, 16%, 20%, 1)',
              },
            },
          },
          warning: {
            asset: {
              color: 'hsla(23, 100%, 36.8%, 0.980)',
            },
            content: {
              label: {
                color: 'hsla(23, 100%, 36.8%, 0.980)',
              },
            },
          },
          error: {
            asset: {
              color: 'hsla(358, 99.8%, 38.3%, 0.832)',
            },
            content: {
              label: {
                color: 'hsla(358, 99.8%, 38.3%, 0.832)',
              },
            },
          },
          'on-hold': {
            asset: {
              color: 'hsla(206, 97.0%, 4.8%, 0.593)',
            },
            content: {
              label: {
                color: 'hsla(214, 16%, 20%, 1)',
              },
            },
          },
          next: {
            asset: {
              color: 'hsla(205, 96.5%, 10.0%, 0.244)',
            },
            content: {
              opacity: 0.6,
              label: {
                color: 'hsla(214, 16%, 20%, 1)',
              },
            },
          },
        },
        'is-last': {
          'path-line': {
            margin: '0px',
          },
        },
      },
      'is-horizontal': {
        'gap-y': '12px',
        step: {
          'path-line': {
            height: '2px',
            width: '100%',
          },
        },
      },
      'is-vertical': {
        step: {
          'path-line': {
            height: '100%',
            width: '2px',
          },
          content: {
            'padding-bottom': '32px',
          },
          'is-last': {
            content: {
              'padding-bottom': '0px',
            },
          },
        },
      },
      'screen-size': {
        medium: {
          'is-horizontal': {
            'gap-y': '0',
          },
        },
      },
    },
    switch: {
      'corner-radius': '500px',
      width: '46px',
      height: '26px',
      padding: '3px',
      track: {
        background: 'hsla(214, 16%, 20%, 1)',
        opacity: 0.2,
      },
      knob: {
        'corner-radius': '500px',
        width: '20px',
        height: '20px',
        background: 'hsla(0, 0%, 100%, 1)',
      },
      hover: {
        track: {
          opacity: 0.3,
        },
      },
      focus: {
        'outline-width': '2px',
        'outline-style': 'solid',
        'outline-color': 'hsla(214, 16%, 20%, 0.84)',
        'outline-offset': '2px',
      },
      'is-selected': {
        track: {
          background: 'hsl(151, 55%, 42%)',
          opacity: 1,
        },
        hover: {
          track: {
            background: 'hsl(152, 56%, 39%)',
          },
        },
        focus: {
          background: 'hsl(152, 56%, 39%)',
        },
      },
      'is-disabled': {
        track: {
          background: 'hsla(210, 98.8%, 14.4%, 0.055)',
          opacity: 1,
        },
        knob: {
          background: 'hsla(214, 16%, 20%, 1)',
          opacity: 0.2,
        },
      },
    },
    tabs: {
      'padding-x': '20px',
      'gap-x': '8px',
      'border-bottom-width': '1.5px',
      'border-bottom-color': 'hsla(210, 94.1%, 11.1%, 0.075)',
      item: {
        'min-width': '56px',
        'margin-y': '8px',
        'corner-radius': '8px',
        'padding-y': '8px',
        'padding-x': '12px',
        'text-color': 'hsla(206, 97.0%, 4.8%, 0.593)',
        hover: {
          background: 'hsla(210, 98.8%, 14.4%, 0.055)',
          'text-color': 'hsla(214, 16%, 20%, 1)',
        },
        selected: {
          'border-bottom-width': '3px',
          'border-bottom-color': 'hsla(151, 100%, 28%, 0.81)',
          'text-color': 'hsla(153, 100%, 22%, 0.87)',
        },
        focus: {
          'text-color': 'hsla(153, 100%, 22%, 0.87)',
          'outline-width': '2px',
          'outline-style': 'solid',
          'outline-color': 'hsla(214, 16%, 20%, 0.84)',
          'outline-offset': '2px',
        },
        disabled: {
          'text-color': 'hsla(205, 96.5%, 10.0%, 0.244)',
        },
      },
    },
    tag: {
      'corner-radius': '4px',
      'gap-x': '4px',
      medium: {
        height: '24px',
        'min-width': '24px',
        'padding-y': '4px',
        'padding-x': '8px',
      },
      small: {
        height: '20px',
        'min-width': '20px',
        'padding-y': '2px',
        'padding-x': '4px',
      },
      solid: {
        foreground: 'hsla(0, 0%, 100%, 1)',
      },
      neutral: {
        background: 'hsla(210, 98.8%, 14.4%, 0.055)',
        foreground: 'hsla(214, 16%, 20%, 1)',
        label: {
          color: 'hsla(214, 16%, 20%, 1)',
        },
        solid: {
          background: 'hsla(214, 16%, 20%, 1)',
          label: {
            color: 'hsla(0, 0%, 100%, 1)',
          },
        },
      },
      info: {
        background: 'hsla(224, 100%, 50.1%, 0.059)',
        foreground: 'hsla(226, 99.6%, 31.1%, 0.797)',
        label: {
          color: 'hsla(226, 99.6%, 31.1%, 0.797)',
        },
        solid: {
          background: 'hsl(226, 70.0%, 55.5%)',
          label: {
            color: 'hsla(0, 0%, 100%, 1)',
          },
        },
      },
      negative: {
        background: 'hsla(0, 100%, 50.2%, 0.063)',
        foreground: 'hsla(358, 99.8%, 38.3%, 0.832)',
        label: {
          color: 'hsla(358, 99.8%, 38.3%, 0.832)',
        },
        solid: {
          background: 'hsl(358, 75.0%, 59.0%)',
          label: {
            color: 'hsla(0, 0%, 100%, 1)',
          },
        },
      },
      warning: {
        background: 'hsla(25, 100%, 50.5%, 0.095)',
        foreground: 'hsla(23, 100%, 36.8%, 0.980)',
        label: {
          color: 'hsla(23, 100%, 36.8%, 0.980)',
        },
        solid: {
          background: 'hsl(24, 94.0%, 50.0%)',
          label: {
            color: 'hsla(0, 0%, 100%, 1)',
          },
        },
      },
      positive: {
        background: 'hsla(139, 100%, 32%, 0.10)',
        foreground: 'hsla(153, 100%, 22%, 0.87)',
        label: {
          color: 'hsla(153, 100%, 22%, 0.87)',
        },
        solid: {
          background: 'hsl(151, 55%, 42%)',
          label: {
            color: 'hsla(0, 0%, 100%, 1)',
          },
        },
      },
      brand: {
        background: 'hsla(139, 100%, 32%, 0.10)',
        foreground: 'hsla(153, 100%, 22%, 0.87)',
        label: {
          color: 'hsla(153, 100%, 22%, 0.87)',
        },
        solid: {
          background: 'hsl(151, 55%, 42%)',
          foreground: 'hsl(137, 47%, 97%)',
          label: {
            color: 'hsla(0, 0%, 100%, 1)',
          },
        },
      },
    },
    'text-list': {
      'gap-y': '4px',
      unordered: {
        'margin-left': '6.5px',
        'gap-x': '0px',
        'indicator-area': {
          'min-width': '21px',
        },
        indicator: {
          'min-width': '8px',
        },
      },
      ordered: {
        'margin-left': '4.7px',
        'gap-x': '1px',
        marker: {
          'gap-x': '0.8px',
        },
        'indicator-area': {
          'min-width': '20px',
        },
        indicator: {
          'min-width': '9px',
        },
      },
    },
    text: {
      'font-family': 'Inter',
      small: {
        'font-size': '12px',
        'line-height': '16px',
        'letter-spacing': 0,
        overline: {
          'font-size': '12px',
          'line-height': '16px',
          'font-weight': 600,
          'letter-spacing': '0.08em',
          'text-transform': 'uppercase',
        },
      },
      medium: {
        'font-size': '14px',
        'line-height': '20px',
        'letter-spacing': '-0.006em',
      },
      large: {
        'font-size': '16px',
        'line-height': '24px',
        'letter-spacing': '-0.011em',
      },
      'font-weight': {
        regular: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
      },
      hyperlink: {
        'font-family': 'Inter',
        'font-weight': 600,
        'text-decoration': 'underline',
        color: 'hsla(153, 100%, 22%, 0.87)',
        small: {
          'font-size': '12px',
          'line-height': '16px',
        },
        medium: {
          'font-size': '14px',
          'line-height': '20px',
        },
        large: {
          'font-size': '16px',
          'line-height': '24px',
        },
      },
      heading: {
        'font-family': 'Inter',
        xsmall: {
          'font-size': '16px',
          'line-height': '20px',
          'font-weight': 600,
          'letter-spacing': '-0.011em',
        },
        small: {
          'font-size': '18px',
          'line-height': '20px',
          'font-weight': 600,
          'letter-spacing': '-0.014em',
        },
        medium: {
          'font-size': '20px',
          'line-height': '24px',
          'font-weight': 600,
          'letter-spacing': '-0.017em',
        },
        large: {
          'font-size': '24px',
          'line-height': '28px',
          'font-weight': 600,
          'letter-spacing': '-0.019em',
        },
      },
      display: {
        'font-family': 'Sharon Display',
        small: {
          'font-size': '24px',
          'line-height': '28px',
          'font-weight': 500,
          'letter-spacing': '-0.02em',
        },
        medium: {
          'font-size': '28px',
          'line-height': '32px',
          'font-weight': 500,
          'letter-spacing': '-0.02em',
        },
      },
      'content-color': {
        high: 'hsla(214, 16%, 20%, 1)',
        medium: 'hsla(206, 97.0%, 4.8%, 0.593)',
        low: 'hsla(205, 96.5%, 10.0%, 0.244)',
        brand: 'hsla(153, 100%, 22%, 0.87)',
        positive: 'hsla(153, 100%, 22%, 0.87)',
        negative: 'hsla(358, 99.8%, 38.3%, 0.832)',
        warning: 'hsla(23, 100%, 36.8%, 0.980)',
        info: 'hsla(226, 99.6%, 31.1%, 0.797)',
        'on-color': 'hsla(0, 0%, 100%, 1)',
        'on-color-high': 'hsla(0, 0%, 100%, 1)',
        'on-color-medium': 'hsla(0, 0%, 100%, 0.80)',
        'on-color-low': 'hsla(0, 0%, 100%, 0.50)',
        'on-brand': 'hsl(137, 47%, 97%)',
        'on-brand-high': 'hsl(137, 47%, 97%)',
        'on-brand-medium': 'hsl(142, 44%, 84%)',
        'on-brand-low': 'hsl(146, 40%, 68%)',
      },
    },
    textarea: {
      input: {
        'min-height': '120px',
        'padding-y': '12px',
      },
    },
    toast: {
      margin: '16px',
      'corner-radius': '12px',
      background: 'hsla(214, 16%, 20%, 1)',
      'padding-right': '4px',
      width: '100%',
      'max-width': '412px',
      'min-height': '56px',
      content: {
        'padding-left': '16px',
        'padding-y': '16px',
        'padding-right': '12px',
        gap: '12px',
      },
      action: {
        'padding-y': '8px',
        'padding-x': '12px',
      },
    },
    tooltip: {
      'min-width': '48px',
      'max-width': '200px',
      background: 'hsla(214, 16%, 20%, 1)',
      'padding-y': '4px',
      'padding-x': '8px',
      'corner-radius': '6px',
      offset: '2px',
      pointer: {
        background: 'hsla(214, 16%, 20%, 1)',
        'corner-radius': '2px',
        width: '12px',
        height: '6px',
        left: {
          'margin-left': '12px',
        },
        right: {
          'margin-right': '12px',
        },
      },
    },
  },
  global: {
    border: {
      width: {
        xsmall: '1px',
        small: '2px',
        medium: '3px',
        large: '4px',
      },
    },
    breakpoint: {
      small: '767px',
    },
    'screen-size': {
      small: '@media (max-width: 767px)',
      medium: '@media only screen and (min-width: calc(767px + 1px))',
    },
    color: {
      black: {
        1: 'hsla(0, 0%, 0%, 1)',
        2: 'hsla(0, 0%, 0%, 0.565)',
        3: 'hsla(0, 0%, 0%, 0.478)',
        4: 'hsla(0, 0%, 0%, 0.439)',
        5: 'hsla(0, 0%, 0%, 0.220)',
        6: 'hsla(0, 0%, 0%, 0.141)',
        7: 'hsla(0, 0%, 0%, 0.114)',
        8: 'hsla(0, 0%, 0%, 0.090)',
        9: 'hsla(0, 0%, 0%, 0.071)',
        10: 'hsla(0, 0%, 0%, 0.047)',
        11: 'hsla(0, 0%, 0%, 0.027)',
        12: 'hsla(0, 0%, 0%, 0.012)',
      },
      white: {
        1: 'hsla(0, 0%, 100%, 1)',
        2: 'hsla(0, 0%, 100%, 0.80)',
        3: 'hsla(0, 0%, 100%, 0.50)',
        4: 'hsla(0, 0%, 100%, 0.386)',
        5: 'hsla(0, 0%, 100%, 0.249)',
        6: 'hsla(0, 0%, 100%, 0.176)',
        7: 'hsla(0, 0%, 100%, 0.124)',
        8: 'hsla(0, 0%, 100%, 0.086)',
        9: 'hsla(0, 0%, 100%, 0.056)',
        10: 'hsla(0, 0%, 100%, 0.034)',
        11: 'hsla(0, 0%, 100%, 0.013)',
        12: 'hsla(0, 0%, 100%, 0)',
      },
      slate: {
        1: 'hsla(214, 16%, 20%, 1)',
        2: 'hsl(206, 6.0%, 43.5%)',
        3: 'hsl(206, 5.8%, 52.3%)',
        4: 'hsl(206, 6.0%, 56.1%)',
        5: 'hsl(205, 10.7%, 78.0%)',
        6: 'hsl(207, 11.1%, 85.9%)',
        7: 'hsl(208, 11.3%, 88.9%)',
        8: 'hsl(208, 11.7%, 91.1%)',
        9: 'hsl(209, 12.2%, 93.2%)',
        10: 'hsl(209, 13.3%, 95.3%)',
        11: 'hsl(210, 16.7%, 97.6%)',
        12: 'hsl(206, 30.0%, 98.8%)',
        alpha: {
          1: 'hsla(214, 16%, 20%, 0.84)',
          2: 'hsla(206, 97.0%, 4.8%, 0.593)',
          3: 'hsla(206, 99.6%, 5.4%, 0.506)',
          4: 'hsla(206, 98.8%, 5.9%, 0.467)',
          5: 'hsla(205, 96.5%, 10.0%, 0.244)',
          6: 'hsla(210, 99.1%, 10.1%, 0.157)',
          7: 'hsla(206, 96.4%, 11.3%, 0.126)',
          8: 'hsla(216, 91.1%, 10.9%, 0.099)',
          9: 'hsla(210, 94.1%, 11.1%, 0.075)',
          10: 'hsla(210, 98.8%, 14.4%, 0.055)',
          11: 'hsla(210, 87.7%, 16.0%, 0.028)',
          12: 'hsla(210, 92.6%, 26.5%, 0.016)',
        },
      },
      tomato: {
        1: 'hsl(10, 50.0%, 13.5%)',
        2: 'hsl(10, 82.0%, 43.5%)',
        3: 'hsl(10, 71.5%, 50.0%)',
        4: 'hsl(10, 78.0%, 54.0%)',
        5: 'hsl(10, 71.6%, 71.0%)',
        6: 'hsl(10, 77.3%, 79.5%)',
        7: 'hsl(9, 84.7%, 86.3%)',
        8: 'hsl(8, 92.8%, 91.0%)',
        9: 'hsl(8, 100%, 94.3%)',
        10: 'hsl(8, 100%, 96.6%)',
        11: 'hsl(8, 100%, 98.4%)',
        12: 'hsl(10, 100%, 99.4%)',
        alpha: {
          1: 'hsla(10, 99.0%, 7.4%, 0.934)',
          2: 'hsla(10, 99.9%, 38.8%, 0.922)',
          3: 'hsla(10, 100%, 41.8%, 0.859)',
          4: 'hsla(10, 99.9%, 43.8%, 0.820)',
          5: 'hsla(10, 99.5%, 41.8%, 0.499)',
          6: 'hsla(10, 99.8%, 43.6%, 0.365)',
          7: 'hsla(9, 99.9%, 46.2%, 0.255)',
          8: 'hsla(7, 99.5%, 47.9%, 0.173)',
          9: 'hsla(8, 100%, 50.1%, 0.114)',
          10: 'hsla(7, 100%, 50.2%, 0.067)',
          11: 'hsla(8, 100%, 51.0%, 0.032)',
          12: 'hsla(0, 100%, 51.0%, 0.012)',
        },
      },
      red: {
        1: 'hsl(354, 50.0%, 14.6%)',
        2: 'hsl(358, 65.0%, 48.7%)',
        3: 'hsl(358, 69.4%, 55.2%)',
        4: 'hsl(358, 75.0%, 59.0%)',
        5: 'hsl(359, 69.5%, 74.3%)',
        6: 'hsl(359, 74.2%, 81.7%)',
        7: 'hsl(360, 81.7%, 87.8%)',
        8: 'hsl(360, 90.2%, 91.9%)',
        9: 'hsl(360, 97.9%, 94.8%)',
        10: 'hsl(360, 100%, 96.8%)',
        11: 'hsl(359, 100%, 98.6%)',
        12: 'hsl(359, 100%, 99.4%)',
        alpha: {
          1: 'hsla(355, 99.3%, 7.9%, 0.926)',
          2: 'hsla(358, 99.8%, 38.3%, 0.832)',
          3: 'hsla(358, 99.9%, 41.0%, 0.761)',
          4: 'hsla(358, 99.9%, 42.9%, 0.718)',
          5: 'hsla(359, 99.6%, 41.1%, 0.436)',
          6: 'hsla(359, 99.7%, 42.7%, 0.318)',
          7: 'hsla(0, 99.5%, 44.9%, 0.224)',
          8: 'hsla(0, 99.9%, 47.5%, 0.153)',
          9: 'hsla(0, 100%, 50.0%, 0.102)',
          10: 'hsla(0, 100%, 50.2%, 0.063)',
          11: 'hsla(0, 100%, 51.0%, 0.032)',
          12: 'hsla(0, 100%, 51.0%, 0.012)',
        },
      },
      crimson: {
        1: 'hsl(340, 65.0%, 14.5%)',
        2: 'hsl(336, 75.0%, 47.2%)',
        3: 'hsl(336, 73.7%, 53.5%)',
        4: 'hsl(336, 80.0%, 57.8%)',
        5: 'hsl(336, 62.3%, 72.9%)',
        6: 'hsl(335, 63.5%, 80.4%)',
        7: 'hsl(333, 67.0%, 86.7%)',
        8: 'hsl(332, 72.1%, 91.1%)',
        9: 'hsl(331, 78.1%, 94.2%)',
        10: 'hsl(331, 85.6%, 96.6%)',
        11: 'hsl(330, 100%, 98.4%)',
        12: 'hsl(332, 100%, 99.4%)',
        alpha: {
          1: 'hsla(340, 99.0%, 10.0%, 0.950)',
          2: 'hsla(336, 99.8%, 40.3%, 0.883)',
          3: 'hsla(336, 100%, 42.5%, 0.808)',
          4: 'hsla(336, 99.9%, 44.3%, 0.761)',
          5: 'hsla(336, 99.5%, 38.5%, 0.440)',
          6: 'hsla(335, 99.7%, 39.1%, 0.322)',
          7: 'hsla(333, 99.5%, 40.5%, 0.224)',
          8: 'hsla(333, 99.9%, 42.3%, 0.153)',
          9: 'hsla(331, 99.9%, 44.3%, 0.102)',
          10: 'hsla(332, 99.1%, 47.1%, 0.063)',
          11: 'hsla(330, 100%, 51.0%, 0.032)',
          12: 'hsla(340, 100%, 51.0%, 0.012)',
        },
      },
      pink: {
        1: 'hsl(320, 70.0%, 13.5%)',
        2: 'hsl(322, 75.0%, 46.0%)',
        3: 'hsl(322, 63.9%, 50.7%)',
        4: 'hsl(322, 65.0%, 54.5%)',
        5: 'hsl(323, 60.3%, 72.4%)',
        6: 'hsl(323, 62.0%, 80.1%)',
        7: 'hsl(323, 66.3%, 86.6%)',
        8: 'hsl(323, 72.2%, 91.1%)',
        9: 'hsl(323, 78.7%, 94.2%)',
        10: 'hsl(323, 86.3%, 96.5%)',
        11: 'hsl(323, 100%, 98.4%)',
        12: 'hsl(322, 100%, 99.4%)',
        alpha: {
          1: 'hsla(321, 99.8%, 10.0%, 0.961)',
          2: 'hsla(322, 99.8%, 39.0%, 0.887)',
          3: 'hsla(322, 100%, 39.1%, 0.808)',
          4: 'hsla(322, 99.7%, 39.3%, 0.750)',
          5: 'hsla(323, 99.5%, 37.7%, 0.444)',
          6: 'hsla(323, 99.7%, 38.5%, 0.322)',
          7: 'hsla(323, 99.5%, 39.6%, 0.224)',
          8: 'hsla(324, 99.9%, 42.3%, 0.153)',
          9: 'hsla(323, 99.9%, 44.3%, 0.102)',
          10: 'hsla(323, 98.9%, 47.3%, 0.067)',
          11: 'hsla(323, 100%, 51.0%, 0.032)',
          12: 'hsla(320, 100%, 51.0%, 0.012)',
        },
      },
      plum: {
        1: 'hsl(291, 66.0%, 14.0%)',
        2: 'hsl(292, 60.0%, 42.5%)',
        3: 'hsl(292, 50.2%, 46.9%)',
        4: 'hsl(292, 45.0%, 51.0%)',
        5: 'hsl(292, 47.7%, 70.8%)',
        6: 'hsl(295, 48.2%, 78.9%)',
        7: 'hsl(296, 51.3%, 85.8%)',
        8: 'hsl(298, 56.1%, 90.5%)',
        9: 'hsl(299, 62.0%, 93.8%)',
        10: 'hsl(299, 71.2%, 96.4%)',
        11: 'hsl(300, 100%, 98.6%)',
        12: 'hsl(292, 90.0%, 99.4%)',
        alpha: {
          1: 'hsla(291, 99.9%, 9.7%, 0.953)',
          2: 'hsla(292, 99.8%, 30.7%, 0.832)',
          3: 'hsla(292, 99.9%, 30.8%, 0.765)',
          4: 'hsla(292, 99.9%, 31.0%, 0.710)',
          5: 'hsla(292, 99.6%, 32.4%, 0.432)',
          6: 'hsla(295, 99.7%, 32.6%, 0.314)',
          7: 'hsla(297, 99.6%, 33.7%, 0.216)',
          8: 'hsla(298, 98.2%, 35.9%, 0.150)',
          9: 'hsla(300, 99.9%, 38.5%, 0.102)',
          10: 'hsla(300, 99.0%, 40.9%, 0.063)',
          11: 'hsla(300, 100%, 51.0%, 0.028)',
          12: 'hsla(280, 100%, 51.0%, 0.012)',
        },
      },
      purple: {
        1: 'hsl(272, 66.0%, 16.0%)',
        2: 'hsl(272, 50.0%, 45.8%)',
        3: 'hsl(272, 46.8%, 50.3%)',
        4: 'hsl(272, 51.0%, 54.0%)',
        5: 'hsl(272, 60.0%, 73.5%)',
        6: 'hsl(273, 61.0%, 81.7%)',
        7: 'hsl(274, 65.4%, 87.8%)',
        8: 'hsl(275, 70.8%, 91.8%)',
        9: 'hsl(275, 76.4%, 94.7%)',
        10: 'hsl(276, 83.1%, 97.0%)',
        11: 'hsl(276, 100%, 99.0%)',
        12: 'hsl(280, 65.0%, 99.4%)',
        alpha: {
          1: 'hsla(272, 99.2%, 11.3%, 0.946)',
          2: 'hsla(272, 99.8%, 29.7%, 0.773)',
          3: 'hsla(272, 99.7%, 32.0%, 0.730)',
          4: 'hsla(272, 99.6%, 34.0%, 0.695)',
          5: 'hsla(272, 99.7%, 37.6%, 0.424)',
          6: 'hsla(273, 99.2%, 38.2%, 0.295)',
          7: 'hsla(275, 100%, 39.2%, 0.200)',
          8: 'hsla(276, 98.6%, 42.0%, 0.142)',
          9: 'hsla(274, 97.9%, 44.3%, 0.095)',
          10: 'hsla(277, 99.6%, 46.5%, 0.055)',
          11: 'hsla(276, 100%, 51.0%, 0.020)',
          12: 'hsla(300, 94.3%, 34.6%, 0.012)',
        },
      },
      violet: {
        1: 'hsl(254, 60.0%, 18.5%)',
        2: 'hsl(250, 43.0%, 48.0%)',
        3: 'hsl(251, 48.1%, 53.5%)',
        4: 'hsl(252, 56.0%, 57.5%)',
        5: 'hsl(252, 68.6%, 76.3%)',
        6: 'hsl(252, 71.0%, 83.7%)',
        7: 'hsl(252, 77.8%, 89.4%)',
        8: 'hsl(252, 85.1%, 93.0%)',
        9: 'hsl(252, 91.5%, 95.5%)',
        10: 'hsl(252, 96.9%, 97.4%)',
        11: 'hsl(252, 100%, 99.0%)',
        12: 'hsl(255, 65.0%, 99.4%)',
        alpha: {
          1: 'hsla(254, 99.5%, 11.9%, 0.926)',
          2: 'hsla(250, 99.8%, 28.4%, 0.726)',
          3: 'hsla(251, 99.6%, 32.5%, 0.691)',
          4: 'hsla(252, 99.9%, 35.8%, 0.663)',
          5: 'hsla(252, 100%, 40.7%, 0.400)',
          6: 'hsla(252, 99.5%, 41.7%, 0.279)',
          7: 'hsla(251, 99.1%, 44.0%, 0.189)',
          8: 'hsla(252, 99.0%, 45.7%, 0.130)',
          9: 'hsla(251, 98.3%, 48.2%, 0.087)',
          10: 'hsla(254, 100%, 50.0%, 0.051)',
          11: 'hsla(252, 100%, 51.0%, 0.020)',
          12: 'hsla(270, 94.3%, 34.6%, 0.012)',
        },
      },
      indigo: {
        1: 'hsl(226, 62.0%, 17.0%)',
        2: 'hsl(226, 55.0%, 45.0%)',
        3: 'hsl(226, 58.6%, 51.3%)',
        4: 'hsl(226, 70.0%, 55.5%)',
        5: 'hsl(226, 75.4%, 74.5%)',
        6: 'hsl(225, 77.4%, 82.1%)',
        7: 'hsl(224, 81.9%, 87.8%)',
        8: 'hsl(224, 87.1%, 92.0%)',
        9: 'hsl(223, 92.9%, 95.0%)',
        10: 'hsl(223, 98.4%, 97.1%)',
        11: 'hsl(223, 100%, 98.6%)',
        12: 'hsl(225, 60.0%, 99.4%)',
        alpha: {
          1: 'hsla(226, 99.3%, 11.4%, 0.938)',
          2: 'hsla(226, 99.6%, 31.1%, 0.797)',
          3: 'hsla(226, 99.8%, 37.1%, 0.773)',
          4: 'hsla(226, 100%, 41.2%, 0.757)',
          5: 'hsla(226, 99.5%, 43.1%, 0.448)',
          6: 'hsla(225, 99.7%, 43.9%, 0.318)',
          7: 'hsla(224, 99.5%, 44.9%, 0.224)',
          8: 'hsla(225, 98.6%, 46.4%, 0.150)',
          9: 'hsla(223, 98.0%, 48.5%, 0.099)',
          10: 'hsla(224, 100%, 50.1%, 0.059)',
          11: 'hsla(223, 100%, 51.0%, 0.028)',
          12: 'hsla(240, 92.6%, 26.5%, 0.008)',
        },
      },
      blue: {
        1: 'hsl(211, 100%, 15.0%)',
        2: 'hsl(211, 100%, 43.2%)',
        3: 'hsl(208, 100%, 47.3%)',
        4: 'hsl(206, 100%, 50.0%)',
        5: 'hsl(206, 81.9%, 65.3%)',
        6: 'hsl(208, 77.5%, 76.9%)',
        7: 'hsl(209, 81.2%, 84.5%)',
        8: 'hsl(209, 95.0%, 90.1%)',
        9: 'hsl(210, 98.8%, 94.0%)',
        10: 'hsl(209, 100%, 96.5%)',
        11: 'hsl(210, 100%, 98.0%)',
        12: 'hsl(206, 100%, 99.2%)',
        alpha: {
          1: 'hsla(213, 100%, 14.4%, 0.980)',
          2: 'hsla(212, 100%, 43.0%, 0.980)',
          3: 'hsla(208, 100%, 47.2%, 0.980)',
          4: 'hsla(206, 100%, 50.0%, 0.980)',
          5: 'hsla(206, 99.8%, 45.1%, 0.632)',
          6: 'hsla(208, 99.9%, 43.8%, 0.412)',
          7: 'hsla(209, 99.5%, 45.3%, 0.283)',
          8: 'hsla(208, 99.1%, 47.1%, 0.189)',
          9: 'hsla(210, 100%, 50.1%, 0.118)',
          10: 'hsla(210, 100%, 50.3%, 0.071)',
          11: 'hsla(210, 100%, 51.0%, 0.040)',
          12: 'hsla(210, 100%, 51.0%, 0.016)',
        },
      },
      cyan: {
        1: 'hsl(192, 88.0%, 12.5%)',
        2: 'hsl(192, 85.0%, 31.0%)',
        3: 'hsl(191, 91.2%, 36.8%)',
        4: 'hsl(190, 95.0%, 39.0%)',
        5: 'hsl(189, 60.3%, 52.5%)',
        6: 'hsl(189, 53.7%, 68.7%)',
        7: 'hsl(188, 54.6%, 78.4%)',
        8: 'hsl(187, 58.3%, 85.4%)',
        9: 'hsl(186, 63.8%, 90.6%)',
        10: 'hsl(186, 70.2%, 94.4%)',
        11: 'hsl(185, 73.3%, 97.1%)',
        12: 'hsl(185, 60.0%, 98.7%)',
        alpha: {
          1: 'hsla(192, 100%, 11.0%, 0.980)',
          2: 'hsla(192, 100%, 27.6%, 0.953)',
          3: 'hsla(191, 99.9%, 34.6%, 0.969)',
          4: 'hsla(190, 100%, 37.8%, 0.980)',
          5: 'hsla(189, 99.9%, 37.6%, 0.761)',
          6: 'hsla(189, 99.6%, 35.0%, 0.483)',
          7: 'hsla(188, 99.4%, 35.4%, 0.334)',
          8: 'hsla(187, 99.3%, 36.6%, 0.232)',
          9: 'hsla(186, 99.9%, 38.5%, 0.153)',
          10: 'hsla(186, 97.8%, 42.2%, 0.095)',
          11: 'hsla(185, 99.9%, 42.3%, 0.051)',
          12: 'hsla(195, 95.2%, 41.2%, 0.020)',
        },
      },
      teal: {
        1: 'hsl(170, 50.0%, 12.5%)',
        2: 'hsl(174, 90.0%, 25.2%)',
        3: 'hsl(173, 83.4%, 32.5%)',
        4: 'hsl(173, 80.0%, 36.0%)',
        5: 'hsl(172, 42.1%, 52.5%)',
        6: 'hsl(170, 39.9%, 68.1%)',
        7: 'hsl(170, 42.6%, 77.9%)',
        8: 'hsl(170, 47.1%, 85.0%)',
        9: 'hsl(169, 53.1%, 90.2%)',
        10: 'hsl(169, 59.8%, 94.0%)',
        11: 'hsl(169, 64.7%, 96.7%)',
        12: 'hsl(165, 60.0%, 98.8%)',
        alpha: {
          1: 'hsla(171, 98.8%, 6.8%, 0.938)',
          2: 'hsla(174, 99.8%, 23.3%, 0.977)',
          3: 'hsla(173, 99.7%, 28.7%, 0.946)',
          4: 'hsla(173, 99.8%, 31.1%, 0.930)',
          5: 'hsla(172, 99.8%, 29.7%, 0.675)',
          6: 'hsla(170, 99.3%, 28.7%, 0.448)',
          7: 'hsla(170, 99.7%, 30.1%, 0.314)',
          8: 'hsla(170, 99.4%, 32.3%, 0.220)',
          9: 'hsla(168, 98.1%, 34.6%, 0.150)',
          10: 'hsla(167, 97.6%, 38.1%, 0.095)',
          11: 'hsla(169, 99.5%, 39.4%, 0.055)',
          12: 'hsla(165, 95.2%, 41.2%, 0.020)',
        },
      },
      green: {
        1: 'hsl(155, 40%, 16%)',
        2: 'hsl(154, 60%, 32%)',
        3: 'hsl(152, 56%, 39%)',
        4: 'hsl(151, 55%, 42%)',
        5: 'hsl(151, 40%, 54%)',
        6: 'hsl(146, 40%, 68%)',
        7: 'hsl(144, 41%, 77%)',
        8: 'hsl(142, 44%, 84%)',
        9: 'hsl(140, 49%, 89%)',
        10: 'hsl(139, 47%, 93%)',
        11: 'hsl(137, 47%, 97%)',
        12: 'hsl(140, 60%, 99%)',
        alpha: {
          1: 'hsla(155, 100%, 7%, 0.90)',
          2: 'hsla(153, 100%, 22%, 0.87)',
          3: 'hsla(152, 100%, 26%, 0.83)',
          4: 'hsla(151, 100%, 28%, 0.81)',
          5: 'hsla(151, 100%, 29%, 0.64)',
          6: 'hsla(146, 100%, 28%, 0.44)',
          7: 'hsla(144, 100%, 29%, 0.32)',
          8: 'hsla(142, 99%, 31%, 0.23)',
          9: 'hsla(140, 100%, 33%, 0.16)',
          10: 'hsla(139, 100%, 32%, 0.10)',
          11: 'hsla(137, 100%, 32%, 0.04)',
          12: 'hsla(140, 100%, 38%, 0.02)',
        },
      },
      grass: {
        1: 'hsl(130, 30.0%, 14.9%)',
        2: 'hsl(133, 50.0%, 32.5%)',
        3: 'hsl(132, 43.1%, 42.2%)',
        4: 'hsl(131, 41.0%, 46.5%)',
        5: 'hsl(131, 38.1%, 56.3%)',
        6: 'hsl(126, 37.1%, 70.2%)',
        7: 'hsl(124, 39.0%, 79.7%)',
        8: 'hsl(122, 42.6%, 86.5%)',
        9: 'hsl(121, 47.5%, 91.4%)',
        10: 'hsl(120, 53.6%, 94.8%)',
        11: 'hsl(120, 60.0%, 97.1%)',
        12: 'hsl(116, 50.0%, 98.9%)',
        alpha: {
          1: 'hsla(128, 98.0%, 4.9%, 0.895)',
          2: 'hsla(133, 99.5%, 19.5%, 0.840)',
          3: 'hsla(132, 99.9%, 24.0%, 0.761)',
          4: 'hsla(131, 99.7%, 26.3%, 0.726)',
          5: 'hsla(131, 100%, 27.6%, 0.604)',
          6: 'hsla(125, 99.9%, 27.0%, 0.408)',
          7: 'hsla(125, 99.2%, 27.9%, 0.283)',
          8: 'hsla(122, 98.5%, 29.9%, 0.193)',
          9: 'hsla(120, 98.7%, 31.5%, 0.126)',
          10: 'hsla(120, 98.0%, 35.5%, 0.079)',
          11: 'hsla(120, 94.9%, 38.7%, 0.048)',
          12: 'hsla(120, 94.9%, 38.7%, 0.016)',
        },
      },
      lime: {
        1: 'hsl(83, 50%, 12.5%)',
        2: 'hsl(83, 90.6%, 25.1%)',
        3: 'hsl(83, 83.1%, 32.5%)',
        4: 'hsl(83, 80.3%, 35.9%)',
        5: 'hsl(83, 42.1%, 52.5%)',
        6: 'hsl(83, 39.9%, 68%)',
        7: 'hsl(83, 42.9%, 78%)',
        8: 'hsl(83, 47.4%, 85.1%)',
        9: 'hsl(83, 52%, 90.2%)',
        10: 'hsl(83, 60%, 94.1%)',
        11: 'hsl(82, 64.7%, 96.7%)',
        12: 'hsl(90, 66.7%, 98.8%)',
        alpha: {
          1: 'hsla(83, 100%, 6.7%, 0.938)',
          2: 'hsla(83, 100%, 23.3%, 0.976)',
          3: 'hsla(83, 100%, 28.6%, 0.945)',
          4: 'hsla(83, 100%, 31%, 0.93)',
          5: 'hsla(83, 100%, 29.6%, 0.675)',
          6: 'hsla(83, 98.6%, 28.8%, 0.448)',
          7: 'hsla(83, 100%, 30%, 0.31)',
          8: 'hsla(83, 100%, 32.2%, 0.22)',
          9: 'hsla(83, 97.7%, 34.7%, 0.150)',
          10: 'hsla(83, 97.9%, 38%, 0.095)',
          11: 'hsla(83, 99%, 39.4%, 0.055)',
          12: 'hsla(83, 95.2%, 41.2%, 0.019)',
        },
      },
      orange: {
        1: 'hsl(15, 60.0%, 17.0%)',
        2: 'hsl(24, 100%, 37.0%)',
        3: 'hsl(24, 100%, 46.5%)',
        4: 'hsl(24, 94.0%, 50.0%)',
        5: 'hsl(24, 94.5%, 64.3%)',
        6: 'hsl(24, 100%, 75.3%)',
        7: 'hsl(25, 100%, 82.8%)',
        8: 'hsl(25, 100%, 88.2%)',
        9: 'hsl(25, 100%, 92.2%)',
        10: 'hsl(24, 100%, 95.3%)',
        11: 'hsl(24, 83.3%, 97.6%)',
        12: 'hsl(24, 70.0%, 99.0%)',
        alpha: {
          1: 'hsla(15, 99.4%, 11.0%, 0.934)',
          2: 'hsla(23, 100%, 36.8%, 0.980)',
          3: 'hsla(23, 100%, 46.4%, 0.980)',
          4: 'hsla(24, 99.9%, 48.4%, 0.969)',
          5: 'hsla(24, 99.7%, 48.7%, 0.695)',
          6: 'hsla(24, 100%, 50.1%, 0.495)',
          7: 'hsla(25, 100%, 50.1%, 0.346)',
          8: 'hsla(25, 100%, 50.1%, 0.236)',
          9: 'hsla(26, 100%, 50.0%, 0.157)',
          10: 'hsla(25, 100%, 50.5%, 0.095)',
          11: 'hsla(24, 95.8%, 46.5%, 0.044)',
          12: 'hsla(20, 94.9%, 38.7%, 0.016)',
        },
      },
      brown: {
        1: 'hsl(20, 30.0%, 19.0%)',
        2: 'hsl(25, 30.0%, 41.0%)',
        3: 'hsl(27, 31.8%, 47.6%)',
        4: 'hsl(28, 34.0%, 51.0%)',
        5: 'hsl(28, 50.0%, 63.1%)',
        6: 'hsl(29, 51.5%, 72.8%)',
        7: 'hsl(29, 52.5%, 80.9%)',
        8: 'hsl(29, 52.9%, 86.8%)',
        9: 'hsl(30, 53.0%, 91.2%)',
        10: 'hsl(30, 52.5%, 94.6%)',
        11: 'hsl(30, 50.0%, 97.6%)',
        12: 'hsl(30, 40.0%, 99.1%)',
        alpha: {
          1: 'hsla(21, 99.4%, 6.6%, 0.867)',
          2: 'hsla(25, 99.8%, 17.3%, 0.714)',
          3: 'hsla(27, 99.7%, 22.4%, 0.675)',
          4: 'hsla(28, 99.9%, 25.5%, 0.655)',
          5: 'hsla(28, 100%, 33.3%, 0.553)',
          6: 'hsla(29, 99.8%, 33.8%, 0.412)',
          7: 'hsla(28, 99.2%, 34.6%, 0.291)',
          8: 'hsla(29, 100%, 34.3%, 0.200)',
          9: 'hsla(31, 98.5%, 34.2%, 0.134)',
          10: 'hsla(30, 97.7%, 33.9%, 0.083)',
          11: 'hsla(30, 94.3%, 34.6%, 0.036)',
          12: 'hsla(30, 94.3%, 34.6%, 0.012)',
        },
      },
      sky: {
        1: 'hsl(195, 100%, 13.0%)',
        2: 'hsl(195, 100%, 31.5%)',
        3: 'hsl(193, 87.0%, 66.5%)',
        4: 'hsl(193, 98.0%, 70.0%)',
        5: 'hsl(193, 77.9%, 53.9%)',
        6: 'hsl(194, 72.3%, 69.6%)',
        7: 'hsl(194, 74.1%, 79.5%)',
        8: 'hsl(194, 82.0%, 86.6%)',
        9: 'hsl(193, 91.4%, 91.4%)',
        10: 'hsl(193, 99.0%, 94.7%)',
        11: 'hsl(193, 100%, 97.3%)',
        12: 'hsl(193, 100%, 98.8%)',
        alpha: {
          1: 'hsla(196, 100%, 12.2%, 0.980)',
          2: 'hsla(196, 100%, 31.2%, 0.980)',
          3: 'hsla(193, 99.8%, 46.6%, 0.628)',
          4: 'hsla(193, 99.7%, 49.4%, 0.593)',
          5: 'hsla(193, 99.9%, 43.8%, 0.820)',
          6: 'hsla(194, 99.8%, 42.2%, 0.526)',
          7: 'hsla(194, 99.9%, 42.3%, 0.357)',
          8: 'hsla(194, 99.2%, 45.4%, 0.244)',
          9: 'hsla(194, 99.6%, 47.7%, 0.165)',
          10: 'hsla(193, 100%, 50.1%, 0.106)',
          11: 'hsla(193, 100%, 50.1%, 0.055)',
          12: 'hsla(190, 100%, 51.0%, 0.024)',
        },
      },
      mint: {
        1: 'hsl(172, 70.0%, 12.0%)',
        2: 'hsl(172, 72.0%, 28.5%)',
        3: 'hsl(167, 59.3%, 63.1%)',
        4: 'hsl(167, 65.0%, 66.0%)',
        5: 'hsl(168, 52.8%, 51.0%)',
        6: 'hsl(166, 50.7%, 66.1%)',
        7: 'hsl(165, 53.5%, 76.9%)',
        8: 'hsl(165, 60.6%, 84.5%)',
        9: 'hsl(165, 68.8%, 89.5%)',
        10: 'hsl(164, 76.6%, 93.3%)',
        11: 'hsl(164, 88.2%, 96.7%)',
        12: 'hsl(165, 80.0%, 98.8%)',
        alpha: {
          1: 'hsla(172, 99.7%, 8.8%, 0.965)',
          2: 'hsla(172, 99.8%, 22.4%, 0.922)',
          3: 'hsla(167, 99.7%, 37.4%, 0.589)',
          4: 'hsla(167, 99.9%, 39.5%, 0.561)',
          5: 'hsla(168, 99.6%, 34.6%, 0.750)',
          6: 'hsla(166, 99.9%, 33.5%, 0.510)',
          7: 'hsla(165, 100%, 35.0%, 0.353)',
          8: 'hsla(165, 99.0%, 37.5%, 0.248)',
          9: 'hsla(164, 99.3%, 41.3%, 0.177)',
          10: 'hsla(164, 99.3%, 43.5%, 0.118)',
          11: 'hsla(164, 99.1%, 47.1%, 0.063)',
          12: 'hsla(168, 95.4%, 42.8%, 0.024)',
        },
      },
      ton: {
        1: 'hsla(126, 100%, 11%, 1)',
        2: 'hsla(133, 98%, 25%, 1)',
        3: 'hsla(127, 96%, 59%, 1)',
        4: 'hsla(124, 100%, 67%, 1)',
        5: 'hsla(133, 100%, 46%, 1)',
        6: 'hsla(126, 99%, 61%, 1)',
        7: 'hsla(119, 100%, 82%, 1)',
        8: 'hsla(118, 100%, 90%, 1)',
        9: 'hsla(118, 100%, 94%, 1)',
        10: 'hsla(117, 100%, 96%, 1)',
        11: 'hsla(120, 100%, 98%, 1)',
        12: 'hsla(120, 100%, 99%, 1)',
        alpha: {
          1: 'hsla(126, 100%, 11%, 1)',
          2: 'hsla(131, 100%, 27%, 1)',
          3: 'hsla(127, 99%, 49%, 0.81)',
          4: 'hsla(124, 100%, 51%, 0.67)',
          5: 'hsla(133, 100%, 46%, 1)',
          6: 'hsla(126, 99%, 50%, 0.79)',
          7: 'hsla(119, 100%, 50%, 0.36)',
          8: 'hsla(118, 100%, 50%, 0.2)',
          9: 'hsla(118, 100%, 53%, 0.13)',
          10: 'hsla(117, 100%, 51%, 0.08)',
          11: 'hsla(120, 100%, 53%, 0.05)',
          12: 'hsla(120, 100%, 54%, 0.03)',
        },
      },
      yellow: {
        1: 'hsl(40, 55.0%, 13.5%)',
        2: 'hsl(42, 100%, 29.0%)',
        3: 'hsl(50, 100%, 48.5%)',
        4: 'hsl(53, 92.0%, 50.0%)',
        5: 'hsl(48, 100%, 46.1%)',
        6: 'hsl(47, 80.4%, 68.0%)',
        7: 'hsl(50, 89.4%, 76.1%)',
        8: 'hsl(52, 97.9%, 82.0%)',
        9: 'hsl(54, 100%, 86.6%)',
        10: 'hsl(55, 100%, 90.9%)',
        11: 'hsl(52, 100%, 95.5%)',
        12: 'hsl(60, 54.0%, 98.5%)',
        alpha: {
          1: 'hsla(41, 98.9%, 8.0%, 0.942)',
          2: 'hsla(42, 100%, 28.6%, 0.980)',
          3: 'hsla(50, 100%, 48.4%, 0.980)',
          4: 'hsla(53, 100%, 48.0%, 0.961)',
          5: 'hsla(48, 100%, 46.0%, 0.980)',
          6: 'hsla(47, 99.8%, 44.6%, 0.577)',
          7: 'hsla(50, 100%, 47.4%, 0.451)',
          8: 'hsla(52, 99.9%, 49.5%, 0.357)',
          9: 'hsla(54, 100%, 50.1%, 0.267)',
          10: 'hsla(55, 100%, 50.2%, 0.181)',
          11: 'hsla(52, 100%, 50.4%, 0.091)',
          12: 'hsla(60, 94.3%, 34.6%, 0.024)',
        },
      },
      amber: {
        1: 'hsl(20, 80.0%, 17.0%)',
        2: 'hsl(30, 100%, 34.0%)',
        3: 'hsl(35, 100%, 55.5%)',
        4: 'hsl(39, 100%, 57.0%)',
        5: 'hsl(35, 85.2%, 55.1%)',
        6: 'hsl(36, 86.1%, 67.1%)',
        7: 'hsl(38, 99.7%, 76.3%)',
        8: 'hsl(42, 100%, 81.8%)',
        9: 'hsl(43, 100%, 86.8%)',
        10: 'hsl(44, 100%, 91.7%)',
        11: 'hsl(40, 100%, 96.5%)',
        12: 'hsl(39, 70.0%, 99.0%)',
        alpha: {
          1: 'hsla(20, 99.8%, 14.1%, 0.965)',
          2: 'hsla(29, 100%, 33.6%, 0.980)',
          3: 'hsla(35, 100%, 50.0%, 0.891)',
          4: 'hsla(39, 100%, 50.0%, 0.859)',
          5: 'hsla(35, 99.8%, 46.0%, 0.832)',
          6: 'hsla(36, 99.9%, 46.2%, 0.612)',
          7: 'hsla(38, 100%, 50.1%, 0.475)',
          8: 'hsla(42, 100%, 50.0%, 0.365)',
          9: 'hsla(43, 100%, 50.0%, 0.263)',
          10: 'hsla(44, 100%, 50.1%, 0.165)',
          11: 'hsla(40, 100%, 50.3%, 0.071)',
          12: 'hsla(40, 94.9%, 38.7%, 0.016)',
        },
      },
      gold: {
        1: 'hsl(36, 16.0%, 20.0%)',
        2: 'hsl(36, 20.0%, 39.0%)',
        3: 'hsl(36, 19.8%, 45.7%)',
        4: 'hsl(36, 20.0%, 49.5%)',
        5: 'hsl(36, 27.2%, 61.8%)',
        6: 'hsl(39, 27.6%, 71.9%)',
        7: 'hsl(41, 28.3%, 79.8%)',
        8: 'hsl(43, 29.9%, 85.7%)',
        9: 'hsl(44, 32.7%, 90.1%)',
        10: 'hsl(46, 38.2%, 93.7%)',
        11: 'hsl(47, 52.9%, 96.7%)',
        12: 'hsl(50, 20.0%, 99.1%)',
        alpha: {
          1: 'hsla(38, 98.0%, 3.8%, 0.832)',
          2: 'hsla(35, 99.1%, 11.2%, 0.687)',
          3: 'hsla(36, 99.2%, 14.6%, 0.636)',
          4: 'hsla(36, 99.9%, 16.2%, 0.604)',
          5: 'hsla(36, 99.3%, 21.5%, 0.487)',
          6: 'hsla(38, 99.8%, 21.5%, 0.357)',
          7: 'hsla(41, 99.7%, 22.0%, 0.259)',
          8: 'hsla(43, 98.4%, 22.6%, 0.185)',
          9: 'hsla(46, 98.0%, 25.4%, 0.134)',
          10: 'hsla(45, 97.0%, 27.9%, 0.087)',
          11: 'hsla(47, 99.9%, 34.6%, 0.051)',
          12: 'hsla(60, 89.3%, 18.3%, 0.012)',
        },
      },
      bronze: {
        1: 'hsl(12, 22.0%, 21.5%)',
        2: 'hsl(15, 20.0%, 43.1%)',
        3: 'hsl(17, 18.1%, 50.1%)',
        4: 'hsl(17, 20.0%, 54.0%)',
        5: 'hsl(17, 25.1%, 66.5%)',
        6: 'hsl(17, 26.9%, 75.6%)',
        7: 'hsl(17, 29.0%, 83.0%)',
        8: 'hsl(17, 31.5%, 88.2%)',
        9: 'hsl(17, 35.2%, 92.1%)',
        10: 'hsl(17, 42.1%, 95.2%)',
        11: 'hsl(17, 63.6%, 97.8%)',
        12: 'hsl(15, 30.0%, 99.1%)',
        alpha: {
          1: 'hsla(12, 98.7%, 5.7%, 0.832)',
          2: 'hsla(15, 99.9%, 13.2%, 0.655)',
          3: 'hsla(17, 99.2%, 15.4%, 0.589)',
          4: 'hsla(18, 99.9%, 16.7%, 0.553)',
          5: 'hsla(17, 99.5%, 20.2%, 0.420)',
          6: 'hsla(18, 99.7%, 21.6%, 0.310)',
          7: 'hsla(17, 99.2%, 22.5%, 0.220)',
          8: 'hsla(19, 99.6%, 23.8%, 0.157)',
          9: 'hsla(17, 99.6%, 26.0%, 0.106)',
          10: 'hsla(18, 98.3%, 29.8%, 0.067)',
          11: 'hsla(17, 95.1%, 40.1%, 0.036)',
          12: 'hsla(0, 89.3%, 18.3%, 0.012)',
        },
      },
    },
    'corner-radius': {
      xsmall: '4px',
      small: '8px',
      medium: '12px',
      large: '16px',
      xlarge: '20px',
      pill: '500px',
    },
    opacity: {
      0: 0,
      5: 0.05,
      10: 0.1,
      20: 0.2,
      30: 0.3,
      40: 0.4,
      50: 0.5,
      60: 0.6,
      70: 0.7,
      80: 0.8,
      90: 0.9,
      100: 1,
    },
    shadow: {
      small: {
        color: 'hsla(210, 94.1%, 11.1%, 0.075)',
        offsetX: '0',
        offsetY: '1px',
        blur: '1px',
      },
      medium: {
        color: 'hsla(210, 94.1%, 11.1%, 0.075)',
        offsetX: '0',
        offsetY: '5px',
        blur: '20px',
      },
      large: {
        color: 'hsla(210, 94.1%, 11.1%, 0.075)',
        offsetX: '0',
        offsetY: '16px',
        blur: '56px',
      },
    },
    space: {
      25: '2px',
      50: '4px',
      100: '8px',
      150: '12px',
      200: '16px',
      250: '20px',
      300: '24px',
      400: '32px',
      500: '40px',
      600: '48px',
      700: '56px',
      800: '64px',
      900: '72px',
      1000: '80px',
    },
    'font-size': {
      25: '10px',
      50: '12px',
      75: '14px',
      100: '16px',
      125: '18px',
      150: '20px',
      200: '24px',
      250: '28px',
      300: '32px',
      350: '36px',
      400: '40px',
    },
    'font-weight': {
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
      black: 900,
    },
    'line-height': {
      50: '12px',
      75: '14px',
      100: '16px',
      125: '18px',
      150: '20px',
      200: '24px',
      250: '28px',
      300: '32px',
      350: '36px',
      400: '40px',
      500: '48px',
      600: '56px',
    },
  },
  theme: {
    color: {
      content: {
        high: 'hsla(214, 16%, 20%, 1)',
        medium: 'hsla(206, 97.0%, 4.8%, 0.593)',
        low: 'hsla(205, 96.5%, 10.0%, 0.244)',
        brand: 'hsla(153, 100%, 22%, 0.87)',
        'on-brand': 'hsl(137, 47%, 97%)',
        'on-brand-high': 'hsl(137, 47%, 97%)',
        'on-brand-medium': 'hsl(142, 44%, 84%)',
        'on-brand-low': 'hsl(146, 40%, 68%)',
        negative: 'hsla(358, 99.8%, 38.3%, 0.832)',
        warning: 'hsla(23, 100%, 36.8%, 0.980)',
        positive: 'hsla(153, 100%, 22%, 0.87)',
        info: 'hsla(226, 99.6%, 31.1%, 0.797)',
        'on-color': 'hsla(0, 0%, 100%, 1)',
        'on-color-high': 'hsla(0, 0%, 100%, 1)',
        'on-color-medium': 'hsla(0, 0%, 100%, 0.80)',
        'on-color-low': 'hsla(0, 0%, 100%, 0.50)',
      },
      background: {
        surface: 'hsla(0, 0%, 100%, 1)',
        'surface-hover': 'hsla(210, 98.8%, 14.4%, 0.055)',
        body: 'hsl(209, 13.3%, 95.3%)',
        neutral: 'hsla(214, 16%, 20%, 1)',
        'neutral-subtle': 'hsla(210, 98.8%, 14.4%, 0.055)',
        'neutral-subtle-hover': 'hsla(210, 94.1%, 11.1%, 0.075)',
        'brand-high': 'hsl(154, 60%, 32%)',
        brand: 'hsl(151, 55%, 42%)',
        'brand-hover': 'hsl(152, 56%, 39%)',
        'brand-subtle': 'hsla(139, 100%, 32%, 0.10)',
        'brand-subtle-hover': 'hsla(140, 100%, 33%, 0.16)',
        'on-brand': 'hsla(0, 0%, 100%, 0.176)',
        'on-brand-hover': 'hsla(0, 0%, 100%, 0.249)',
        negative: 'hsl(358, 75.0%, 59.0%)',
        'negative-hover': 'hsl(358, 69.4%, 55.2%)',
        'negative-subtle': 'hsla(0, 100%, 50.2%, 0.063)',
        'negative-subtle-hover': 'hsla(0, 100%, 50.0%, 0.102)',
        warning: 'hsl(24, 94.0%, 50.0%)',
        'warning-hover': 'hsl(24, 100%, 46.5%)',
        'warning-subtle': 'hsla(25, 100%, 50.5%, 0.095)',
        'warning-subtle-hover': 'hsla(26, 100%, 50.0%, 0.157)',
        positive: 'hsl(151, 55%, 42%)',
        'positive-hover': 'hsl(152, 56%, 39%)',
        'positive-subtle': 'hsla(139, 100%, 32%, 0.10)',
        'positive-subtle-hover': 'hsla(140, 100%, 33%, 0.16)',
        info: 'hsl(226, 70.0%, 55.5%)',
        'info-hover': 'hsl(226, 58.6%, 51.3%)',
        'info-subtle': 'hsla(224, 100%, 50.1%, 0.059)',
        'info-subtle-hover': 'hsla(223, 98.0%, 48.5%, 0.099)',
        'on-color': 'hsla(0, 0%, 100%, 0.124)',
        'on-color-hover': 'hsla(0, 0%, 100%, 0.176)',
      },
      border: {
        neutral: 'hsla(214, 16%, 20%, 0.84)',
        high: 'hsla(205, 96.5%, 10.0%, 0.244)',
        medium: 'hsla(206, 96.4%, 11.3%, 0.126)',
        low: 'hsla(210, 94.1%, 11.1%, 0.075)',
        brand: 'hsla(151, 100%, 28%, 0.81)',
        'brand-subtle': 'hsla(144, 100%, 29%, 0.32)',
        'on-brand': 'hsl(137, 47%, 97%)',
        negative: 'hsla(358, 99.9%, 42.9%, 0.718)',
        'negative-subtle': 'hsla(0, 99.5%, 44.9%, 0.224)',
        warning: 'hsla(24, 99.9%, 48.4%, 0.969)',
        'warning-subtle': 'hsla(25, 100%, 50.1%, 0.346)',
        positive: 'hsla(151, 100%, 28%, 0.81)',
        'positive-subtle': 'hsla(144, 100%, 29%, 0.32)',
        info: 'hsla(226, 100%, 41.2%, 0.757)',
        'info-subtle': 'hsla(224, 99.5%, 44.9%, 0.224)',
        'on-color': 'hsla(0, 0%, 100%, 1)',
      },
    },
    'corner-radius': {
      container: '12px',
      interactive: '8px',
    },
    interaction: {
      outline: {
        offset: '2px',
        default: {
          width: '2px',
          style: 'solid',
          color: 'hsla(214, 16%, 20%, 0.84)',
        },
        'on-color': {
          width: '2px',
          style: 'solid',
          color: 'hsla(0, 0%, 100%, 1)',
        },
        'on-brand': {
          width: '2px',
          style: 'solid',
          color: 'hsl(137, 47%, 97%)',
        },
      },
      focus: {
        'outline-width': '2px',
        'outline-style': 'solid',
        'outline-color': 'hsla(214, 16%, 20%, 0.84)',
        'outline-color-on-color': 'hsla(0, 0%, 100%, 1)',
        'outline-color-on-brand': 'hsl(137, 47%, 97%)',
        'outline-offset': '2px',
      },
      pressed: {
        scale: {
          small: '0.97',
          medium: '0.95',
          large: '0.90',
        },
      },
    },
    container: {
      'gap-y': {
        small: '16px',
      },
      'padding-x': {
        small: '16px',
        medium: '20px',
      },
    },
    focus: {
      'outline-width': '2px',
      'outline-style': 'solid',
      'outline-color': 'hsla(214, 16%, 20%, 0.84)',
      'outline-color-on-color': 'hsla(0, 0%, 100%, 1)',
      'outline-color-on-brand': 'hsl(137, 47%, 97%)',
      'outline-offset': '2px',
    },
    pressed: {
      scale: {
        small: '0.97',
        medium: '0.95',
        large: '0.90',
      },
    },
    'font-family': {
      global: 'Inter',
      brand: 'Sharon Display',
    },
    text: {
      small: {
        'font-size': '12px',
        'line-height': '16px',
        'letter-spacing': 0,
        overline: {
          'font-family': 'Inter',
          'font-size': '12px',
          'line-height': '16px',
          'font-weight': 600,
          'letter-spacing': '0.08em',
        },
      },
      medium: {
        'font-size': '14px',
        'line-height': '20px',
        'letter-spacing': '-0.006em',
      },
      large: {
        'font-size': '16px',
        'line-height': '24px',
        'letter-spacing': '-0.011em',
      },
      'font-weight': {
        regular: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
      },
      hyperlink: {
        'font-family': 'Inter',
        'font-weight': 600,
        'text-decoration': 'underline',
        small: {
          'font-size': '12px',
          'line-height': '16px',
          'letter-spacing': '0',
        },
        medium: {
          'font-size': '14px',
          'line-height': '20px',
          'letter-spacing': '-0.006em',
        },
        large: {
          'font-size': '16px',
          'line-height': '24px',
          'letter-spacing': '-0.011em',
        },
      },
      heading: {
        'font-family': 'Inter',
        xsmall: {
          'font-size': '16px',
          'line-height': '20px',
          'font-weight': 600,
          'letter-spacing': '-0.011em',
        },
        small: {
          'font-size': '18px',
          'line-height': '20px',
          'font-weight': 600,
          'letter-spacing': '-0.014em',
        },
        medium: {
          'font-size': '20px',
          'line-height': '24px',
          'font-weight': 600,
          'letter-spacing': '-0.017em',
        },
        large: {
          'font-size': '24px',
          'line-height': '28px',
          'font-weight': 600,
          'letter-spacing': '-0.019em',
        },
      },
      display: {
        'font-family': 'Sharon Display',
        small: {
          'font-size': '24px',
          'line-height': '28px',
          'font-weight': 500,
          'letter-spacing': '-0.02em',
        },
        medium: {
          'font-size': '28px',
          'line-height': '32px',
          'font-weight': 500,
          'letter-spacing': '-0.02em',
        },
      },
    },
  },
}
