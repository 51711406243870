import { SFCaseTree, SFCaseTreeField, SFCaseTreeSelectors } from '../services/saleston/types'

export const getTopics = (tree: SFCaseTree): string[] => Object.keys(tree)

export const getCategories = (tree: SFCaseTree, topic: SFCaseTreeSelectors[0]): string[] => {
  if (!(topic in tree)) return []
  return Object.keys(tree[topic])
}

export const getSubjects = (
  tree: SFCaseTree,
  topic: SFCaseTreeSelectors[0],
  category: SFCaseTreeSelectors[1],
): string[] => {
  if (!(topic in tree) || !(category in tree[topic])) return []

  return Object.keys(tree[topic][category])
}

export const getFields = (tree: SFCaseTree, selectors: SFCaseTreeSelectors): SFCaseTreeField[] => {
  if (!(selectors[0] in tree) || !(selectors[1] in tree[selectors[0]]) || !(selectors[2] in tree[selectors[0]][selectors[1]])) return []

  return tree[selectors[0]][selectors[1]][selectors[2]]
}

type TreeClassificationOption = { label: string; value: string }
export const getCombinationOptions = (tree: SFCaseTree): TreeClassificationOption[] => {
  const options: TreeClassificationOption[] = []

  Object.keys(tree).forEach((topic) => (
    Object.keys(tree[topic]).forEach((category) => (
      Object.keys(tree[topic][category]).forEach((subject) => {
        options.push({
          label: `${topic} : ${category} : ${subject}`,
          value: JSON.stringify({
            topic,
            category,
            subject,
          }),
        })
      })
    ))
  ))

  return options
}
