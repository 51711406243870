import { Input, Button, Form } from 'antd'
import React from 'react'
import { IoIosSearch } from 'react-icons/io'

import Title from '../../atoms/Title'

interface VSValidationFormProps {
  onSubmit: (values: any) => void
  style?: React.CSSProperties
}

const VSValidationForm = ({ onSubmit, style }: VSValidationFormProps) => {
  const [form] = Form.useForm()

  return (
    <>
      <Title align='start' size='1rem'>
        Código de validação
      </Title>
      <Form form={form} onFinish={onSubmit} layout='inline' style={style} size='large'>
        <Form.Item name='token' rules={[{
          required: true,
          message: 'Insira o código, por favor!',
        }]}>
          <Input prefix={<IoIosSearch />} placeholder='Informe o código' autoComplete='off' />
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit'>
            Validar código
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default VSValidationForm
