import { Modal, Form, Input, Button, FormInstance } from 'antd'
import React, { Dispatch, SetStateAction } from 'react'

import isJsonString from '../../../utils/isJsonString'

interface MacroModalProps {
  form: FormInstance
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
}

const MacroModal = ({ form, visible, setVisible }: MacroModalProps) => {
  const [modalForm] = Form.useForm()

  return (
    <Modal
      open={visible}
      onCancel={() => {
        setVisible(false)
        modalForm.resetFields()
      }}
      title='Preencher Caso com Macro'
      footer={[
        <Button
          key='back'
          onClick={() => {
            setVisible(false)
            modalForm.resetFields()
          }}
        >
          Cancelar
        </Button>,
        <Button
          key='submit'
          type='primary'
          onClick={() => {
            modalForm.validateFields()
            modalForm.submit()
          }}
        >
          Preencher
        </Button>,
      ]}
    >
      <Form
        form={modalForm}
        onFinish={({ macro }) => {
          Object.entries(JSON.parse(macro)).forEach((field) => {
            form.setFieldValue(...field)
          })
        }}
      >
        <Form.Item
          name='macro'
          rules={[
            {
              required: true,
              message: 'Campo necessário',
            },
            {
              validator: async (_, value) => {
                if (!isJsonString(value)) {
                  throw new Error('Macro Inválido')
                }
              },
            },
          ]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default MacroModal
