import { Col, Form, Row } from 'antd'
import { AxiosResponse } from 'axios'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import message from '../../../services/message'
import getAccounts from '../../../services/saleston/getAccounts'
import { SFAccount } from '../../../services/saleston/types'
import Title from '../../atoms/Title'
import CollapsableCard from '../../molecules/CollapseCard'
import SearchUserForm from '../../molecules/Form/SearchUserForm'
import SearchUserTable from '../../molecules/Table/SearchUser'
import { TabContext } from '../../templates/Client'

interface SearchUserCardProps {
  selectedAccount?: SFAccount
  setSelectedAccount: (selectedAccount: SFAccount) => void
}

const SearchUserCard = ({ selectedAccount, setSelectedAccount }: SearchUserCardProps) => {
  const { document: defaultDocument } = useContext(TabContext)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)
  const [accounts, setAccounts] = useState<SFAccount[]>([])
  const [isTableVisible, setIsTableVisible] = useState<boolean>(false)

  const search = useCallback(({ document }) => {
    setLoading(true)
    setIsTableVisible(true)

    const treatedDocument = document.replace(/\D/g, '')

    getAccounts(treatedDocument)
      .then(({ data: { records } }) => {
        setAccounts(records as SFAccount[])

        if (records.length === 1 && !selectedAccount) {
          setSelectedAccount(records[0])
          setIsTableVisible(false)
          setAccounts([])
          form.resetFields()
        }

        setLoading(false)
      })
      .catch((error: any) => {
        if (treatedDocument && error.response.status === 404) {
          setLoading(false)
          message({
            type: 'warning',
            content: 'Nehum cliente encontrado!',
          })
        }

        setLoading(false)
        return new Promise<AxiosResponse<any>>((_resolve, reject) => {
          reject(error.response)
        })
      })
  }, [setSelectedAccount, selectedAccount, setAccounts, setIsTableVisible, form])

  const selectAccount = useCallback((index: number) => {
    setSelectedAccount(accounts[index])

    setIsTableVisible(false)
    setAccounts([])
    form.resetFields()
  }, [setSelectedAccount, accounts, setAccounts, form])

  useEffect(() => {
    if (!defaultDocument || selectedAccount) return

    search({ document: defaultDocument })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CollapsableCard iconName='searchUser.svg' title='Identificação do cliente' loading={false} autoOpen onChange={() => { }}>
      {selectedAccount && (
        <Row justify='center'>
          <Col style={{ margin: '0 5%' }}>
            <Title variant='light'>Nome</Title>
            <Title>
              {selectedAccount.Name}
            </Title>
          </Col>

          <Col style={{ margin: '0 5%' }}>
            <Title variant='light'>Documento</Title>
            <Title>
              {selectedAccount.CNPJ__c}
            </Title>
          </Col>
        </Row>
      )}
      <SearchUserForm
        form={form}
        onSubmit={search}
        style={{
          margin: '3% 0',
          display: 'flex',
          justifyContent: 'center',
        }}
      />
      {isTableVisible && (
        <SearchUserTable
          loading={loading}
          dataSource={accounts}
          selectRow={selectAccount}
        />
      )}
    </CollapsableCard>
  )
}

export default SearchUserCard
