import { VSStatus, VsTokenHistory } from '../services/vs/types'

export default (history: VsTokenHistory[]): VSStatus => {
  const statusHistory = history.map((item) => item.status)

  if (statusHistory.includes('AUTHORIZED')) {
    return 'AUTHORIZED'
  }

  if (statusHistory.includes('PENDING')) {
    return 'PENDING'
  }

  return 'UNAUTHORIZED'
}
