import { useCallback } from 'react'

import getMacro from '../../services/saleston/getMacro'
import { SFCase } from '../../services/saleston/types'
import useAwaitedData from '../useAwaitedData'

const useGetMacro = (topic: string, category: string, subject: string) => {
  const fetch = useCallback(() => getMacro(topic, category, subject), [topic, category, subject])
  const { data, loading, refetch } = useAwaitedData(fetch, [] as SFCase[])

  return {
    macro: data.records ? data.records[0] : { records: {} },
    loading,
    refetch,
  }
}

export default useGetMacro
