import api from './api'

export interface AnnouncementsFilter {
  document?: string
}

export default async (filters: AnnouncementsFilter) => {
  const queryFilter = new URLSearchParams(JSON.parse(JSON.stringify(filters))).toString()
  return api.get(`/announcement?${queryFilter}`)
}
