import { Button } from 'antd'
import React, { useContext } from 'react'

import { TabContext } from '../../templates/Client'
import './index.css'

interface DrawerTriggerProps {
  caseData: {
    id: string
    number: string
    createdDate: string
    status: string
    subject: string
    accountName: string
    ownerName: string
  }
}

const DrawerTrigger = ({ caseData }: DrawerTriggerProps) => {
  const { drawer } = useContext(TabContext)

  return (
    <Button className="trigger" type="link" onClick={() => {
      drawer!.open!(caseData)
    }}>
      {caseData.number}
    </Button>
  )
}

export default DrawerTrigger
