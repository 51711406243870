import { Alert, AlertProps, Button } from 'antd'
import React, { useState } from 'react'

import { VSStatus } from '../../../../services/vs/types'

interface VSAlertProps {
  status: VSStatus
  action?: {
    title: string
    handler: () => Promise<void>
  }
}

const alertMapping: Record<VSAlertProps['status'], AlertProps & { colors?: { bg?: string; border?: string; text?: string } }> = {
  AUTHORIZED: {
    type: 'success',
    message: 'Este cliente está verificado!',
    colors: {
      bg: '#E0FFF1',
      border: '#7FF5BC',
      text: '#54C01B',
    },
  },
  PENDING: {
    type: 'warning',
    message: 'Token enviado para celular e e-mail informado.',
    colors: {
      bg: '#FEFFE0',
      border: '#FFE500',
      text: '#F8AF15',
    },
  },
  UNAUTHORIZED: {
    type: 'error',
    message: 'Esse cliente não está verificado! Envie o token para verificação.',
    colors: {
      bg: '#FFF1F0',
      border: '#FFA39E',
      text: '#FB4F4C',
    },
  },
}

const VSAlert = ({ status, action }: VSAlertProps) => {
  const [loading, setLoading] = useState(false)

  return (
    <Alert
      style={{
        padding: '2%',
        borderColor: alertMapping[status].colors?.border,
        color: alertMapping[status].colors?.text,
        backgroundColor: alertMapping[status].colors?.bg,
      }}
      message={alertMapping[status].message}
      type={alertMapping[status].type}
      action={
        action && (
          <Button
            loading={loading}
            onClick={() => {
              setLoading(true)
              action.handler()
                .then(() => {
                  setLoading(false)
                })
                .catch(() => {
                  setLoading(false)
                })
            }}
          >
            {action.title}
          </Button>
        )
      }
      showIcon
    />
  )
}

export default VSAlert
