import { Tabs } from 'antd'
import React, { useContext } from 'react'

import CollapsableCard from '../../molecules/CollapseCard'
import { TabContext } from '../../templates/Client'

import './index.css'

import Announcements from './Tabs/Announcements'
import Cases from './Tabs/Cases'

const UserRecordsCard = () => {
  const { account } = useContext(TabContext)

  const tabItems = [
    {
      key: '1',
      label: 'Casos',
      children: <Cases id={account?.Id || ''} />,
    },
    {
      key: '2',
      label: 'Comunicados',
      children: <Announcements document={account?.CNPJ__c || ''} />,
    },
  ]

  return (
    <CollapsableCard iconName='userRecords.svg' title='Registros do Usuário' loading={false} onChange={() => { }} noPadding autoOpen>
      <Tabs defaultActiveKey='1' items={tabItems} />
    </CollapsableCard>
  )
}

export default UserRecordsCard
