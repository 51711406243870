import { Row, Col } from 'antd'
import React from 'react'

import CaseDetailsField from '../../atoms/CaseDetailsField'

interface CaseDetailsProps {
    subject: string
    owner: string
    createdDate: string
    status: string
    customerAccountName: string
}

const CaseDetails = ({ subject, owner, createdDate, status, customerAccountName }: CaseDetailsProps) => (

  <Row gutter={[5, 24]}>
    <Col span={24}>
      <h1 style={{
        fontWeight: 600,
        fontSize: '16px',
        color: '#2B323B',
      }}>
        {subject}
      </h1>
    </Col>

    <Col span={8}>
      <CaseDetailsField
        label="Proprietário"
        value={owner}
      />
    </Col>

    <Col span={8}>
      <CaseDetailsField
        label="Aberto em"
        value={createdDate}
      />
    </Col>

    <Col span={8}>
      <CaseDetailsField
        label="Status"
        value={status}
      />
    </Col>

    <Col span={24}>
      <CaseDetailsField
        label="Cliente"
        value={customerAccountName}
      />
    </Col>
  </Row>
)

export default CaseDetails
