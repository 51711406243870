import { Input, Button, Form } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import React from 'react'
import { IoIosSearch } from 'react-icons/io'

import isCpfOrCnpj from '../../../utils/validations/isCpfOrCnpj'

interface SearchUserFormProps {
  onSubmit: (values: any) => void
  style?: React.CSSProperties
  form?: FormInstance
}

const SearchUserForm = ({ onSubmit, style, form: customForm }: SearchUserFormProps) => {
  const [defaultForm] = Form.useForm()
  const form = customForm || defaultForm

  return (
    <Form form={form} onFinish={onSubmit} layout='inline' style={style} size='large'>
      <Form.Item
        name='document'
        rules={[
          {
            required: true,
            message: 'Insira um CPF ou CNPJ, por favor!',
          },
          () => ({
            validator (_, value) {
              if (!value || isCpfOrCnpj(value)) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('CPF ou CNPJ inválido'))
            },
          }),
        ]}
      >
        <Input prefix={<IoIosSearch />} placeholder='Busque pelo CPF ou CNPJ' />
      </Form.Item>

      <Form.Item>
        <Button type='primary' htmlType='submit'>
          Buscar
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          type='default'
          onClick={() => {
            form.resetFields()
          }}
        >
          Limpar
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SearchUserForm
