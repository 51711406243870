import React from 'react'

import { SFCaseFeedItem } from '../../../services/saleston/types'

export const findCaseChanges = (capabilities: SFCaseFeedItem['capabilities'], fieldName: string) => {
  const change = capabilities?.trackedChanges?.changes?.find((item) => item.fieldName === fieldName)
  return [change?.oldValue || '', change?.newValue || '']
}

const getFeedItemTitle = (item: SFCaseFeedItem): React.ReactElement => {
  switch (item.type) {
  case 'TextPost':
    return <p style={{ margin: 0 }}><b>{item.actor?.displayName}</b></p>
  case 'CallLogPost':
    return <p style={{ margin: 0 }}>Rechamado de <b>{item.actor?.displayName}</b></p>
  case 'EmailMessageEvent':
    return <p style={{ margin: 0 }}>Email enviado de Stone para <b>{'fulano@gmail.com'}</b></p>
  case 'ChangeStatusPost':
    return <p style={{ margin: 0 }}>Status Atualizado de <b>{findCaseChanges(item.capabilities, 'Status')[0]}</b> para <b>{findCaseChanges(item.capabilities, 'Status')[1]}</b></p>
  case 'TrackedChange':
    return <p style={{ margin: 0 }}>Alteração de dono do caso de <b>{findCaseChanges(item.capabilities, 'Case Owner')[0]}</b> para <b>{findCaseChanges(item.capabilities, 'Case Owner')[1]}</b></p>
  default:
    return <p style={{ margin: 0 }}><b>{item.actor?.displayName}</b></p>
  }
}

export default getFeedItemTitle
