import { Table } from 'antd'
import React from 'react'

import { VsTokenHistory } from '../../../../services/vs/types'
import StatusTag, { StatusTagProps } from '../../../atoms/StatusTag'

import Title from '../../../atoms/Title'

import columns from './columns'

interface VsHistoryTableProps {
  dataSource: VsTokenHistory[]
  loading: boolean
}

const statusMap: Record<VsTokenHistory['status'], StatusTagProps['status']> = {
  AUTHORIZED: 'success',
  PENDING: 'warning',
  UNAUTHORIZED: 'error',
}

const insertTag = (items: VsTokenHistory[]) =>
  items.map((item) => ({
    ...item,
    status: <StatusTag status={statusMap[item.status]} />,
  }))

const VsHistoryTable = ({ dataSource, loading }: VsHistoryTableProps) => (
  <>
    <Title align='start'>Histórico de verificações</Title>
    <Table
      loading={loading}
      dataSource={insertTag(dataSource)}
      columns={columns}
      pagination={false}
      rowKey={(record) => `${record.user_id}-${record.created_at}`}
    />
  </>
)

export default VsHistoryTable
