import React from 'react'

import CopyButton from '../../../atoms/CopyButton'

export default [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '10%',
    ellipsis: true,
    render: (id: string) => (
      <CopyButton content={id} alertMessage="User Id copiado com sucesso." />
    ),
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Celular',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Enviar Token',
    dataIndex: 'sendToken',
    key: 'sendToken',
  },
]
