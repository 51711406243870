const friendlyDate = (timestamp: string): string => {
  const date = new Date(timestamp)
  return date.toLocaleDateString('pt-BR', {
    year: 'numeric',
    month: ('short'),
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })
}

export default friendlyDate
