import { Auth } from 'aws-amplify'

import decodeJwt from './decodeJwt'

export default async () => {
  const token = await Auth.currentSession()
    .then((session) => session.getIdToken().getJwtToken())
    .catch(() => {})

  if (!token) {
    const credentials = localStorage.getItem('salesforce-credentials')
    if (!credentials) {
      console.error('User not authenticated')
      return {}
    }

    return JSON.parse(credentials)
  }

  return decodeJwt(token)
}
