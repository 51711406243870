import { ThemeConfig, theme } from 'antd'

import tokens from './jade-origin'

const jadeTheme: ThemeConfig = {
  token: {
    colorPrimary: tokens.theme.color.content.brand,
    colorLink: tokens.theme.color.content.brand,
    colorSuccess: tokens.theme.color.content.positive,
    colorInfo: tokens.theme.color.content.info,
    colorWarning: tokens.theme.color.content.warning,
    colorError: tokens.theme.color.content.negative,

    colorText: tokens.theme.color.content.high,
    colorTextSecondary: tokens.theme.color.content.low,
    colorTextTertiary: tokens.theme.color.content.low,
    colorTextQuaternary: tokens.theme.color.content.low,
    colorPrimaryText: tokens.theme.color.content.high,
    colorPrimaryTextActive: tokens.theme.color.content.brand,
    colorPrimaryTextHover: tokens.theme.color.content.high,
    colorSuccessText: tokens.theme.color.content.positive,
    colorSuccessTextActive: tokens.theme.color.content.positive,
    colorSuccessTextHover: tokens.theme.color.content.positive,

    colorBgBase: tokens.theme.color.background.surface,
    colorBgLayout: tokens.theme.color.background.body,
    colorPrimaryBg: tokens.theme.color.background['brand-subtle'],
    colorPrimaryBgHover: tokens.theme.color.background['brand-subtle-hover'],
    colorSuccessBg: tokens.theme.color.background['positive-subtle'],
    colorSuccessBgHover: tokens.theme.color.background['positive-subtle-hover'],
    colorWarningBg: tokens.theme.color.background['warning-subtle'],
    colorWarningBgHover: tokens.theme.color.background['warning-subtle-hover'],
    colorErrorBg: tokens.theme.color.background['negative-subtle'],
    colorErrorBgHover: tokens.theme.color.background['negative-subtle-hover'],

    colorFill: tokens.theme.color.background.neutral,
    colorFillSecondary: tokens.theme.color.background['neutral-subtle'],
    colorFillTertiary: tokens.theme.color.background.surface,
    colorFillQuaternary: tokens.theme.color.background.body,

    colorBorder: tokens.theme.color.border.low,
    colorBorderSecondary: tokens.theme.color.border.low,
    colorPrimaryBorder: tokens.theme.color.border.brand,
    colorPrimaryBorderHover: tokens.theme.color.border['brand-subtle'],
    colorSuccessBorder: tokens.theme.color.border.positive,
    colorSuccessBorderHover: tokens.theme.color.border['positive-subtle'],
    colorInfoBg: tokens.theme.color.border.info,
    colorInfoBgHover: tokens.theme.color.border['info-subtle'],
    colorWarningBorder: tokens.theme.color.border.warning,
    colorWarningBorderHover: tokens.theme.color.border['warning-subtle'],
    colorErrorBorder: tokens.theme.color.border.negative,
    colorErrorBorderHover: tokens.theme.color.border['negative-subtle'],
  },
  components: {
    Typography: {
      fontSize: parseInt(tokens.theme.text.medium['font-size']),
      fontSizeHeading1: parseInt(tokens.theme.text.heading.large['font-size']),
      fontSizeHeading2: parseInt(tokens.theme.text.heading.medium['font-size']),
      fontSizeHeading3: parseInt(tokens.theme.text.heading.small['font-size']),
      fontSizeHeading4: parseInt(tokens.theme.text.heading.xsmall['font-size']),

      lineHeight: parseFloat(tokens.theme.text.medium['line-height']),
      lineHeightHeading1: parseFloat(tokens.theme.text.heading.large['line-height']),
      lineHeightHeading2: parseFloat(tokens.theme.text.heading.medium['line-height']),
      lineHeightHeading3: parseFloat(tokens.theme.text.heading.small['line-height']),
      lineHeightHeading4: parseFloat(tokens.theme.text.heading.xsmall['line-height']),

      fontWeightStrong: tokens.theme.text.display.medium['font-weight'],
      linkHoverDecoration: 'underline',
    },
    Button: {
      primaryShadow: 'none',
      defaultShadow: 'none',
    },
    Card: {
      boxShadow: 'none',
      boxShadowSecondary: 'none',
      boxShadowTertiary: 'none',
    },
  },
}

export default jadeTheme
