import { Auth } from 'aws-amplify'

import { useEffect, useState } from 'react'

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  // SessionId
  useEffect(() => {
    // Check if user already is authenticated
    const savedCredentials = localStorage.getItem('salesforce-credentials')
    if (savedCredentials) {
      setIsAuthenticated(true)
    }

    // Expect auth event
    const handler = ({ data }: any) => {
      if (data.call !== 'sfSendValue') return
      const [
        username,
        userId,
        sessionId,
        profileId,
        profileName,
      ] = data.value.split(';')

      const credentials = {
        username,
        userId,
        sessionId,
        profileId,
        profileName,
      }
      localStorage.setItem('salesforce-credentials', JSON.stringify(credentials))
      setIsAuthenticated(true)
    }

    window.addEventListener('message', handler)
    return () => window.removeEventListener('message', handler)
  }, [])

  // Amplify (Cognito)
  useEffect(() => {
    // Check if user already is authenticated
    Auth.currentAuthenticatedUser()
      .then(() => {
        setIsAuthenticated(true)
      })
      .catch(() => {
        setIsAuthenticated(false)
      })

    // Expect auth event
    const handler = ({ key, newValue }: StorageEvent) => {
      if (key === 'amplify-signin-with-hostedUI' && newValue === 'true') {
        setIsAuthenticated(true)
      }
    }

    window.addEventListener('storage', handler)
    return () => window.removeEventListener('storage', handler)
  }, [])

  return {
    isAuthenticated,
  }
}

export default useAuth
