import { Rule } from 'antd/lib/form'

export interface SFAccount {
  attributes: {
    type: string
    url: string
  }
  Id: string
  Name: string
  CNPJ__c: string
}

export interface SFCaseBase {
  Subject: string
  Description: string
  Demanda_Nao_Realizada__c?: string
  status: string
  Momento_UAU__c?: string
  Transfer_ncia_Indevida__c?: string
}

export interface SFAnnouncementBase {
  Subject: string
  CreatedDate: string
  Description?: string
  Status?: string
}

export type SFCase = SFCaseBase & Record<string, any>
export type SFAnnouncement = SFAnnouncementBase & Record<string, any>

export interface SFIncidentBase {
  N_vel__c: String
  Assunto__c: String
  Subassunto__c: String
  Direcionamento__c: String
  Descri_o_do_Incidente__c: String
  Discurso__c: String
  Nome_Incidente__c: String
  Tipo_de_acompanhamento__c: String
  Name: String
  CreatedDate: String
  Empresa_afetada__c: String
}

export type SFIncident = SFIncidentBase & Record<string, any>

export interface SFCaseTreeField {
  SFfield: string
  title: string
  field: string
  type: 'text' | 'select' | 'checkbox' | 'textarea'
  placeholder?: string
  rules?: Rule[]
  tooltip?: string
  options?: string[]
}

export interface SFCaseTree {
  [topic: string]: {
    [category: string]: {
      [subject: string]: SFCaseTreeField[]
    }
  }
}
export type SFCaseTreeSelectors = [topic: string, category: string, subject: string]

export interface PostCaseTranscript {
  caseId: string
  phone?: string
  email?: string
}

export interface SFCaseFeedItemActorBase {
  displayName?: string
}

export type SFCaseFeedItemActor = SFCaseFeedItemActorBase & Record<string, any>

export interface SFCaseFeedItemBodyBase {
  isRichText?: boolean
  text?: string
}

export type SFCaseFeedItemBody = SFCaseFeedItemBodyBase & Record<string, any>

export interface SFCaseFeedItemCommentBase {
  body?: SFCaseFeedItemBodyBase
  user?: SFCaseFeedItemActor
  createdDate?: string
}

export type SFCaseFeedItemComment = SFCaseFeedItemCommentBase & Record<string, any>

export interface SFCaseFeedItemCapabilitiesCommentsPageBase {
  currentPageToken?: string
  currentPageUrl?: string
  items?: SFCaseFeedItemComment[]
  nextPageToken?: string
  nextPageUrl?: string
  previousPageToken?: string
  previousPageUrl?: string
  total?: number
}

export type SFCaseFeedItemCapabilitiesCommentsPage = SFCaseFeedItemCapabilitiesCommentsPageBase & Record<string, any>

export interface SFCaseFeedItemCapabilitiesCommentsBase {
  page?: SFCaseFeedItemCapabilitiesCommentsPage
}

export type SFCaseFeedItemCapabilitiesComments = SFCaseFeedItemCapabilitiesCommentsBase & Record<string, any>

export interface SFCaseFeedItemCapabilityEnhancedLinkBase {
  description?: string
}

export type SFCaseFeedItemCapabilityEnhancedLink = SFCaseFeedItemCapabilityEnhancedLinkBase & Record<string, any>

export interface SFCaseFeedItemCapabilityEmailAddressBase {
  displayName?: string
  emailAddress?: string
}

export type SFCaseFeedItemCapabilityEmailAddress = SFCaseFeedItemCapabilityEmailAddressBase & Record<string, any>

export interface SFCaseFeedItemCapabilityEmailMessageBase {
  body?: string
  toAddresses?: SFCaseFeedItemCapabilityEmailAddress[]
}

export type SFCaseFeedItemCapabilityEmailMessage = SFCaseFeedItemCapabilityEmailMessageBase & Record<string, any>

export interface SFCaseFeedItemCapabilityTrackedChangeBase {
  fieldName?: string
  newValue?: string
  oldValue?: string
}

export type SFCaseFeedItemCapabilityTrackedChange = SFCaseFeedItemCapabilityTrackedChangeBase & Record<string, any>

export interface SFCaseFeedItemCapabilityTrackedChangesBase {
  changes: SFCaseFeedItemCapabilityTrackedChange[]
}

export type SFCaseFeedItemCapabilityTrackedChanges = SFCaseFeedItemCapabilityTrackedChangesBase & Record<string, any>

export interface SFCaseFeedItemCapabilityFileBase {
  title?: string
  downloadUrl?: string
  fileExtension?: string
  fileSize?: string
  fileType?: string
}

export type SFCaseFeedItemCapabilityFile = SFCaseFeedItemCapabilityFileBase & Record<string, any>

export interface SFCaseFeedItemCapabilityFilesBase {
  items?: SFCaseFeedItemCapabilityFile[]
}

export type SFCaseFeedItemCapabilityFiles = SFCaseFeedItemCapabilityFilesBase & Record<string, any>

export interface SFCaseFeedItemCapabilitiesBase {
  comments?: SFCaseFeedItemCapabilitiesComments
  enhancedLink?: SFCaseFeedItemCapabilityEnhancedLink
  emailMessage?: SFCaseFeedItemCapabilityEmailMessage
  trackedChanges?: SFCaseFeedItemCapabilityTrackedChanges
  files?: SFCaseFeedItemCapabilityFiles
}

export type SFCaseFeedItemCapabilities = SFCaseFeedItemCapabilitiesBase & Record<string, any>

export interface SFCaseFeedItemBase {
  id: string
  actor?: SFCaseFeedItemActor
  body?: SFCaseFeedItemBody
  capabilities?: SFCaseFeedItemCapabilities
  createdDate?: string
  type?: string
}

export type SFCaseFeedItem = SFCaseFeedItemBase & Record<string, any>

export interface SFCaseFeedBase {
  currentPageToken?: string
  currentPageUrl?: string
  elements: SFCaseFeedItem[]
  nextPageToken?: string
  nextPageUrl?: string
  updatesToken?: string
  updatesUrl?: string
}

export type SFCaseFeed = SFCaseFeedBase & Record<string, any>

export enum FeedTypeEnum {
  TEXTPOST = 'TextPost',
  RECALL = 'Recall'
}

export interface SFCasePostFeedItemData {
  type: FeedTypeEnum
  caseId: string
  body: string
  changeOwner: boolean
}

export interface SFCaseChangeOwner {
  caseId: string
  ownerId: string
}
