import api from './api'

export default async (profileName: string, page?: number, perPage?: number) =>
  api.get('/incident/active', {
    params: {
      profileName,
      page,
      perPage,
    },
  })
