import { useCallback } from 'react'

import getCaseTree from '../../services/saleston/getCaseTree'
import { SFCaseTree } from '../../services/saleston/types'
import useAwaitedData from '../useAwaitedData'

const useGetCaseTree = () => {
  const fetch = useCallback(() => getCaseTree(), [])
  const { data, loading, refetch } = useAwaitedData(fetch, {} as SFCaseTree)

  return {
    tree: data || '{}',
    loading,
    refetch,
  }
}

export default useGetCaseTree
