import { Button, Space } from 'antd'
import React from 'react'

import packageJson from '../../../../package.json'

const SupportMenu = () => (
  <Space style={{
    display: 'flex',
    justifyContent: 'space-between',
  }}>
    <Space style={{ display: 'flex' }}>
      {`v${packageJson.version}`}
    </Space>
    <Space style={{ display: 'flex' }}>
      <Button type="link" size="small" onClick={() => window.location.replace('/')}>Recarregar</Button>
      <Button type="link" size="small" onClick={() => {
        localStorage.clear()
        window.location.replace('/')
      }}>Limpar Cache</Button>
    </Space>
  </Space >
)

export default SupportMenu
