export default (phone: string) => {
  const phoneRegex = /^[+][0-9]{2}[(]?[0-9]{2,3}[)]?[0-9]{5}[-]?[0-9]{4}$/

  const parsedPhone = `+55${phone}`
  if (phoneRegex.test(parsedPhone)) {
    return parsedPhone
  }

  return phone
}
