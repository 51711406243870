import { useCallback } from 'react'

import { SFCase } from '../../services/saleston/types'
import VSgetContacts from '../../services/vs/VSgetContacts'
import useAwaitedData from '../useAwaitedData'

const useGetContacts = (document: string) => {
  const fetch = useCallback(() => VSgetContacts(document), [document])
  const { data, loading, refetch } = useAwaitedData(fetch, [] as SFCase[])

  return {
    contacts: data.data ?? [],
    loading,
    refetch,
  }
}

export default useGetContacts
