import React from 'react'

interface TitleProps {
  align?: 'start' | 'center' | 'end'
  variant?: 'light' | 'dark'
  size?: string
  bold?: 'bolder' | 'bold' | 'normal' | 'lighter'
  children: any
}

const Title = ({ align = 'center', variant = 'dark', size = '1.1rem', bold = 'normal', children }: TitleProps) => {
  const chooseColor = () => {
    const options: Record<NonNullable<TitleProps['variant']>, string> = {
      light: '#BBC9D9',
      dark: '#45505E',
    }

    return options[variant]
  }

  return (
    <h1
      style={{
        textAlign: align,
        fontSize: size,
        color: chooseColor(),
        fontWeight: bold,
      }}
    >
      {children}
    </h1>
  )
}

export default Title
