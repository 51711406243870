import { ColumnType } from 'antd/lib/table'

export default [
  {
    title: 'Nível',
    dataIndex: 'N_vel__c',
    key: 'N_vel__c',
  },
  {
    title: 'Título',
    dataIndex: 'Name',
    key: 'Name',
  },
  {
    title: 'Tipo',
    dataIndex: 'Tipo_de_acompanhamento__c',
    key: 'Tipo_de_acompanhamento__c',
  },
  {
    title: 'Criar caso',
    key: 'createCase',
    dataIndex: 'createCase',
    align: 'center' as ColumnType<any>['align'],
  },
]
