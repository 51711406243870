import { Card, Table, Tooltip, Tag, Button } from 'antd'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import { CopyToClipboard } from 'react-copy-to-clipboard'

import message from '../../../../services/message'
import createCase from '../../../../services/saleston/createCase'
import postCaseTranscript from '../../../../services/saleston/postCaseTranscript'
import { SFIncident } from '../../../../services/saleston/types'
import Title from '../../../atoms/Title'

import { TabContext } from '../../../templates/Client'
import type { TablePaginationConfig } from 'antd/es/table'

import './index.css'
import columns from './columns'

interface IncidentsTableProps {
  dataSource: SFIncident[]
  loading: boolean
  refetch: () => void
  pagination: TablePaginationConfig
  setPagination: (pagination: TablePaginationConfig) => void
}

const IncidentsTable = ({
  dataSource,
  loading,
  refetch,
  pagination,
  setPagination,
}: IncidentsTableProps) => {
  const { task_id, phone, email, account } = useContext(TabContext)

  const handleTableChange = (paginationComponent: TablePaginationConfig) => {
    setPagination(paginationComponent)
    refetch()
  }

  const tableItems = dataSource.map((item) => (
    {
      ...item,
      Name: `${item.Name} - [${item?.Nome_Incidente__c !== null ? item.Nome_Incidente__c.toUpperCase() : ''}]${item?.Subassunto__c !== null
        ? `[${item.Subassunto__c.toUpperCase()}]`
        : ''}`,
    }
  ))

  const buildPriorityTag = (value: string) => {
    const priority = `P${value.slice(6)}`
    return <Tag className={priority}>{priority}</Tag>
  }

  const [btnStatus, setBtnStatus] = useState<boolean[]>([])

  useEffect(() => {
    setBtnStatus([])
  }, [account])

  const disable = (btnIndex: number) => {
    setBtnStatus(prevBtnStatuses => {
      const newBtnStatus = [...prevBtnStatuses]
      newBtnStatus[btnIndex] = true
      return newBtnStatus
    })
  }

  const insertButton = (data: SFIncident[]) =>
    data.map((item: any, btnIndex: number) => ({
      ...item,
      createCase: (
        <Button
          type='primary'
          shape='circle'
          onClick={() => {
            disable(btnIndex)
            actionCreateCase(item)
          }}
          disabled={btnStatus[btnIndex] || item.Tipo_de_acompanhamento__c === 'Aviso Operação'}
        >
          +
        </Button>
      ),
      N_vel__c: buildPriorityTag(item?.N_vel__c || ''),
    }))

  const actionCreateCase = useCallback((item) => {
    createCase({
      Subject: `${item.Nome_Incidente__c} - ${item.Name}`,
      TemaPagarmeMEI__c: 'Comunicados',
      CategoriaPagarmeMEI__c: 'Incidente',
      Incidente_Relacionado__c: item.Id,
      accountId: account && account.Id,
      Description: `Este é um caso de incidente. O discurso foi: \n
          ${item.Discurso__c}.`,
      status: 'Resolvido',
      Twilio_Task_Id__c: task_id,
    })
      .then(({ data }) => {
        message({
          type: 'success',
          content: `Case #${data.caseId} criado com sucesso!`,
        })

        if (phone || email) {
          postCaseTranscript({
            caseId: data.caseId,
            email,
            phone,
          })
        }

        window.dispatchEvent(new CustomEvent('case:created', { detail: { accountId: account?.Id } }))
      })
      .catch(() => {
        message({
          type: 'warning',
          content: 'Ocorreu algo errado! Tente novamente mais tarde.',
        })
      })
  }, [account, task_id, phone, email])

  return (
    <>
      <Table
        columns={columns}
        dataSource={insertButton(tableItems)}
        rowKey={(record) => record.Id}
        loading={loading}
        pagination={pagination}
        expandable={{
          expandedRowRender: record => (
            <>
              <Title align='start'>Direcionamento</Title>
              <p style={{ margin: 0 }}>{record.Direcionamento__c}</p>
              <br />
              <Card
                title='Discurso'
                extra={
                  <CopyToClipboard
                    text={record.Discurso__c.toString()}
                    onCopy={() => {
                      message({
                        type: 'success',
                        content: 'Discurso copiado com sucesso.',
                      })
                    }}
                  >
                    <Tooltip placement="topRight" title='Copiar'>
                      <span className='iconCopy'>
                        <img src='./images/copy.svg' alt='icon' className='icon' />
                      </span>
                    </Tooltip>
                  </CopyToClipboard>
                }
              >
                <p style={{ margin: 0 }}>{record.Discurso__c}</p>
              </Card>
            </>
          ),
        }}
        onChange={handleTableChange}
      />
    </>
  )
}

export default IncidentsTable
