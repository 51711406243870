import { ColumnType } from 'antd/es/table'
import React from 'react'

import CopyButton from '../../../atoms/CopyButton'

export default [
  {
    title: 'ID',
    dataIndex: 'user_id',
    key: 'user_id',
    width: '10%',
    ellipsis: true,
    render: (id: string) => (
      <CopyButton content={id} alertMessage="User Id copiado com sucesso." />
    ),
  },
  {
    title: 'Nome',
    dataIndex: 'user_name',
    key: 'user_name',
  },
  {
    title: 'Criada em',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (date: string) => new Date(date).toLocaleString(),
  },
  {
    title: 'Criado Por',
    dataIndex: 'created_by',
    key: 'created_by',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center' as ColumnType<any>['align'],
  },
]
