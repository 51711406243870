import { Tabs, TabsProps } from 'antd'
import React, { ReactElement } from 'react'

interface TabPanelProps {
  panes: {
    key: string
    label: string
    children: ReactElement
  }[]
  active: string
  onChange: TabsProps['onChange']
  onEdit: TabsProps['onEdit']
}

export default function TabPanel ({ panes, active, onChange, onEdit }: TabPanelProps) {
  return (
    <Tabs type='editable-card' hideAdd onChange={onChange} activeKey={active} onEdit={onEdit} items={panes} />
  )
}
