import React, { useCallback } from 'react'

import { SFCaseFeedItem } from '../../../services/saleston/types'

import Attachment from './Attachment'
import FeedItemCallLogPost from './CallLogPost'
import FeedItemGenericPost from './GenericPost'
import FeedItemTextPost from './TextPost'

interface FeedItemProps {
    feedItemData: SFCaseFeedItem
}

const FeedItemBody = ({ feedItemData }: FeedItemProps) => {
  const getFeedPost = useCallback(() => {
    switch (feedItemData.type) {
    case 'TextPost':
      return (<FeedItemTextPost feedItemData={feedItemData} />)
    case 'CallLogPost':
      return (<FeedItemCallLogPost feedItemData={feedItemData} />)
    case 'EmailMessageEvent':
      return (<></>)
    case 'ChangeStatusPost':
      return (<></>)
    case 'TrackedChange':
      return (<></>)
    default:
      return (<FeedItemGenericPost feedItemData={feedItemData} />)
    }
  }, [feedItemData])

  return (
    <>
      {getFeedPost()}
      <Attachment files={feedItemData.capabilities?.files} />
    </>
  )
}

export default FeedItemBody
