/* eslint-disable no-restricted-syntax */
import { useCallback } from 'react'

import getCases, { CaseFilter } from '../../services/saleston/getCases'
import { SFCase } from '../../services/saleston/types'
import useAwaitedData from '../useAwaitedData'

const useGetCases = ({ accountId, tema, categoria, assunto, status, createdInTheLastDays }: CaseFilter, page?: number, perPage?: number) => {
  const fetch = useCallback(() => getCases({
    accountId,
    tema,
    categoria,
    assunto,
    status,
    createdInTheLastDays,
  }, page, perPage), [accountId, tema, categoria, assunto, status, createdInTheLastDays, page, perPage])

  const { data, loading, refetch } = useAwaitedData(fetch, {
    records: [] as SFCase[],
    totalRecords: 0,
  })

  return {
    cases: data,
    loading,
    refetch,
  }
}

export default useGetCases
