import createCase from './createCase'
import getCaseById from './getCaseById'
import { SFCase } from './types'

export default async (caseData: SFCase) => {
  const { data: { caseId } } = await createCase(caseData)
  const { data } = await getCaseById({ id: caseId })

  return data
}
