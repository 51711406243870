import { Button } from 'antd'
import React from 'react'
import { useCopyToClipboard } from 'react-use'

import useGetMacro from '../../../hooks/saleston/getMacro'
import CollapsableCard from '../CollapseCard'

interface GuidedServiceProps {
  selectors: [topic: string, category: string, subject: string]
}

const GuidedService = ({ selectors }: GuidedServiceProps) => {
  const { macro } = useGetMacro(...selectors)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, copy] = useCopyToClipboard()

  const copyMacro = () => {
    copy(macro.Texto_Macro__c)
  }

  return (
    <CollapsableCard title='Atendimento guiado' loading={false} autoOpen onChange={() => {}} noMargin>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: macro.Fluxo_de_Atendimento__c }} />
      {macro.Texto_Macro__c && (
        <Button type='primary' onClick={copyMacro}>
          Copiar Macro
        </Button>
      )}
    </CollapsableCard>
  )
}

export default GuidedService
