import { Table, Row } from 'antd'
import React from 'react'

import { SFAnnouncement } from '../../../../services/saleston/types'
import type { TablePaginationConfig } from 'antd/es/table'

import columns from './columns'

interface UserAnnouncementsTableProps {
  dataSource: SFAnnouncement[]
  customColumns?: typeof columns
  loading: boolean
  pagination?: TablePaginationConfig
  setPagination?: (pagination: TablePaginationConfig) => void
}

const UserAnnouncementsTable = ({ dataSource, customColumns, loading, pagination, setPagination }: UserAnnouncementsTableProps) => {
  const handleTableChange = (paginationComponent: TablePaginationConfig) => {
    if (!pagination || !setPagination) return

    setPagination(paginationComponent)
  }

  return (
    <>
      <Table
        columns={customColumns || columns}
        dataSource={dataSource}
        rowKey={(record) => record.rowKey}
        loading={loading}
        pagination={pagination || false}
        expandable={{
          expandedRowRender: (record) => <>
            <Row>
              <p>
                {record?.Description! ? record.Description : ' A descrição não foi preenchida!'}
              </p>
            </Row>
          </>,
        }}
        onChange={handleTableChange}
      />
    </>
  )
}

export default UserAnnouncementsTable
