import React from 'react'

import DrawerTrigger from '../../DrawerTrigger'

export default [
  {
    title: 'Caso',
    dataIndex: 'CaseNumber',
    key: 'CaseNumber',
    width: '15%',
    ellipsis: true,
    render: (caseNumber: string, record: any) => {
      const caseData = {
        id: record.Id,
        number: caseNumber,
        createdDate: record.CreatedDate,
        status: record.Status,
        subject: record.Subject,
        accountName: record.Account?.Name || '',
        ownerName: record.Owner?.Name || '',
      }

      return <DrawerTrigger caseData={caseData} />
    },
  },
  {
    title: 'Assunto',
    dataIndex: 'Subject',
    key: 'Subject',
    width: '37%',
    ellipsis: true,
  },
  {
    title: 'Criada em',
    dataIndex: 'CreatedDate',
    key: 'CreatedDate',
    width: '20%',
    ellipsis: true,
  },
  {
    title: 'Dono',
    dataIndex: ['Owner', 'Name'],
    key: 'Name',
    width: '15%',
    ellipsis: true,
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'Status',
    width: '13%',
    ellipsis: true,
  },
]
