import React, { useContext, useEffect, useState } from 'react'
import { IoAlertCircleOutline } from 'react-icons/io5'

import useGetCases from '../../../../hooks/saleston/getCases'
import { SFCase } from '../../../../services/saleston/types'
import Container from '../../../atoms/Container'
import { TabContext } from '../../../templates/Client'
import UserRecordsTable from '../../Table/UserRecords'

import columns from './columns'

const openStatuses = ['Novo', 'Aberto', 'Pendente', 'Em espera']

interface RecallAlertProps {
  selectors: [topic: string, category: string, subject: string]
}

const RecallAlert = ({ selectors: [tema, categoria, assunto] }: RecallAlertProps) => {
  const { account } = useContext(TabContext)
  const [cases, setCases] = useState<SFCase[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const { cases: { records: resolvedCases }, loading: loadingResolvedCases } = useGetCases({
    accountId: account?.Id,
    tema,
    categoria,
    assunto,
    status: 'Resolvido',
    createdInTheLastDays: 3,
  })

  const { cases: { records: allCases }, loading: loadingAll } = useGetCases({
    accountId: account?.Id,
    tema,
    categoria,
    // status needs to be declared outside the component function or in a useState to avoid loops
    status: openStatuses,
    assunto,
  })

  useEffect(() => {
    setCases([...resolvedCases, ...allCases])
  }, [resolvedCases, allCases])

  useEffect(() => {
    setLoading(loadingAll || loadingResolvedCases)
  }, [loadingAll, loadingResolvedCases])

  return cases.length > 0 && !loading
    ? (
      <Container
        style={{
          padding: '2%',
          border: '1px solid #F4B643',
          backgroundColor: '#FEFFE0',
          borderRadius: '2px',
        }}
      >
        <div style={{ display: 'flex' }}>
          <IoAlertCircleOutline color='#F4B643' size='30' />
          <div style={{ margin: '3px 0 0 10px' }}>
            <h4 style={{ margin: 0 }}><b>Alerta Rechamado!</b> Há um caso semelhante aberto recentemente 🚨</h4>
            <text style={{ fontSize: 11 }}>Favor analisar o caso abaixo e, se necessário, realizar um rechamado:</text>
          </div>
        </div>

        <Container style={{ padding: '2%' }}>
          <UserRecordsTable
            dataSource={cases}
            customColumns={columns}
            loading={loading}
          />
        </Container>

      </Container>
    )
    : (<></>)
}

export default RecallAlert
