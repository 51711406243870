import { message } from 'antd'
import { ArgsProps } from 'antd/lib/message'
import { MessageType } from 'antd/lib/message/interface'

export default (args: ArgsProps): MessageType => {
  const key = window.crypto.randomUUID()

  return message.open({
    key,
    onClick: () => message.destroy(key),
    style: { cursor: 'pointer' },
    ...args,
  })
}
