import { ConfigProvider } from 'antd'
import { Amplify, Hub } from 'aws-amplify'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import jadeTheme from './theme/jade'

const {
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_APP_CLIENT_ID,
  REACT_APP_SSO_DOMAIN,
  REACT_APP_SSO_REDIR_URL,
} = process.env

const amplifyConfig = {
  mandatorySignIn: true,
  region: REACT_APP_COGNITO_REGION,
  userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: REACT_APP_COGNITO_APP_CLIENT_ID,
  oauth: {
    domain: REACT_APP_SSO_DOMAIN,
    scope: ['openid', 'profile'],
    redirectSignIn: REACT_APP_SSO_REDIR_URL,
    redirectSignOut: REACT_APP_SSO_REDIR_URL,
    responseType: 'code',
  },
}

Amplify.configure({
  Auth: amplifyConfig,
})

Hub.listen('auth', ({ payload }) => {
  switch (payload.event) {
  case 'signIn':
    window.close()
    break

  default:
    break
  }
})

const Root = () => (
  <ConfigProvider theme={jadeTheme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ConfigProvider>
)

ReactDOM.render(<Root />, document.getElementById('root'))
