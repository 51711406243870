export default [
  {
    title: 'Id',
    dataIndex: 'Id',
    key: 'Id',
  },
  {
    title: 'Nome Fantasia',
    dataIndex: 'Name',
    key: 'Name',
  },
  {
    title: 'Documento',
    dataIndex: 'CNPJ__c',
    key: 'CNPJ__c',
  },
]
