import { Tag } from 'antd'
import React from 'react'

import { IoCloudDownloadOutline } from 'react-icons/io5'

import { SFCaseFeedItemCapabilityFiles } from '../../../../services/saleston/types'
import getEnv from '../../../../utils/getEnv'

interface AttachmentProps {
  files?: SFCaseFeedItemCapabilityFiles
}

const Attachment = ({ files }: AttachmentProps) => (
  <>
    {files?.items && (
      <>
        {files.items.map((fileToDownload: any, i) => (
          <>
            <Tag key={`attachment-${i}`} style={{
              cursor: 'pointer',
              marginTop: '10px',
            }} color='success' icon={<IoCloudDownloadOutline />} onClick={() => {
              window.open(`${getEnv('REACT_APP_SALESFORCE_URL')}/sfc/servlet.shepherd/document/download/${fileToDownload.id}?operationContext=S1`)
            }}>
              {fileToDownload.title}
            </Tag>
          </>
        ))}
      </>
    )}
  </>
)

export default Attachment
