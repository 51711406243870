import { useCallback } from 'react'

import getIncidents from '../../services/saleston/getIncidents'
import { SFIncident } from '../../services/saleston/types'
import useAwaitedData from '../useAwaitedData'

const useGetIncidents = (page?: number, perPage?: number) => {
  const { profileName } = JSON.parse(localStorage.getItem('salesforce-credentials') || '{}')

  const fetch = useCallback(() => getIncidents(profileName, page, perPage), [profileName, page, perPage])
  const { data, loading, refetch } = useAwaitedData(fetch, [] as SFIncident[])

  return {
    incidents: data.records
      ? data
      : {
        records: [],
        totalRecords: 0,
      },
    loading,
    refetch,
  }
}

export default useGetIncidents
