import isJsonString from '../../utils/isJsonString'
import useEventListener from '../useListener'

export interface Event<T> {
  action: string
  agentEmail: string
  communicationChannel: string
  socketMessage: T
}

export default <T>(action: string, handler: (data: T) => void) => {
  useEventListener(
    'message',
    (e) => {
      const event = e as MessageEvent<string>
      if (!isJsonString(event.data)) return

      const { action: eventAction, socketMessage, data } = JSON.parse(event.data)
      if (eventAction !== action) return

      handler({
        ...data,
        ...socketMessage,
      })
    })
}
