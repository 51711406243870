import { Flex, List } from 'antd'
import React, { useState, useEffect, useCallback, useRef } from 'react'

import useGetCaseFeed from '../../../hooks/saleston/getCaseFeed'
import useListener from '../../../hooks/useListener'

import { SFCaseFeedItem } from '../../../services/saleston/types'
import friendlyDate from '../../../utils/friendlyDate'
import InfiniteScrollTrigger from '../../atoms/InfiniteScrollTrigger'
import RefreshButton from '../../atoms/RefreshButton'
import FeedItem from '../../molecules/FeedItem'
import getFeedItemIcon from '../../molecules/FeedItem/icon-map'
import getFeedItemTitle from '../../molecules/FeedItem/title-map'

interface CaseFeedProps {
  caseId: string
}

const CaseFeed = ({ caseId }: CaseFeedProps) => {
  const [page, setPage] = useState()
  const [loadedFeedItems, setLoadedFeedItems] = useState<SFCaseFeedItem[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadedFeedPages, setLoadedFeedPages] = useState<string[]>([])

  const buttonRef = useRef<HTMLButtonElement>(null)

  const { data, loading, refetch } = useGetCaseFeed({ caseId }, page)

  useEffect(() => {
    if (!data?.elements || data.elements.length <= 0) return

    setLoadedFeedPages((currentPages) => {
      const isPageRepeated = currentPages.find((newPage) => newPage === data.currentPageToken)
      if (isPageRepeated) return currentPages

      setLoadedFeedItems((currentItems) => currentItems.concat(data.elements))
      return [...currentPages, data.currentPageToken]
    })
  }, [data, setLoadedFeedItems, setLoadedFeedPages])

  const reload = useCallback(async () => {
    setPage((prev) => {
      if (prev === undefined) {
        refetch()
      }
      return undefined
    })
    setLoadedFeedItems([])
    setLoadedFeedPages([])
  }, [setPage, setLoadedFeedItems, refetch])

  const handle = useCallback(async () => {
    if (!data.nextPageToken || loadedFeedItems.length === 0) return

    setPage(data.nextPageToken)
  }, [data, setPage, loadedFeedItems])

  useListener(['case:feedCreated'], () => {
    buttonRef.current?.click()
  })

  return (
    <>
      <RefreshButton trigger={reload} icon="./images/refresh.svg" alignment="right" ref={buttonRef} />
      <List
        loadMore={<InfiniteScrollTrigger loading={loading && loadedFeedItems.length > 0} handle={handle} />}
        loading={loading && loadedFeedItems.length <= 0}
        bordered={false}
        itemLayout="vertical"
        dataSource={
          loadedFeedItems
            .map((feedItem) => ({
              icon: getFeedItemIcon(feedItem),
              title: getFeedItemTitle(feedItem),
              created_at: feedItem.createdDate,
              content: <FeedItem feedItemData={feedItem} />,
            }))
        }
        renderItem={(item) => (
          <List.Item style={{ border: 0 }}>
            <Flex>
              <div style={{
                minWidth: 32,
                marginTop: '1%',
              }}>
                {item.icon}
              </div>
              <div style={{ margin: '0 2%' }}>
                <div>
                  <div style={{
                    fontSize: 14,
                    color: '#2B323B',
                  }}>
                    {item.title}
                  </div>
                  {item.created_at && (
                    <p style={{
                      margin: 0,
                      fontSize: 12,
                      color: '#687076',
                    }}>
                      {friendlyDate(item.created_at)}
                    </p>
                  )}
                </div>
                {item.content}
              </div>
            </Flex>
          </List.Item>
        )}
      />
    </>
  )
}

export default CaseFeed
