import axios from 'axios'

import getStringEnv from '../../utils/getEnv'
import { requestInterceptor, responseInterceptor } from '../interceptors'

const api = axios.create({
  baseURL: `${getStringEnv('REACT_APP_PLUTON_API_URL')}/saleston`,
})

api.interceptors.request.use(...requestInterceptor)
api.interceptors.response.use(...responseInterceptor)

export default api
