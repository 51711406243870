import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Home from '../pages/Home'
import Login from '../pages/Login'
import UserAnnouncements from '../pages/SFEmbeddable/UserAnnouncements'

import ProtectedRoute from './ProtectedRoute'

const PlutonRoutes = () => (
  <div className='App'>
    <Routes>
      <Route
        path='/login'
        element={<Login />}
      />

      <Route path='/' element={
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      } />

      <Route
        path='/announcements'
        element={
          <UserAnnouncements />
        }
      />
    </Routes>

  </div>
)

export default PlutonRoutes
