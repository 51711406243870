import { Auth } from 'aws-amplify'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

import packageJson from '../../package.json'

import message from './message'

export interface SalesforceCredentials {
  username: string
  userId: string
  sessionId: string
  profileId: string
}

export const requestInterceptor = [
  async (config: AxiosRequestConfig) => {
    try {
      const token = await Auth.currentAuthenticatedUser()
        .then((auth) => auth.signInUserSession.idToken.jwtToken)
        .catch(() => {})
      const sfCredentials: SalesforceCredentials = JSON.parse(localStorage.getItem('salesforce-credentials') || '{}')

      if (config.headers) {
        config.headers = {
          ...config.headers,
          Authorization: token ? `Bearer ${token}` : sfCredentials.sessionId,
          'App-Name': packageJson.name,
          'App-Version': packageJson.version,
        }
      }
    } catch (error) {
      console.error('Error getting current user session.')
    }
    return config
  },
]

export const responseInterceptor = [
  (config: AxiosResponse) => config,
  (error: any) => {
    if (error.response.status === 404) {
      return Promise.reject(error)
    }

    if (error.response.status === 401) {
      window.postMessage('NotAuthorized')
    }

    message({
      type: 'error',
      content: error.message,
    })

    return Promise.reject(error)
  },
]
