import { useCallback, useState } from 'react'
import { useLocalStorage } from 'react-use'

import { SFAccount } from '../../services/saleston/types'
import getAuthenticationPayload from '../../utils/getAuthenticationPayload'
import removeFromLocalStorage from '../../utils/removeFromLocalStorage'

export interface TabMessage {
  email?: string
  phone?: string
  document?: string
  urn?: string
  task_id?: string
  worker?: {
    teams: string[]
    contactChannels: string[]
  }
  transference?: {
    fromDepartment: string
    to: string
    from: string
  }
}
export interface TabOpen {
  agentEmail: string
  communicationChannel: string
  socketMessage: TabMessage
}

export interface Case {
  id: string
  number: string
  createdDate: string
  status: string
  subject: string
  accountName: string
  ownerName: string
}

export interface Tab extends TabMessage {
  agentEmail: string
  communicationChannel: string
  urn: string
  placeholder: string
  account?: SFAccount
  drawer?: {
    caseData: {
      number?: string
      id?: string
      createdDate?: string
      status?: string
      subject?: string
      accountName?: string
      ownerName?: string
    }
    isOpen: boolean
    open?: (caseData: Case) => void
    close?: () => void
  }
}

export type CreateTab = (data: Partial<Tab>) => Promise<void>

export const DEFAULT_TAB_KEY = '_DEFAULT'
export const DEFAULT_TAB = {
  agentEmail: DEFAULT_TAB_KEY,
  communicationChannel: DEFAULT_TAB_KEY,
  urn: DEFAULT_TAB_KEY,
  placeholder: '⭐',
}

const useTabs = () => {
  const [openTab, setOpenTab] = useState<string>(DEFAULT_TAB_KEY)
  const [tabs, setTabs] = useLocalStorage<Tab[]>('pluton-tabs', [])

  const createTab = useCallback<CreateTab>(
    async (data) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { email } = await getAuthenticationPayload()

      const tab: Tab = {
        ...data,
        agentEmail: data.agentEmail || email,
        communicationChannel: data.communicationChannel || 'CHAT',
        placeholder: data.document || 'NOVA ABA',
        urn: data?.urn || `NO-URN-${window.crypto.randomUUID()}`,
      }

      const tabExists = tabs?.some(({ urn }) => urn === tab.urn)

      if (!tabExists) {
        setTabs([...(tabs || []), tab])
      }

      setOpenTab(tab.urn)
    },
    [tabs, setTabs],
  )

  const removeTab = useCallback(
    (key, action) => {
      if (action !== 'remove') return
      if (key === DEFAULT_TAB) return

      removeFromLocalStorage(key)
      setTabs(tabs?.filter(({ urn }) => urn !== key))
    },
    [tabs, setTabs],
  )

  return {
    tabs: tabs || [],
    createTab,
    removeTab,
    openTab,
    setOpenTab,
  }
}

export default useTabs
