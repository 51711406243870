import api from './api'

export interface CaseFeedFilter {
  caseId: string
}

export default async ({ caseId }: CaseFeedFilter, page?: string, perPage?: number) => {
  const params = page
    ? {
      perPage: perPage || 10,
      page,
    }
    : {
      perPage: perPage || 10,
    }

  return api.get(`/case/${caseId}/feed-items`,
    {
      params,
    })
}
