import { Button } from 'antd'
import React, { useState } from 'react'
import { IoSend } from 'react-icons/io5'
import { MdDone } from 'react-icons/md'

import message from '../../../services/message'
import sleep from '../../../utils/sleep'
import './index.css'

interface TitleProps {
  sendToken: () => Promise<void>
}

type Status = 'ready' | 'loading' | 'done'

const statusMapping: Record<Exclude<Status, 'loading'>, any> = {
  ready: {
    icon: (
      <IoSend
        style={{
          fontSize: '1.1rem',
          marginTop: '.2rem',
        }}
      />
    ),
  },
  done: {
    icon: (
      <MdDone
        style={{
          fontSize: '1.2rem',
          marginTop: '.2rem',
        }}
      />
    ),
  },
}

const Title = ({ sendToken }: TitleProps) => {
  const [status, setStatus] = useState<Status>('ready')

  const handleClick = async () => {
    if (status !== 'ready') {
      message({
        type: 'info',
        content: 'Token já foi enviado!',
      })
      return
    }

    setStatus('loading')

    await sendToken()
      .then(() => {
        setStatus('done')
      })
      .catch(() => {
        setStatus('ready')
      })

    await sleep(3)
    setStatus('ready')
  }

  return (
    <Button
      style={{
        alignItems: 'center',
      }}
      type='primary'
      onClick={handleClick}
      shape='circle'
      icon={status !== 'loading' && statusMapping[status].icon}
      loading={status === 'loading'}
    />
  )
}

export default Title
