import { Card } from 'antd'
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import Announcements from '../../components/organisms/UserRecordsCard/Tabs/Announcements'

export default function UserAnnouncements () {
  const [searchParams] = useSearchParams()
  const document = searchParams.get('document') || ''
  const sessionId = searchParams.get('sessionId') || ''

  useEffect(() => {
    localStorage.setItem('salesforce-credentials', JSON.stringify({ sessionId }))
  }, [sessionId])

  return (
    <Card
      style={{ margin: '5px' }}
      bodyStyle={{ margin: '0px' }}
      title={
        <span className='title'>
          <img src='./images/userRecords.svg' alt='icon' className='icon' />Comunicados
        </span>
      }
    >
      <Announcements document={document} />
    </Card>
  )
}
