import api from './api'

export interface CaseFilter {
  accountId?: string
  tema?: string
  categoria?: string
  assunto?: string
  status?: string | string[]
  createdInTheLastDays?: number
}

export default async (filters: CaseFilter, page = 1, perPage = 10) => {
  const parsedParams: Record<string, string> = {}
  Object.entries(filters).forEach(([key, value]) => {
    if (!value) return
    if (typeof value !== 'string') {
      value = JSON.stringify(value)
    }

    parsedParams[key] = value
  })

  return api.get('/case', {
    params: {
      page,
      perPage,
      ...parsedParams,
    },
  })
}
