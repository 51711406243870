import { Alert, AlertProps, Space } from 'antd'
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'

import useGetContacts from '../../../hooks/vs/getContacts'
import useGetHistory from '../../../hooks/vs/getHistory'
import { Token, VSStatus } from '../../../services/vs/types'
import sendToken from '../../../services/vs/VSsendToken'
import VSunauthorizeToken from '../../../services/vs/VSunauthorizeToken'
import validateToken from '../../../services/vs/VSvalidateToken'
import formatPhone from '../../../utils/formatPhone'
import getVsUserStatus from '../../../utils/getVsUserStatus'
import VSAlert from '../../molecules/Alerts/Vs'
import CollapsableCard from '../../molecules/CollapseCard'
import VSValidationForm from '../../molecules/Form/VSValidation'
import VSContactsTable from '../../molecules/Table/VsContacts'
import VsHistoryTable from '../../molecules/Table/VsHistory'
import { TabContext } from '../../templates/Client'

const VSCard = () => {
  const { urn, account } = useContext(TabContext)
  const document = account?.CNPJ__c || ''
  const [status, setStatus] = useState<VSStatus | undefined>()
  const [alert, setAlert] = useState<{ message: any; type: AlertProps['type']; action?: ReactElement } | null>(null)

  const { contacts, loading: loadingContacts } = useGetContacts(document)
  const { history, loading: loadingHistory, refetch: refetchHistory } = useGetHistory(urn, document)

  useEffect(() => {
    const userStatus = getVsUserStatus(history)
    setStatus(userStatus)
  }, [history])

  const handleSendToken = useCallback((token: Omit<Token, 'URN' | 'document'>) => (
    sendToken({
      URN: urn,
      document,
      ...token,
      contact: formatPhone(token.contact),
    })
      .then(() => {
        setStatus('PENDING')
      })
      .catch(() => {
        setStatus('UNAUTHORIZED')
      })
  ), [document, urn])

  const handleValidateToken = useCallback(({ token }) => {
    setAlert(null)
    return validateToken({
      document,
      URN: urn,
      token,
    })
      .then(({ data }) => {
        if (data.isTokenValid) {
          refetchHistory()
        } else {
          setAlert({
            type: 'error',
            message: 'Token Inválido!',
          })
        }
      })
      .catch(() => {
        setAlert({
          type: 'error',
          message: 'Token Inválido!',
        })
      })
  }, [document, refetchHistory, urn])

  return (
    <CollapsableCard iconName='vs.svg' title='Verificação de segurança' loading={false} onChange={() => { }} noPadding>
      <Space direction='vertical' size='large' style={{ display: 'flex' }}>
        {
          status && (
            <VSAlert
              status={status}
              action={
                status === 'AUTHORIZED'
                  ? ({
                    title: 'Desvalidar',
                    handler: () => VSunauthorizeToken(document).then(() => refetchHistory()),
                  })
                  : undefined
              }
            />
          )}
        {status !== 'AUTHORIZED' && (
          <VSContactsTable dataSource={contacts} loading={loadingContacts} sendToken={handleSendToken} />
        )}

        {status !== 'PENDING' && <VsHistoryTable dataSource={history} loading={loadingHistory} />}

        {status === 'PENDING' && (
          <>
            <VSValidationForm onSubmit={handleValidateToken} />
            {alert && (
              <Alert
                message={alert.message}
                type={alert.type}
                action={alert.action}
                showIcon
                closable
                onClose={() => setAlert(null)}
              />
            )}

          </>
        )}
      </Space>
    </CollapsableCard>
  )
}

export default VSCard
