import isNumeric from './isNumeric'

const PATTERN = /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/

export default (value: string) => {
  const isValid = PATTERN.test(value)
  const isValidWithoutSymbols = isNumeric(value) && [11, 14].includes(value.length)

  return isValid || isValidWithoutSymbols
}
