import { Avatar } from 'antd'
import React from 'react'

import { SFCaseFeedItem } from '../../../services/saleston/types'

import { findCaseChanges } from './title-map'

const iconMap: Record<string, Record<string, React.ReactElement>> = {
  TextPost: { default: <Avatar src="./images/caseFeedIcons/avatar.svg" /> },
  CallLogPost: { default: <Avatar src="./images/caseFeedIcons/devolution.svg" /> },
  EmailMessageEvent: { default: <Avatar src="./images/caseFeedIcons/mail.svg" /> },
  TrackedChange: { default: <Avatar src="./images/caseFeedIcons/avatar-check.svg" /> },
  ChangeStatusPost: {
    default: <Avatar style={{ padding: '3px' }} src="./images/caseStatusIcons/Pendente.png" />,
    Aberto: <Avatar style={{ padding: '4px' }} src="./images/caseStatusIcons/Aberto.png" />,
    'Em espera': <Avatar style={{ padding: '3px' }} src="./images/caseStatusIcons/Em_espera.png" />,
    Fechado: <Avatar style={{ padding: '3px' }} src="./images/caseStatusIcons/Fechado.png" />,
    Novo: <Avatar style={{ padding: '3px' }} src="./images/caseStatusIcons/Novo.png" />,
    Pendente: <Avatar style={{ padding: '3px' }} src="./images/caseStatusIcons/Pendente.png" />,
    Resolvido: <Avatar style={{ padding: '3px' }} src="./images/caseStatusIcons/Resolvido.png" />,
  },
}
const getFeedItemIcon = (item: SFCaseFeedItem): React.ReactElement => {
  const typeSet = iconMap[item.type || ''] || iconMap.TextPost

  if (item.type === 'ChangeStatusPost') {
    const status = findCaseChanges(item.capabilities, 'Status').at(-1)
    return typeSet[status || 'default'] || typeSet.default
  }

  return typeSet.default
}

export default getFeedItemIcon
