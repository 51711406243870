import { Button } from 'antd'
import React, { useEffect, useState } from 'react'

const Description: React.FunctionComponent<{content: string}> = ({ content }) => {
  const [hasHiddenContent, setHasHiddenContent] = useState(false)
  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    setHasHiddenContent(content.length > 80)
    setHidden(content.length > 80)
  }, [content])

  return (
    <div>
      <div>
        {hidden ? content.slice(0, 80) + '...' : content}
      </div>
      {hasHiddenContent && (
        <Button
          type='link'
          size='small'
          style={{ padding: 0 }}
          onClick={() => setHidden(current => !current)}
        >
          {hidden ? 'Ver mais' : 'Ver menos'}
        </Button>)}
    </div>
  )
}

export default Description
