import { datadogRum } from '@datadog/browser-rum'
import React from 'react'

import packageJson from '../package.json'

import PlutonRoutes from './routes'
import './style.css'
import getEnv from './utils/getEnv'

datadogRum.init({
  applicationId: getEnv('REACT_APP_DATADOG_APPLICATION_ID'),
  clientToken: getEnv('REACT_APP_DATADOG_CLIENT_TOKEN'),
  site: getEnv('REACT_APP_DATADOG_SITE'),
  service: 'pluton',
  env: getEnv('REACT_APP_ENV'),
  version: packageJson.version,
  sessionSampleRate: +getEnv('REACT_APP_DATADOG_SESSION_SAMPLE_RATE'),
  sessionReplaySampleRate: +getEnv(
    'REACT_APP_DATADOG_SESSION_REPLAY_SAMPLE_RATE',
  ),
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask',
  usePartitionedCrossSiteSessionCookie: true,
  allowFallbackToLocalStorage: true,
})

export default function App () {
  return (
    <PlutonRoutes />
  )
}
