/* eslint-disable no-restricted-syntax */
import { useCallback } from 'react'

import getAnnouncements from '../../services/saleston/getAnnouncements'
import { SFAnnouncement } from '../../services/saleston/types'

import useAwaitedData from '../useAwaitedData'

const useGetAnnouncements = (document: string) => {
  const fetch = useCallback(() => getAnnouncements({ document }), [document])

  const { data, loading, refetch } = useAwaitedData(fetch, [] as SFAnnouncement[])

  const tempArray = []
  if (data.records) {
    for (const item of data.records) {
      item.rowKey = data.records.indexOf(item)
      tempArray.push(item)
    }

    data.records = tempArray
  }

  return {
    announcements: data.records
      ? data
      : {
        records: [],
        totalRecords: 0,
      },
    loading,
    refetch,
  }
}

export default useGetAnnouncements
