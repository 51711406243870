import { SFAccount } from '../services/saleston/types'

export default (urn: string) => {
  try {
    const account: SFAccount | null = JSON.parse(window.localStorage.getItem(`pluton-${urn}-account`) || '')

    return account?.Name
  } catch (error) {
    return false
  }
}
