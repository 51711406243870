export default [
  {
    title: '#',
    dataIndex: 'Stonecode',
    key: 'Stonecode',
    width: '10%',
    ellipsis: true,
  },
  {
    title: 'Assunto',
    dataIndex: 'Subject',
    key: 'Subject',
    width: '60%',
    ellipsis: true,
  },
  {
    title: 'Criado em',
    dataIndex: 'CreatedDate',
    key: 'CreatedDate',
    width: '20%',
    ellipsis: true,
    render: (date: string) => new Date(date).toLocaleString('pt-BR', { timeZone: 'UTC' }),
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'Status',
    width: '10%',
    ellipsis: true,
  },
]
