import { Button, Drawer, Flex } from 'antd'
import React, { useCallback, useContext } from 'react'

import getEnv from '../../../utils/getEnv'
import CaseDetails from '../../molecules/CaseDetails'
import CaseFeedForm from '../../molecules/CaseFeedForm'
import { TabContext } from '../../templates/Client'
import CaseFeed from '../CaseFeed'

const ClientDrawer = () => {
  const { drawer } = useContext(TabContext)
  const header = useCallback(() => (
    <Flex style={{ justifyContent: 'space-between' }}>
      Caso {drawer?.caseData.number}
      <Button type="link" size="small" onClick={() => {
        window.open(`${getEnv('REACT_APP_SALESFORCE_URL')}/lightning/r/Case/${drawer?.caseData.id}/view`, '_blank')
      }}>
        Ver no Salesforce
      </Button>
    </Flex>
  ), [drawer])

  return (
    <Drawer title={header()} onClose={drawer!.close} open={drawer!.isOpen}>
      <CaseDetails
        subject={drawer?.caseData?.subject || '-'}
        owner={drawer?.caseData?.ownerName || '-'}
        createdDate={drawer?.caseData?.createdDate || '-'}
        status={drawer?.caseData?.status || '-'}
        customerAccountName={drawer?.caseData?.accountName || '-'}
      />
      <CaseFeedForm />
      {drawer?.caseData?.id && (
        <CaseFeed
          caseId={drawer?.caseData?.id}
        />
      )}
    </Drawer>
  )
}

export default ClientDrawer
