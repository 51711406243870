import { TablePaginationConfig } from 'antd'
import React, { useEffect, useState } from 'react'

import useGetIncidents from '../../../hooks/saleston/getIncidents'
import RefreshButton from '../../atoms/RefreshButton'
import CollapsableCard from '../../molecules/CollapseCard'
import IncidentsTable from '../../molecules/Table/IncidentTable'

const IncidentsCard = () => {
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    hideOnSinglePage: true,
    defaultCurrent: 1,
    defaultPageSize: 10,
  })

  const { incidents, loading, refetch } = useGetIncidents(pagination.current, pagination.pageSize)

  useEffect(() => {
    setPagination((oldPagination) => {
      oldPagination.total = incidents.totalRecords
      return oldPagination
    })

    if (pagination.total == null) {
      refetch()
    }
  }, [incidents, pagination, refetch])

  return (
    <CollapsableCard
      extra={
        <>
          <span>Total de incidentes ativos: {incidents.totalRecords}</span>
          <RefreshButton trigger={refetch} icon='./images/refresh.svg' />
        </>
      }
      iconName='incidents.svg'
      title='Incidentes e alertas'
      loading={loading}
      onChange={() => { }}
    >
      <IncidentsTable
        dataSource={incidents.records}
        loading={loading}
        refetch={refetch}
        pagination={pagination}
        setPagination={setPagination}
      />
    </CollapsableCard>
  )
}

export default IncidentsCard
