import React from 'react'

import { SFCaseFeedItem } from '../../../../services/saleston/types'
import Description from '../../../atoms/Description'

interface FeedItemGenericPostProps {
  feedItemData: SFCaseFeedItem
}

const FeedItemGenericPost = ({ feedItemData }: FeedItemGenericPostProps) => (
  <Description content={feedItemData.body?.text || ''} />

)

export default FeedItemGenericPost
