import { Table } from 'antd'
import React from 'react'

import { SFAccount } from '../../../../services/saleston/types'
import Title from '../../../atoms/Title'

import columns from './columns'

interface SearchUserTableProps {
  loading: boolean
  dataSource: SFAccount[]
  selectRow: (index: number) => void
}

const SearchUserTable = ({ loading, dataSource, selectRow }: SearchUserTableProps) => (
  <>
    <Title size='0.7rem' align='start'>
      Escolha entre as opções para vincular as informações ao plugin
    </Title>
    <Table
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      rowKey={(record) => record.Id}
      rowClassName='pointer'
      onRow={(record, rowIndex) => ({
        onClick: () => {
          if (rowIndex !== undefined) selectRow(rowIndex)
        },
      })}
    />
  </>
)

export default SearchUserTable
