import { Button } from 'antd'
import React from 'react'

import useAuth from '../hooks/useAuth'

const ProtectedRoute = ({ children }: any) => {
  const { isAuthenticated } = useAuth()

  if (!isAuthenticated) {
    return (
      <div style={{
        position: 'absolute',
        left: '0',
        right: '0',
        bottom: '70%',
      }}>
        <Button
          style={{
            position: 'absolute',
            width: '100%',
          }}
          type='primary'
          size='large'
          onClick={() => window.open('/login?popup=true', 'popup', 'popup=true')}>
          Login com Salesforce
        </Button>
      </div>
    )
  }

  return children
}

export default ProtectedRoute
