import React, { CSSProperties } from 'react'

interface ContainerProps {
  children: any
  style?: CSSProperties
}

const Container = ({ children, style }: ContainerProps) => (
  <div
    style={{
      padding: '5%',
      alignContent: 'center',
      alignItems: 'center',
      ...style,
    }}
  >
    {children}
  </div>
)

export default Container
