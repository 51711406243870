import { AxiosResponse } from 'axios'
import { useCallback, useState } from 'react'

import { Await } from '../utils/awaitType'

import useAwait from './useAwait'

const useLazyAwaitedData = <
  T extends () => Promise<AxiosResponse<any>>,
  D extends Await<ReturnType<T>>['data'],
  DF = D | null
>(
    originalFetcher: T,
    defaultValue: DF,
  ): {
  data: D | DF
  loading: boolean
  fetch: () => Promise<void>
  reset: () => void
  fetched: boolean
  error: Error | undefined
} => {
  const [loading, fetcher, { toggle }] = useAwait(originalFetcher)
  const [fetched, setFetched] = useState(false)
  const [data, setData] = useState<D | DF>(defaultValue)
  const [error, setError] = useState<Error>()
  const fetch = useCallback(async () => {
    try {
      setFetched(true)
      const res = await fetcher()
      const value: D = res.data
      setData(value)
      setError(undefined)
    } catch (err) {
      setData(defaultValue)
      setFetched(true)
      setError(err as Error)
    } finally {
      toggle(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalFetcher, fetcher, setData, toggle, setFetched])

  const reset = useCallback(() => {
    setData(defaultValue)
    setFetched(false)
    setError(undefined)
  }, [setData, defaultValue])

  return {
    data,
    loading,
    fetch,
    reset,
    fetched,
    error,
  }
}

export default useLazyAwaitedData
