import React from 'react'

import { SFCaseFeedItem } from '../../../../services/saleston/types'
import Description from '../../../atoms/Description'

interface FeedItemCallLogPostProps {
  feedItemData: SFCaseFeedItem
}

const FeedItemCallLogPost = ({ feedItemData }: FeedItemCallLogPostProps) => (
  <Description content={feedItemData.capabilities?.enhancedLink?.description || ''} />
)

export default FeedItemCallLogPost
