import { useEffect, useRef } from 'react'

type Handler = (e: Event | CustomEvent) => void
export default (listenTo: string | string[], handler: Handler, element = window) => {
  const savedHandler = useRef<Handler>(() => { })

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])
  useEffect(
    () => {
      const isSupported = element && element.addEventListener
      if (!isSupported) return

      const eventListener = (event: Event) => {
        savedHandler.current(event)
      }

      const eventList = !Array.isArray(listenTo)
        ? [listenTo]
        : listenTo

      eventList.map(eventName => element.addEventListener(eventName, eventListener))

      // eslint-disable-next-line consistent-return
      return () => {
        eventList.map(eventName => element.removeEventListener(eventName, eventListener))
      }
    },
    [listenTo, element],
  )
}
