import React, { useState } from 'react'

import SupportMenu from '../components/molecules/SupportMenu'
import TabPanel from '../components/molecules/Tabs'
import Client from '../components/templates/Client'
import useTabListener from '../hooks/tabs/useTabListener'
import useTabs, { DEFAULT_TAB } from '../hooks/tabs/useTabs'
import useListener from '../hooks/useListener'
import getName from '../utils/getName'
import limitText from '../utils/limitText'

export default function Home () {
  const {
    tabs,
    createTab,
    removeTab,
    openTab,
    setOpenTab,
  } = useTabs()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, update] = useState<boolean>(true)
  useListener('account:selected', () => update(value => !value))

  useTabListener('tab:open', createTab)

  return (
    <>
      <SupportMenu />
      <TabPanel
        panes={
          [DEFAULT_TAB, ...tabs].map((data) => ({
            key: data.urn,
            label: limitText(getName(data.urn) || data.placeholder, 20),
            children: <Client createTab={createTab} data={data} />,
          }))
        }
        active={openTab}
        onChange={setOpenTab}
        onEdit={removeTab}
      />
    </>
  )
}
