import { AxiosResponse } from 'axios'
import { useEffect } from 'react'

import { Await } from '../utils/awaitType'

import useLazyAwaitedData from './useLazyAwaitedData'

const useAwaitedData = <
  T extends () => Promise<AxiosResponse<any>>,
  D extends Await<ReturnType<T>>['data'],
  DF = D | null
>(
    originalFetcher: T,
    defaultValue: DF,
  ): {
  data: D | DF
  loading: boolean
  refetch: () => Promise<void>
  error: any
} => {
  const { data, fetch, loading, error } = useLazyAwaitedData(originalFetcher, defaultValue)
  useEffect(() => {
    fetch()
  }, [fetch])
  return {
    data,
    loading,
    refetch: fetch,
    error,
  }
}

export default useAwaitedData
