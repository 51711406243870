import { Tooltip } from 'antd'
import React, { ReactElement } from 'react'
import { MdClose, MdDone, MdOutlineWarningAmber } from 'react-icons/md'

export interface StatusTagProps {
  status: 'success' | 'warning' | 'error'
}

interface StatusContent {
  style: {
    backgroundColor: string
    borderColor: string
    color: string
  }
  icon: ReactElement
  tip: string
}

const StatusTag = ({ status }: StatusTagProps) => {
  const statusMap: Record<StatusTagProps['status'], StatusContent> = {
    success: {
      style: {
        backgroundColor: '#E0FFF1',
        borderColor: '#7FF5BC',
        color: '#7FF5BC',
      },
      icon: <MdDone />,
      tip: 'Token Verificado!',
    },
    warning: {
      style: {
        backgroundColor: '#FEFFE0',
        borderColor: '#FFE500',
        color: '#FFE500',
      },
      icon: <MdOutlineWarningAmber />,
      tip: 'Token Enviado!',
    },
    error: {
      style: {
        backgroundColor: '#FFF1F0',
        borderColor: '#FFA39E',
        color: '#FFA39E',
      },
      icon: <MdClose />,
      tip: 'Não Verificado!',
    },
  }

  return (
    <Tooltip placement="topLeft" title={statusMap[status].tip}>
      <span
        style={{
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          textAlign: 'center',
          lineHeight: '20px',
          display: 'inline-block',
          borderWidth: '2px',
          borderStyle: 'solid',
          ...statusMap[status].style,
        }}
      >
        {statusMap[status].icon}
      </span>
    </Tooltip>
  )
}

export default StatusTag
