import { useCallback } from 'react'

import { SFCase } from '../../services/saleston/types'
import VSgetHistory from '../../services/vs/VSgetHistory'
import useAwaitedData from '../useAwaitedData'

const useGetHistory = (urn: string, document: string) => {
  const fetch = useCallback(() => VSgetHistory(urn, document), [urn, document])
  const { data, loading, refetch } = useAwaitedData(fetch, [] as SFCase[])

  return {
    history: data.data ?? [],
    loading,
    refetch,
  }
}

export default useGetHistory
